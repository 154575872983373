import React, { Component } from 'react';
import { connect }                from 'react-redux';
import MopsTreeView from 'components/mop/MopsTreeView';
import MopsList from 'components/mop/MopsList';
import MopConfiguration from 'components/mop/MopConfiguration';
import muiThemeable from 'material-ui/styles/muiThemeable';

class Home extends Component {
  render() {
    return (
      <div style={{...this.props.muiTheme.pageContainer}}>
        <MopsTreeView/>
        {this.shouldDisplayMopConfiguration() ? <MopConfiguration /> : <MopsList/>}
      </div>
    );
  }
}

Home.prototype.shouldDisplayMopConfiguration= function(){
  const {mopLoading, mopConfiguration} = this.props;
  return mopLoading === true || (mopConfiguration !== null);
}

const mapStateToProps = state => {
  return {
    mopLoading: state.meanOfPayments.mopLoading,
    mopConfiguration: state.meanOfPayments.mopConfiguration
  }
}
export default connect(mapStateToProps)(muiThemeable()(Home));