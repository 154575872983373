import React, {Component} from "react"
import {connect} from "react-redux"
import _ from "lodash"
import {
      actions as actionsPaymentListWidget,
      selectors
    } from "modules/mod-paymentListTest"
import Icon from "react-bulma-components/lib/components/icon"
import Button from "react-bulma-components/lib/components/button"

// eslint-disable-next-line
import AceEditor from "react-ace"
import "brace/mode/json";
import { JsonEditor as Editor } from "jsoneditor-react";

class FormPaymentListTest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openFormErrorDialog: false,
      cssUrl: window.localStorage.getItem("cssUrl") || window.OnePayConfiguration.serversURLConstant.FRONT_CUBE,
      messagesUrl: window.localStorage.getItem("messagesUrl") || window.OnePayConfiguration.serversURLConstant.TRANSLATE_CUBE,
    }
  }
  render() {
      const {selectedClient, selectedChannel} = this.props
    return (
      <div>
        <div className="columns">
          <div className="column">
            <label className="label is-small">Customer *</label>
            <div className="control">
              <div className="select" style={{width: "100%"}}>
                <select value={this.props.selectedClient} onChange={this.handleChangeClient} style={{width: "100%"}}>
                  {this.getClients()}
                </select>
              </div>
              <p className="help is-danger">{selectedClient === null && "Required"}</p>
            </div>
          </div>
          <div className="column">
            {/* Channel */}
            <label className="label is-small">Channel *</label>
            <div className="control">
              <div className="select" style={{width: "100%"}}>
                <select value={this.props.selectedChannel} onChange={this.handleChangeChannel} style={{width: "100%"}}>
                  {this.getChannels()}
                </select>
              </div>
              <p className="help is-danger">{selectedChannel === null && "Required"}</p>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {/* contexts */}
            <label className="label is-small">Context</label>
            <div className="control">
              <div className="select" style={{width: "100%"}}>
                <select value={this.props.selectedContext} onChange={this.handleChangeContext} style={{width: "100%"}}>
                  {this.getContexts()}
                </select>
              </div>
            </div>
          </div>
          <div className="column">
            {/* OrderAmount */}
            <div className="field">
              <label className="label is-small">Order Amount</label>
              <div className="control">
                <input className="input" onChange={this.handleChangeOrderAmount} type="number" value={this.props.orderAmount}/>
              </div>
              <p className="help is-danger">{this.intIsValid(this.props.orderAmount) ? "" : "Please input an Integer"}</p>
            </div>
          </div>
        </div>
        <div className="columns">
          {/* oneClick */}
          <div className="column">
            <label className="label is-small">One Click</label>
            <div className="control">
              <label className="radio">
                <input type="radio" onChange={this.handleChangeOneClick} value="1" checked={this.props.oneClick === "1"}/>
                Yes
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeOneClick} value="0" checked={this.props.oneClick === "0"}/>
                No
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeOneClick} value="-1" checked={this.props.oneClick === null || this.props.oneClick === "-1"}/>
                No param
              </label>
            </div>
          </div>
          {/* privateCustomer*/}
          <div className="column">
            <label className="label is-small">Private Customer</label>
            <div className="control">
              <label className="radio">
                <input type="radio" onChange={this.handleChangePrivateCustomer} value="1" checked={this.props.privateCustomer === "1"}/>
                Yes
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangePrivateCustomer} value="0" checked={this.props.privateCustomer === "0"}/>
                No
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangePrivateCustomer} value="-1" checked={this.props.privateCustomer === null || this.props.privateCustomer === "-1"}/>
                No param
              </label>
            </div>
          </div>
        </div>
        {/* professionalCustomer */}
        <div className="columns">
          <div className="column">
            <label className="label is-small">Professional Customer</label>
            <div className="control">
              <label className="radio">
                <input type="radio" onChange={this.handleChangeProfessionalCustomer} value="1" checked={this.props.professionalCustomer === "1"}/>
                Yes
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeProfessionalCustomer} value="0" checked={this.props.professionalCustomer === "0"}/>
                No
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeProfessionalCustomer} value="-1" checked={this.props.professionalCustomer === null || this.props.professionalCustomer === "-1"}/>
                No param
              </label>
            </div>
          </div>
          {/* paymentId  */}
          <div className="column">
            <div className="field">
              <label className="label is-small">PaymentId</label>
              <div className="control">
                <input className="input" onChange={this.handleChangePaymentId} type="number" value={this.props.paymentId}/>
              </div>
              <p className="help is-danger">{this.intIsValid(this.props.paymentId) ? "" : "Please input an Integer"}</p>
            </div>
          </div>
        </div>
        {/* forLoyaltyCustomer */}
        <div className="columns">
          <div className="column">
            <label className="label is-small">For Loyalty Customer</label>
            <div className="control">
              <label className="radio">
                <input type="radio" onChange={this.handleChangeForLoyaltyCustomer} value="1" checked={this.props.forLoyaltyCustomer === "1"}/>
                Yes
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeForLoyaltyCustomer} value="0" checked={this.props.forLoyaltyCustomer === "0"}/>
                No
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeForLoyaltyCustomer} value="-1" checked={this.props.forLoyaltyCustomer === null || this.props.forLoyaltyCustomer === "-1"}/>
                No param
              </label>
            </div>
          </div>
          {/* forNotLoyaltyCustomer */}
          <div className="column">
            <label className="label is-small">For Not Loyalty Customer</label>
            <div className="control">
              <label className="radio">
                <input type="radio" onChange={this.handleChangeForNotLoyaltyCustomer} value="1" checked={this.props.forNotLoyaltyCustomer === "1"}/>
                Yes
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeForNotLoyaltyCustomer} value="0" checked={this.props.forNotLoyaltyCustomer === "0"}/>
                No
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeForNotLoyaltyCustomer} value="-1" checked={this.props.forNotLoyaltyCustomer === null || this.props.forNotLoyaltyCustomer === "-1"}/>
                No param
              </label>
            </div>
          </div>
        </div>
        {/* regularDelivery */}
        <div className="columns">
          <div className="column">
            <label className="label is-small">Regular Delivery</label>
            <div className="control">
              <label className="radio">
                <input type="radio" onChange={this.handleChangeRegularDelivery} value="1" checked={this.props.regularDelivery === "1"}/>
                Yes
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeRegularDelivery} value="0" checked={this.props.regularDelivery === "0"}/>
                No
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeRegularDelivery} value="-1" checked={this.props.regularDelivery === null || this.props.regularDelivery === "-1"}/>
                No param
              </label>
            </div>
          </div>
          {/* paymentOnDelivery  */}
          <div className="column">
            <label className="label is-small">Payment On Delivery</label>
            <div className="control">
              <label className="radio">
                <input type="radio" onChange={this.handleChangePaymentOnDelivery} value="1" checked={this.props.paymentOnDelivery === "1"}/>
                Yes
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangePaymentOnDelivery} value="0" checked={this.props.paymentOnDelivery === "0"}/>
                No
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangePaymentOnDelivery} value="-1" checked={this.props.paymentOnDelivery === null || this.props.paymentOnDelivery === "-1"}/>
                No param
              </label>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {/* Version */}
            <div className="field">
              <label className="label is-small">Version</label>
              <div className="control">
                <input className="input" onChange={this.handleChangeVersion} type="number" value={this.props.version}/>
              </div>
              <p className="help is-danger">{this.intIsValid(this.props.version) ? "" : "Please input an Integer"}</p>
            </div>
          </div>
          {/* type */}
          <div className="column">
            <label className="label is-small">Type</label>
            <div className="control">
              <label className="radio">
                <input type="radio" onChange={this.handleChangeType} value="PAYMENT" checked={this.props.type === "PAYMENT"}/>
                PAYMENT
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeType} value="REFUND" checked={this.props.type === "REFUND"}/>
                REFUND
              </label>
              <label className="radio">
                <input type="radio" onChange={this.handleChangeType} value="" checked={this.props.type === null || this.props.type === ""}/>
              </label>
            </div>
          </div>
        </div>
          {/* Headers */}
          <Button color={"success"} fullwidth onClick={this.addHeader}>Add
          header</Button>
          {Object.keys(this.props.headers).map((index) =>
          <span key={index} className="control is-flex" style={{flexGrow: 1}}>
          <span className="is-flex" style={{flexGrow: 1}}>
          <p className="control has-icons-left">
          <input className="input is-small"
                  placeholder="Authorization"
                  id={`header-key-${index}`}
                  onChange={(event) => this.handleChangeHeaderKeys(event, event.target.value, index)}
                  value={this.props.headers[index].key}/>
          <Icon color={this.props.headers[index].key ? "success" : null}
                align="left">
            <i className="fas fa-key"/>
          </Icon>
          </p>
          </span>
          <p style={{flexGrow: 1}}> = </p>
          <span className="is-flex" style={{flexGrow: 1}}>
          <p className="control has-icons-left">
          <input className="input is-small"
                  placeholder="Bearer eyZ"
                  id={`header-value-${index}`}
                  onChange={(event) => this.handleChangeHeaderValues(event, event.target.value, index)}
                  value={this.props.headers[index].value}/>
          <Icon color={this.props.headers[index].value ? "success" : null}
                align="left">
            <i className="fas fa-chevron-right"/>
          </Icon>
          </p>
          </span>
          <Button color={"danger"} size={"small"}
                onClick={(event, test, index) => this.deleteHeader(index)}
                style={{flexGrow: 1}}>Delete</Button>
          </span>)
          }

          <div>
            <div className="field">
              <label className="label is-small">Stylesheet url</label>
              <div className="control">
                <input className="input" 
                  onChange={event => this.changeUrlCss(event)} 
                  type="text" value={this.state.cssUrl}
                  style={{width: "100%"}}/>
              </div>
            </div>
          </div>
          <div>
            <div className="field">
              <label className="label is-small">Messages.js url</label>
              <div className="control">
                <input className="input" onChange={event => this.changeUrlMessage(event)} type="text" value={this.state.messagesUrl}/>
              </div>
            </div>
          </div>
          <br/>
          <div style={{width: "500px"}} className="form-min-json">
            <Editor
              name="customerData"
              onChange={(code) => this.updateCustomerData(code)}
              onError={errors => this.validateCustomerCode(errors)}
              value={this.props.paymentListWidget.typingCustomerData}
              mode={"code"}
            />
          </div>
          <div style={{width: "500px"}} className="form-min-json">
            <Editor
              name="paymentData"
              onChange={(code) => this.updateData(code)}
              onError={errors => this.validateCode(errors)}
              value={this.props.paymentListWidget.typingPaymentData}
              mode={"code"}
            />
          </div>
        </div>
    )
  }

    changeUrlCss = (event) => {
      window.localStorage.setItem("cssUrl", event.target.value)
      this.setState({cssUrl: event.target.value});
    }

    changeUrlMessage = (event) => {
      window.localStorage.setItem("messagesUrl", event.target.value);
      this.setState({messagesUrl: event.target.value});
    }

    handleChangeClient = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListSelectClient(event.target.value))
    }
    handleChangeChannel = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListSelectChannel(event.target.value))
    }
    handleChangeContext = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListSelectContext(event.target.value))
    }
    handleChangeOrderAmount = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListOrderAmount(event.target.value))
    }
    handleChangeOneClick = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListOneClick(event.target.value))
    }
    handleChangePrivateCustomer = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListOnePrivateCustomer(event.target.value))
    }
    handleChangeProfessionalCustomer = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListOneProfessionalCustomer(event.target.value))
    }
    handleChangePaymentId = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListPaymentId(event.target.value))
    }
    handleChangeForLoyaltyCustomer = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListForLoyaltyCustomer(event.target.value))
    }
    handleChangeForNotLoyaltyCustomer = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListForNotLoyaltyCustomer(event.target.value))
    }
    handleChangeRegularDelivery = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListRegularDelivery(event.target.value))
    }
    handleChangePaymentOnDelivery = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListPaymentOnDelivery(event.target.value))
    }
    handleChangeVersion = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListVersion(event.target.value))
    }
    handleChangeType = (event) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListType(event.target.value))
    }
    changeShowParamsState = () => {
      this.props.dispatch(actionsPaymentListWidget.changeShowParamsState())
    }
    addHeader = () => {
      this.props.dispatch(actionsPaymentListWidget.addHeader())
    }
    deleteHeader = (index) => {
      this.props.dispatch(actionsPaymentListWidget.deleteHeader(index))
    }
    handleChangeHeaderKeys = (event, value, index) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListHeaderKeys({
        value: value,
        index: index,
      }))
    }
    handleChangeHeaderValues = (event, value, index) => {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListHeaderValues({
        value: value,
        index: index,
      }))
    }
}


    FormPaymentListTest.prototype.getClients = function () {
      const {clients} = this.props
      return _.sortBy(clients, ["description"]).map(client =>
        <option key={"client" + client.id} value={client.externalId}>
          {client.description}    
        </option>
      )
    }
    
    FormPaymentListTest.prototype.getChannels = function () {
      const {channels} = this.props
      return _.sortBy(channels, ["description"]).map(channel =>
        <option key={"channel" + channel.id} value={channel.type} >
          {channel.description}
        </option>
      )
    }
    
    
    FormPaymentListTest.prototype.getContexts = function () {
      const {contexts, selectedContexts} = this.props
    
      let contextsItems = []
      contextsItems.push(<option key={"ctx"} value=""></option>)
      contexts.forEach(channel => {
        contextsItems.push(<option key={"ctx" + channel.id}
                                     value={channel.type}>{channel.description}</option>)
        channel.contexts.forEach(ctx => {
          contextsItems.push(<option key={"ctx" + ctx.id} value={ctx.type}
                                       insetChildren={true}
                                       checked={selectedContexts === ctx.type}>{ctx.description}</option>)
        })
      })
      return contextsItems
    }

    FormPaymentListTest.prototype.intIsValid = function (x) {
      if (x === null || x.length === 0) {
        return true
      }
      let parsed = parseInt(x, 10)
      if (isNaN(parsed)) {
        return 0
      }
      return parsed
    }


/********************************************************/
/* Keep typed code in app state
/********************************************************/
FormPaymentListTest.prototype.updateCustomerData = function (code) {
  console.log("uupdateCustomerDatap", code);
  const {dispatch} = this.props
  dispatch(actionsPaymentListWidget.updateCustomerData({
    code: code,
  }))
}

FormPaymentListTest.prototype.updateData = function (code) {
  const {dispatch} = this.props
  dispatch(actionsPaymentListWidget.updateData({
    code: code,
  }))
}
/********************************************************/
/* Validate code
/********************************************************/
FormPaymentListTest.prototype.validateCustomerCode = function (errors) {
  const {dispatch} = this.props
  dispatch(actionsPaymentListWidget.validateCustomerCode({
    errors: errors,
  }))
}

FormPaymentListTest.prototype.validateCode = function (errors) {
  const {dispatch} = this.props
  dispatch(actionsPaymentListWidget.validateCode({
    errors: errors,
  }))
}

const mapStateToProps = state => {
  return {
    clients: state.appClient.clients,
    channels: selectors.channelsSelector(state),
    contexts: selectors.contextsSelector(state),
    selectedClient: state.paymentListWidget.selectedClient,
    selectedChannel: state.paymentListWidget.selectedChannel,
    selectedContext: state.paymentListWidget.selectedContext,
    oneClick: state.paymentListWidget.oneClick,
    orderAmount: state.paymentListWidget.orderAmount,
    privateCustomer: state.paymentListWidget.privateCustomer,
    professionalCustomer: state.paymentListWidget.professionalCustomer,
    paymentId: state.paymentListWidget.paymentId,
    forLoyaltyCustomer: state.paymentListWidget.forLoyaltyCustomer,
    forNotLoyaltyCustomer: state.paymentListWidget.forNotLoyaltyCustomer,
    regularDelivery: state.paymentListWidget.regularDelivery,
    paymentOnDelivery: state.paymentListWidget.paymentOnDelivery,
    version: state.paymentListWidget.version,
    type: state.paymentListWidget.type,
    headers: state.paymentListWidget.headers,
    showParams: state.paymentListWidget.showParams,
    meanOfPaymentList: state.paymentListWidget.meanOfPaymentList,
    typingPaymentData: state.typingPaymentData,
    typingCustomerData: state.typingCustomerData,
    paymentListWidget: state.paymentListWidget,
    collab: state.collab.profile,
  }
}

export default connect(mapStateToProps)(FormPaymentListTest)