import React, {Component} from 'react';
import {connect} from 'react-redux';
import { findRoleByAppClient } from 'utils/SecurityTools';
import Paper from 'material-ui/Paper';
import Toggle from 'material-ui/Toggle';
import FlatButton from 'material-ui/FlatButton';
import ActionBuildIcon from 'material-ui/svg-icons/action/build';
import muiThemeable from 'material-ui/styles/muiThemeable';
import {actions} from 'modules/mod-mops-config';
import Icon from "react-bulma-components/lib/components/icon"

class Mop extends Component {

  render() {
    const {paymentClient, pcctFoEnable, isStable, isBackup, paymentBackup} = this.props;
    const {mop} = this.props.muiTheme;

    var asRoleWriter = findRoleByAppClient(this.props.collab, this.props.selectedAppClientId);
    var marginLeft = isStable !== false ? { 'margin-left' : '24px' } : null;
    var fontBackup = { 'margin-left' : '500px', 'font-size' : '12px', 'color' : 'gray' };
    return (
      <Paper style={{...mop}}>
        <div style={{...mop.content, ...marginLeft}} >
          { isStable === false &&
            <Icon color="warning"
                  align="left"
                  className={"tooltip is-tooltip-warning is-tooltip-right"}
                  data-tooltip="Unstable">
                    <i className="fas fa-lg fa-exclamation-triangle"/>
            </Icon>
          }
          <Toggle toggled={pcctFoEnable === true} style={{...mop.toggle}} onToggle={this.toggleMeanOfPayment} disabled={asRoleWriter === -1} />
          { paymentClient && <h2 style={{...mop.name}}>{`${paymentClient.payment.pmntName} (${paymentClient.payment.pmntId})`}</h2> }
          { isBackup === false && paymentBackup &&
          <h3 style={{...mop.name, ...fontBackup}}>Main of : {`${paymentBackup.pmntName} (${paymentBackup.pmntId})`}</h3>
          }
          { isBackup === true && paymentBackup &&
          <h3 style={{...mop.name, ...fontBackup}}>Backup of : {`${paymentBackup.pmntName} (${paymentBackup.pmntId})`}</h3>
          }
          {asRoleWriter !== -1 &&
            <FlatButton
              label="Configure"
              secondary={true}
              icon={<ActionBuildIcon/>}
              onClick={this.loadMopConfiguration}
            />
          }
        </div>
      </Paper>
    );
  }

  toggleMeanOfPayment = (event, isInputChecked) => {
    const {dispatch, pcctId} = this.props;
    dispatch(actions.activateMop(pcctId, isInputChecked));
  };

  loadMopConfiguration = (event) => {
    event.preventDefault();
    const {dispatch, selectedAppClientId, selectedChannelId, pcctId} = this.props;
    dispatch(actions.getMopConfiguration(selectedAppClientId, selectedChannelId, pcctId));

    this.loadContracts(event)
    this.loadPaymentAttributes(event)
  };

  loadContracts = (event) => {
    event.preventDefault();
    const {dispatch, selectedAppClientExternalId} = this.props;
    dispatch(actions.getContracts(selectedAppClientExternalId, this.props.paymentClient.payment.pmntId));
  };

  loadPaymentAttributes = (event) => {
    event.preventDefault();
    const {dispatch, selectedAppClientExternalId, selectedChannelId} = this.props;
    dispatch(actions.getPaymentAttributes(selectedAppClientExternalId, selectedChannelId, this.props.paymentClient.payment.pmntId));
  };
}

const mapStateToProps = state => {
  return {
    selectedAppClientId: state.appClient.selectedAppClientId,
    selectedAppClientExternalId: state.appClient.selectedAppClientExternalId,
    selectedChannelId: state.appClient.selectedChannelId,
    collab: state.collab.profile,
  }
}
export default connect(mapStateToProps)(muiThemeable()(Mop));
