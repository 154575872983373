import {
  ANIMATE_NOTIFICATION_OUT_SUCCESS,
  ANIMATE_NOTIFICATION_SUCCESS,
  CLOSE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_SUCCESS
} from "./Types"

export const createNotificationSuccess = (id, text, level) => {
  return {
    type: CREATE_NOTIFICATION_SUCCESS,
    id: id,
    text: text,
    level: level,
  }
}

export const animateNotificationSuccess = (id) => {
  return {
    type: ANIMATE_NOTIFICATION_SUCCESS,
    id: id,
  }
}

export const animateNotificationOutSuccess = (id) => {
  return {
    type: ANIMATE_NOTIFICATION_OUT_SUCCESS,
    id: id,
  }
}

export const closeNotificationSuccess = (id) => {
  return {
    type: CLOSE_NOTIFICATION_SUCCESS,
    id: id,
  }
}
