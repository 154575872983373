import * as ServicesURLConstant   from 'utils/ServicesURLConstant';
import { fetchAPI }               from 'utils/UrlHelper';

const GET_PLUGINS_REQUEST = 'GET_PLUGINS_REQUEST';
const GET_PLUGINS_SUCCESS = 'GET_PLUGINS_SUCCESS';
const GET_PLUGINS_FAILURE = 'GET_PLUGINS_FAILURE';

function getPlugins(){
    return dispatch => {
        dispatch({ type: GET_PLUGINS_REQUEST});

        fetchAPI(ServicesURLConstant.GET_PLUGINS_URL)
            .then((response) => response.json())
            .then((responseJson) => {
                dispatch({
                    type: GET_PLUGINS_SUCCESS,
                    data: responseJson
                });
            })
            .catch(error => {
                dispatch({
                    type: 'DISPLAY_MESSAGE',
                    data: {
                        text: error.message,
                        type: 'error'
                    }
                });
                dispatch({ type: GET_PLUGINS_FAILURE });
            })
    }
}

export const actions = {
  getPlugins,
};

const initialState = {
  plugins: [],
  loading: true,
};

export function pluginManagementReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PLUGINS_REQUEST:
            return { ...state, plugins:[], loading: true };
        case GET_PLUGINS_SUCCESS:
            return { ...state, plugins:action.data, loading: false };
        case GET_PLUGINS_FAILURE:
          return { ...state, plugins:[], loading: false };
        default:
            return state;
    }
}
