import * as PropTypes from "prop-types"
import React, {PureComponent} from "react"
import DatePicker from "react-datepicker/es/index"
import Icon from "react-bulma-components/lib/components/icon"
import {formatForShort} from "../../../../utils/DateTools";
import * as moment from "moment";
import Columns from "react-bulma-components/lib/components/columns"
import Column from "react-bulma-components/lib/components/columns/components/column"

// Didn't managed to use (issue with refs, not much time to fix right now
class DatePickerIconInput extends PureComponent {

  static contextFrom = {
    id: "from",
    label: "From",
    icon: {
      id: "calendar",
      position: "right"
    }
  }

  static contextTo = {
    id: "to",
    label: "To",
    icon: {
      id: "calendar-alt",
      position: "left"
    }
  }

  render() {
    const context = this.props.from ? DatePickerIconInput.contextFrom : DatePickerIconInput.contextTo

    return <span>
            <label className="label is-small has-text-centered" htmlFor={context.id}>{context.label}</label>
            <p className={`control has-icons-${context.icon.position}`}>
            <input className={`input has-text-${context.icon.position} is-small`}
                   id={context.id}
                   name={context.id}
                   ref={context.id}
                   defaultValue={this.props.date ? formatForShort(this.props.date) : ""}/>
            <Icon align={context.icon.position} color={this.props.date ? "success" : null}>
            <i className={`fas fa-${context.icon.id}`}/>
          </Icon>
          </p>
          </span>
  }
}

DatePickerIconInput.propTypes = {
  from: PropTypes.bool.isRequired,
  date: PropTypes.any,
}

export class IntervalFilter extends PureComponent {

  render() {

    const fromDate = this.props.from.selected ? this.props.from.selected.toDate() : undefined
    const fromMaxDate = this.props.from.maxDate ? this.props.from.maxDate.toDate() : undefined
    const toDate = this.props.to.selected ? this.props.to.selected.toDate() : undefined
    const toMinDate = this.props.to.minDate ? this.props.to.minDate.toDate() : undefined
    const toMaxDate = this.props.to.maxDate ? this.props.to.maxDate.toDate() : undefined

    return <Columns className="is-gapless">
      <Column>
        <label className="label is-small has-text-centered" htmlFor="from">From</label>
        <div className="control has-icons-right">
          <DatePicker
            title={"From"}
            dropdownMode={"select"}
            selected={fromDate}
            onChange={(date) => this.props.from.onSelect(moment(date))}
            onSelect={(date) => this.props.from.onSelect(moment(date))}
            selectsStart
            startDate={this.props.from.selected ? fromDate : undefined}
            endDate={this.props.to.selected ? toDate : undefined}
            placeholderText="From"
            showTimeSelect
            timeCaption="Hour"
            timeFormat="HH:mm"
            timeIntervals={15}

            maxDate={fromMaxDate}
            className={"input has-text-right is-small is-fullwidth"}
            popperClassName={"date-picker popper"}
            customInputRef="from"
            dateFormat="dd/MM/yyyy HH:mm"
          />
          <Icon align={"right"} size="small" color={this.props.from.selected ? "success" : null}>
            <i className="fas fa-calendar"/>
          </Icon>
        </div>
      </Column>
      <Column>
        <label className="label is-small" htmlFor="to">To</label>
        <div className={"control has-icons-left"}>
          <DatePicker
            title={"To"}
            dropdownMode={"select"}
            selected={toDate}
            onSelect={(date) => this.props.to.onSelect(moment(date))}
            onChange={(date) => this.props.to.onSelect(moment(date))}
            selectsEnd
            startDate={this.props.from.selected ? fromDate : undefined}
            endDate={this.props.to.selected ? toDate : undefined}
            placeholderText="To"
            showTimeSelect
            timeCaption="Hour"
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            minDate={this.props.to.minDate ? toMinDate : undefined}
            maxDate={this.props.to.maxDate ? toMaxDate : undefined}
            popperClassName={"date-picker popper"}
            className={"input is-small has-text-left is-fullwidth"}
            customInputRef="to"
          />
          <Icon align={"left"} size="small" color={this.props.to.selected ? "success" : null} >
            <i className="fas fa-calendar-alt is-small"/>
          </Icon>
        </div>
      </Column>
    </Columns>
  }
}

IntervalFilter.propTypes = {
  from: PropTypes.objectOf(PropTypes.shape({
    selected: PropTypes.string,
    onSelect: PropTypes.func,
    maxDate: PropTypes.string,
  })).isRequired,
  to: PropTypes.objectOf(PropTypes.shape({
    selected: PropTypes.string,
    onSelect: PropTypes.func,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
  })).isRequired,
}
