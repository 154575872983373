import {closeNotification} from "../../api/NotificationApi";
import {connect} from "react-redux";
import Button from "react-bulma-components/lib/components/button";
import Notification from "react-bulma-components/lib/components/notification";
import { CSSTransition } from 'react-transition-group';
import React, {PureComponent} from "react";

class OnePayNotification extends PureComponent{
  render() {
    return <div className='notifications'>
      {Object.entries(this.props.notifications).map(([key, notification]) => {
        return (
          <CSSTransition in={this.props.notifications[key].show}
                         key={notification.id}
                         classNames={{
                           enter: 'notification-enter',
                           enterActive: 'notification-enter-active',
                           appear: 'notification-enter',
                           appearActive: 'notification-enter-active',
                           exit: 'notification-leave',
                           exitActive: 'notification-leave-active',
                         }}
                         timeout={300}
                         unmountOnExit
          >
            <Notification className="onepay" color={notification.level} key={notification.id}><Button remove medium='true'
                                                                                                      onClick={() => closeNotification(
                                                                                                        notification.id)}/>{notification.text}
            </Notification>
          </CSSTransition>
        )
      })}
    </div>
  }
}

const mapStateToProps = (store) => {
  return {
    notifications: store.notifications.notifications,
  }
}

export default connect(mapStateToProps)(OnePayNotification)
