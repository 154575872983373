import React, {PureComponent} from "react"
import * as PropTypes from "prop-types"
import Icon from "react-bulma-components/lib/components/icon";
import Box from "react-bulma-components/lib/components/box";
import {heightForContent} from "../../../../utils/SizeTools";

export default class MultiSelectFilter extends PureComponent {
  render() {

    const enabled = this.props.entries && this.props.entries.length > 0
    const selectedNumber = this.props.entries.filter(entry => entry.props.selected).length
    const color = enabled ? selectedNumber > 0 ? "success" : "info" : "grey"
    const size = this.props.size ? this.props.size : 4;
    const height = (this.props.style && this.props.style.height) || heightForContent(size, 'small')

    const icon = this.props.icon !== undefined ?
      <Icon align={"left"} color={color}>
        <i className={`fas fa-${this.props.icon}`}/>
      </Icon> :
      null

    return <div className="field">
      <label className={`label is-small is-flex`} style={{alignItems: "center"}}>
        {icon}
        {this.props.label}
        <span style={{paddingLeft: "5px"}}>({selectedNumber})</span>
      </label>
      <Box className="is-marginless is-paddingless is-radiusless">
        <div className={`is-marginless is-paddingless${!enabled ? " has-background-grey-lighter is-not-clickable" : ""}`}>
          <div style={{height: height, overflowY: "auto"}}>
            <div className={"is-fullwidth"}
                 style={{
                   display: "flex",
                   flexDirection: "column",
                   flexGrow: "1",
                   position: "relative",
                 }}
            >
              {this.props.entries}
            </div>
          </div>
        </div>
      </Box>
    </div>
  }
}

MultiSelectFilter.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.node).isRequired,
  label: PropTypes.any.isRequired,
  size: PropTypes.number,
  icon: PropTypes.string,
}

export class MultiSelectEntry extends PureComponent {
  render() {
    const insetLevel = (this.props.insetLevel ? this.props.insetLevel : 0) + 1.5

    return <span className={`is-flex`}>
          <input className={`field is-checkradio is-white has-no-border is-small has-big-checkradio inset-${this.props.insetLevel}`}
                 id={this.props.label + this.props.id}
                 type="checkbox"
                 name={this.props.label + this.props.id}
                 onChange={this.props.onChange}
                 checked={this.props.selected}/>
          <label htmlFor={this.props.label + this.props.id}
                 className={`is-radiusless is-marginless is-uppercase${this.props.selected ? " has-text-white has-background-primary" : ""}`}
                 style={
                   {
                     paddingLeft: `${insetLevel}rem`,
                     fontWeight: `calc(1000 / ${insetLevel})`,
                     flexGrow: 1,
                     position: "relative",
                   }
                 }
          >
            <span>{this.props.label}</span>
          </label>
        </span>
  }
}

MultiSelectEntry.propTypes = {
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  insetLevel: PropTypes.number,
  onChange: PropTypes.func,
}
