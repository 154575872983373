import React, {Component} from 'react';
import {connect}          from 'react-redux';
import Column             from "react-bulma-components/lib/components/columns/components/column";
import Columns            from "react-bulma-components/lib/components/columns";
import {actions}          from 'modules/mod-mop-tree-form';
import SelectFilter       from "../transactions/filter/select/SelectFilter";
import FormClient         from "./form/FormClient";
import FormChannel        from "./form/FormChannel";
import FormContext        from "./form/FormContext";

class MopTreeForm extends Component {

  render() {
    return(
      <div>
        <Columns>
          <Column size={12}>
            <SelectFilter onChange={this.handleChangeClient}
                          label={"Customer"}
                          style={{height: "unset"}}
                          options={this.getClients()}
            />
          </Column>
        </Columns>
        <Columns>
          <Column size={12}>
            <FormClient/>
          </Column>
        </Columns>
        <button className="button is-small is-fullwidth" onClick={() => this.addChannel()}>
          <span className="icon is-small">
            <i className="fas fa-plus-circle"></i>
          </span>
          <span>Add Channel</span>
        </button>
        
        { this.props.form.channels.map((channel, index) =>
          <div  key={index}>
            <Columns style={{'margin':'2px', 'borderLeft': '2px solid #0182c3'}} className="is-vcentered">
              <Column size={9}>
                <FormChannel data={channel} index={index}></FormChannel>
              </Column>
              <Column size={3}>
                <button className="button is-small is-fullwidth" onClick={() => this.addContext(index)}>
                  <span className="icon is-small">
                    <i className="fas fa-plus-circle"></i>
                  </span>
                  <span>Add Context</span>
                </button>
              </Column>
            </Columns>
            {channel.contexts.map((context, indexContext) =>
              <Columns key={indexContext} className="is-vcentered" style={{'margin':'2px'}}>
                <Column size={1}>
                  <i className="fas fa-level-up-alt" style={{'transform': 'rotate(90deg)'}}></i>
                </Column>
                <Column size={11} style={{'borderLeft': '2px solid #f26c1f'}}>
                  <FormContext data={context} index={indexContext} channelIndex={index}></FormContext>
                </Column>
              </Columns>
            )}
          </div>
        )}
      </div>
    );
  }

  getClients = () => {
    const {clients} = this.props;
    let optionClients = clients.map((client, index) =>
      <option key={"client" + client.id} value={index}>
        {client.description}
      </option>,
    )

    optionClients.unshift(
      <option key={"add-client"} value={'new'}>
        Create new Client
      </option>)

    return optionClients;
  }
   
  handleChangeClient = (event) => {
    this.props.dispatch(actions.selectClient(this.props.clients[event.target.value]))
  }

  addChannel = () => {
    this.props.dispatch(actions.addChannel());
  }

  addContext = (channelIndex) => {
    this.props.dispatch(actions.addContext(channelIndex))
  }

}

const mapStateToProps = state => {
  return {
    appClient : state.appClient,
    form : state.mopTreeForm.form,
    clients : state.appClient.clients
  }
}

export default connect(mapStateToProps)(MopTreeForm);