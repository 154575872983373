import {
  FETCH_ORDER_HISTORY_FAILURE,
  FETCH_ORDER_HISTORY_REQUEST,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_TRANSACTION_HISTORY_FAILURE,
  FETCH_TRANSACTION_HISTORY_REQUEST,
  FETCH_TRANSACTION_HISTORY_SUCCESS,
  FETCH_TRANSACTION_LOGS_FAILURE,
  FETCH_TRANSACTION_LOGS_REQUEST,
  FETCH_TRANSACTION_LOGS_SUCCESS,
  SET_CURRENT_TRANSACTION_LOG_ACTION
} from "./Types";

export const fetchTransactionHistoryRequest = (transactionId) => {
  return {
    type: FETCH_TRANSACTION_HISTORY_REQUEST,
    data: {
      transactionId,
      logType: "transaction"
    }
  }
}

export const fetchTransactionHistorySuccess = (transactionId, history) => {
  return {
    type: FETCH_TRANSACTION_HISTORY_SUCCESS,
    data: {
      transactionId,
      history: history,
      logType: "transaction",
    }
  }
}

export const fetchTransactionHistoryFailure = () => {
  return {
    type: FETCH_TRANSACTION_HISTORY_FAILURE,
  }
}

export const fetchOrderHistoryRequest = (transactionId, orderId) => {
  return {
    type: FETCH_ORDER_HISTORY_REQUEST,
    data: {
      transactionId,
      orderId,
      logType: "order",
    }
  }
}

export const fetchOrderHistorySuccess = (transactionId, orderId, history) => {
  return {
    type: FETCH_ORDER_HISTORY_SUCCESS,
    data: {
      transactionId,
      orderId,
      history: history,
      logType: "order",
    }
  }
}

export const fetchOrderHistoryFailure = () => {
  return {
    type: FETCH_ORDER_HISTORY_FAILURE,
  }
}

export const fetchTransactionLogsRequest = (transactionId) => {
  return {
    type: FETCH_TRANSACTION_LOGS_REQUEST,
    data: {
      transactionId,
      logType: "logs",
    }
  }
}

export const fetchTransactionLogsSuccess = (transactionId, history) => {
  return {
    type: FETCH_TRANSACTION_LOGS_SUCCESS,
    data: {
      transactionId,
      history: history,
      logType: "logs",
    }
  }
}

export const fetchTransactionLogsFailure = () => {
  return {
    type: FETCH_TRANSACTION_LOGS_FAILURE,
  }
}

export const setCurrentAction = (action) => {
  return {
    type: SET_CURRENT_TRANSACTION_LOG_ACTION,
    data: action,
  }
}
