import React, {PureComponent} from 'react'
import * as PropTypes from 'prop-types'
import {Modal} from "react-bulma-components/lib";
import ModalCard from "react-bulma-components/lib/components/modal/components/card";
import ModalCardHead from "react-bulma-components/lib/components/modal/components/card/head";
import ModalCardBody from "react-bulma-components/lib/components/modal/components/card/body";
import ModalCardFoot from "react-bulma-components/lib/components/modal/components/card/foot";

export default class Confirmation extends PureComponent{

  render() {
    return <Modal onClose={this.props.cancel}
                  show={this.props.active !== undefined && this.props.active}
                  className={`${this.props.active ? "is-clipped" : ""}`}
                  closeOnEsc closeOnBlur showClose>
      <ModalCard onClose={this.props.cancel}
                 className="is-radiusless"
                 modal={{closeOnEsc: true, showClose: true, closeOnBlur: true}}>
        <ModalCardHead showClose
                       onClose={this.props.cancel}
                       className={`has-background-${this.props.headerColor} has-text-${this.props.headerText} is-size-4`}
                       style={{justifyContent: "space-between"}}>
          {this.props.header}
        </ModalCardHead>
        <ModalCardBody>
          {this.props.content}
        </ModalCardBody>
        <ModalCardFoot style={{justifyContent: "space-between"}}>
          <button className="button" onClick={this.props.cancel}>Cancel</button>
          <button className="button is-primary" onClick={this.props.accept}>Apply changes</button>
        </ModalCardFoot>
      </ModalCard>
    </Modal>
  }
}

Confirmation.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  headerColor: PropTypes.oneOf(["primary", "success", "info", "warning", "danger"]).isRequired,
  headerText: PropTypes.oneOf(["white", "black"]).isRequired,
  accept: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  active: PropTypes.bool,
}
