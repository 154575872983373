import React, {Component} from 'react';
import {connect}          from 'react-redux';
import Column             from "react-bulma-components/lib/components/columns/components/column";
import Columns            from "react-bulma-components/lib/components/columns";
import {actions}          from 'modules/mod-mop-tree-form';

class FormClient extends Component {
  render() {
    const {form} = this.props;
    return(
      <div>
        <Columns>
          <Column size={3}>
            <label className="label is-small">Description</label>
            <input className="input is-small" name="description" value={form.description || ''} type="text" placeholder={'Short description of the merchant, its usage,...'} onChange={this.handleInputChange} />
          </Column>
          <Column size={3}>
            <label className="label is-small">Currency</label>
            <input className="input is-small" name="currency" value={form.currency || ''} type="text" placeholder={'Currency using ISO 4217 format'} onChange={this.handleInputChange} />
          </Column>
          <Column size={3}>
            <label className="label is-small">External ID</label>
            <input className="input is-small" name="externalId" value={form.externalId || ''} type="text" placeholder={'ID used in API calls'} onChange={this.handleInputChange} />
          </Column>
          <Column size={3}>
            <label className="label is-small">External Id Iso</label>
            <input className="input is-small" name="externalIdIso" value={form.externalIdIso || ''} type="text" placeholder={'External Id Iso'} onChange={this.handleInputChange} />
          </Column>
        </Columns>
      </div>
    );
  }

  handleInputChange = (event) =>{
    const {dispatch} = this.props;
    const target = event.target;
    const value = target.value;
    const name = target.name;
    dispatch(actions.updateFormClient(name, value));
  }

}


const mapStateToProps = state => {
  return {
    form : state.mopTreeForm.form
  }
}

export default connect(mapStateToProps)(FormClient);