import * as ServicesURLConstant   from 'utils/ServicesURLConstant';
import { fetchAPI }               from 'utils/UrlHelper';
import _ from "lodash";


/***************************************************************/
/* Actions
/***************************************************************/
const SET_USER_ID_ACCESS = 'SET_USER_ID_ACCESS';
const SET_USER_ADMIN_ACCESS = 'SET_USER_ADMIN_ACCESS';
const SET_USER_ROLE_ACCESS = 'SET_USER_ROLE_ACCESS';
const SET_FILTER_ACCESS = 'SET_FILTER_ACCESS';

/**
 * Generic dispatch function
 *
 * @param selectAction
 * @param value
 * @returns {function(*)}
 */
function selectElement(selectAction, value) {
  return dispatch => {
    dispatch({
      type: selectAction,
      data: value
    });
  }
}

function setUserId(value) {
  return selectElement(SET_USER_ID_ACCESS, value);
}

function selectAdmin(value) {
  return selectElement(SET_USER_ADMIN_ACCESS, value);
}

function selectRole(value, name) {

  var data = {newData: {name: name, value: value}};

  return selectElement(SET_USER_ROLE_ACCESS, data);
}

function selectFilter(value){
  return selectElement(SET_FILTER_ACCESS, value);
}

const GET_ALL_ROLES_REQUEST = 'GET_ALL_ROLES_REQUEST';
const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
const GET_ALL_ROLES_FAILURE = 'GET_ALL_ROLES_FAILURE';

function getAllRoles(){
  return dispatch => {
    dispatch({ type: GET_ALL_ROLES_REQUEST});

      fetchAPI(ServicesURLConstant.GET_ALL_ROLES)
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch({
          type: GET_ALL_ROLES_SUCCESS,
          data: responseJson.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error'
          }
        });
        dispatch({ type: GET_ALL_ROLES_FAILURE });
      })
  }
}

const ACCESS_USER_INFOS_REQUEST = 'ACCESS_USER_INFOS_REQUEST';
const ACCESS_USER_INFOS_SUCCESS = 'ACCESS_USER_INFOS_SUCCESS';
const ACCESS_USER_INFOS_FAILURE = 'ACCESS_USER_INFOS_FAILURE';

function getUserInformations(userId){
  return dispatch => {
    dispatch({ type: ACCESS_USER_INFOS_REQUEST});
    if (userId != null) {

      fetchAPI(ServicesURLConstant.getUserInformations(userId))
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch({
          type: ACCESS_USER_INFOS_SUCCESS,
          data: {userId, infos: responseJson.data}
        });
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error'
          }
        });
        dispatch({ type: ACCESS_USER_INFOS_FAILURE });
      })
    }
  }
}

const ACCESS_USER_SAVE_REQUEST = 'ACCESS_USER_SAVE_REQUEST';
const ACCESS_USER_SAVE_SUCCESS = 'ACCESS_USER_SAVE_SUCCESS';
const ACCESS_USER_SAVE_FAILURE = 'ACCESS_USER_SAVE_FAILURE';

function saveAccess(accessAttributes, userId) {
  return dispatch => {
    dispatch({
      type: ACCESS_USER_SAVE_REQUEST
    });

    if(accessAttributes.access) {
      var updateAttributes = Array.from(accessAttributes.access.entries(),
          entry => ({appClientId: entry[0], roleId: entry[1]}));
    }

    fetchAPI(ServicesURLConstant.setUserInformations(userId),
        { method: "PUT", body: JSON.stringify({access: updateAttributes, isAdmin: accessAttributes.isAdmin}) })
    .then((response) => {
      if (!response.ok) {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: "Error while saving ",
            type: 'error'
          }
        });
      } else {
        dispatch({ type: ACCESS_USER_SAVE_SUCCESS });
      }
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
      dispatch({ type: ACCESS_USER_SAVE_FAILURE });
    })
  };
}

export const actions = {
  setUserId:setUserId,
  selectAdmin:selectAdmin,
  selectRole:selectRole,
  selectFilter:selectFilter,
  getAllRoles:getAllRoles,
  getUserInformations:getUserInformations,
  saveAccess:saveAccess,
};

/***************************************************************/
/* Reducer functions
/***************************************************************/
const formInitialState = {
  userFound: false,
  userId: '',
  filter: "all",
  showClient: "",
  access: new Map(),
  isSaving: false,
};

const initialState = {
  ...formInitialState,
  content: new Map(),

};

export function accessReducer(state = initialState, action) {
  switch (action.type) {
    // Get All Roles
    case GET_ALL_ROLES_REQUEST:{
      return {...state, roles: null};
    }
    case GET_ALL_ROLES_SUCCESS: {
      return {...state, roles: action.data};
    }
    case GET_ALL_ROLES_FAILURE: {
      return { ...state, roles: null };
    }

    // Access User Infos
    case ACCESS_USER_INFOS_REQUEST:{
      return {...state, userData: {content:null, userFound:false, loading: true}, filter: "all", isAdmin: undefined};
    }
    case ACCESS_USER_INFOS_SUCCESS: {
      state.access.clear();
      if(action.data.infos.userId == null)
        return {...state, userData: {content: null, userFound: false}};
      else
        return {...state, userData: {content: action.data.infos, userFound: true, loading: false}};
    }
    case ACCESS_USER_INFOS_FAILURE: {
      return { ...state, userFound: false };
    }

    // Save data
    case ACCESS_USER_SAVE_REQUEST:
      return { ...state, isSaving: true };

    case ACCESS_USER_SAVE_SUCCESS:
      state.access.clear();
      return { ...state, isSaving: false };

    case ACCESS_USER_SAVE_FAILURE:
      return { ...state, isSaving: false };

    // Setter
    case SET_USER_ID_ACCESS:

      return { ...state, userId: action.data, userData: undefined };

    case SET_USER_ADMIN_ACCESS:

      return { ...state, isAdmin: action.data};

    case SET_USER_ROLE_ACCESS:
      state.access.set(action.data.newData.name, action.data.newData.value);
      state.userData.content.access.forEach((value, key) => {
        if (value.appClientId === action.data.newData.name) {
          state.userData.content.access[key].roleId = action.data.newData.value;
        }
      });

      let clonedAccess = _.cloneDeep(state.access);
      clonedAccess.valueChanged = true;

      return { ...state, access: clonedAccess };
    case SET_FILTER_ACCESS :
      return { ...state, filter: action.data };

    default:
      return state;
  }
}
