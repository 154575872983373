import React from "react"
import Columns from "react-bulma-components/lib/components/columns"
import * as PropTypes from "prop-types"
import Box from "react-bulma-components/lib/components/box";

const LeftColumn = (props) => {

  if(props.pspName === undefined &&  props.channel === undefined &&  props.context === undefined && props.transactionId === undefined){
    console.log(`All props are empty, not printing left column`)
    return <></>
  }

  return <Columns.Column narrow>
    <Box>
      <div>
        <ul>
          {props.pspName !== undefined && props.pspName !== null && props.pspName !== "" ? <li><b>PSP:</b> {props.pspName}</li> : null}
          {props.channel !== undefined && props.channel !== null && props.channel !== "" ? <li><b>Channel:</b> {props.channel}</li> : null}
          {props.context !== undefined && props.context !== null && props.context !== "" ? <li><b>Context:</b> {props.context}</li> : null}
          {props.transactionId !== undefined ? <li><b>Transaction:</b> {props.transactionId}</li> : null}
        </ul>
      </div>
    </Box>
  </Columns.Column>
}

LeftColumn.propTypes = {
  pspName: PropTypes.string,
  channel: PropTypes.string,
  context: PropTypes.string,
  transactionId: PropTypes.number,
}

export default LeftColumn
