import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {
  actions as actionBlockActions,
  selectors as actionBlockSelectors,
} from 'modules/transaction/mod-transaction-analyzer-actions'
import {selectedRows} from "../../api/TransactionApi"
import Button from "react-bulma-components/lib/components/button"
import ModalCardBody
  from "react-bulma-components/lib/components/modal/components/card/body"
import ModalCardHead
  from "react-bulma-components/lib/components/modal/components/card/head"
import ModalCardFoot
  from "react-bulma-components/lib/components/modal/components/card/foot"
import ModalCard
  from "react-bulma-components/lib/components/modal/components/card"
import SelectFilter from "./filter/select/SelectFilter"

class TransactionAnalyzerActionsBlock extends Component {

  render() {
    return (
      <ModalCard onClose={this.props.handleCloseActionsBlock}>
        <ModalCardHead
          className={'has-background-info has-text-white is-size-4'}
          style={{justifyContent: "space-between"}}
          onClose={this.props.handleCloseActionsBlock}
        >
          Actions
        </ModalCardHead>

        <ModalCardBody>


          <div className="field is-flex"
               style={{justifyContent: "space-between"}}>

            <input className="is-checkradio"
                   id="imethod_relaunch"
                   type="radio"
                   name="method"
                   value="relaunch"
                   onClick={e => this.handleChangeMethod(e, 'relaunch')}
            />
            <label htmlFor="imethod_relaunch">Relaunch action</label>

            <input className="is-checkradio"
                   id="method_force_status"
                   type="radio"
                   name="method"
                   value="force_status"
                   onClick={e => this.handleChangeMethod(e, 'force_status')}
            />
            <label htmlFor="method_force_status">Change status</label>

            <input className="is-checkradio"
                   id="method_notify"
                   type="radio"
                   name="method"
                   value="notify"
                   onClick={e => this.handleChangeMethod(e, 'notify')}
            />
            <label htmlFor="method_notify">Renotify</label>

          </div>

          <SelectFilter label={'Action'}
                        options={this.getActions()}
                        value={this.props.selectedAction}
                        disabled={!this.props.selectedMethod}
                        onChange={this.handleChangeAction}/>

          <SelectFilter label={'Status'}
                        options={this.getStatuses()}
                        value={this.props.selectedStatus}
                        disabled={this.isStatusDisable()}
                        onChange={this.handleChangeStatus}/>
        </ModalCardBody>

        <ModalCardFoot style={{justifyContent: "space-between"}}>
          <Button reset
                  onClick={this.props.handleCloseActionsBlock}
                  disabled={this.props.loading}
          >
            Cancel
          </Button>

          <Button submit fullwidth
                  color={'primary'}
                  onClick={this.handleValidateClick}
                  disabled={!this.isStatusDefined() || this.props.loading}
                  loading={this.props.loading}>
            Validate
          </Button>
        </ModalCardFoot>
      </ModalCard>
    )
  }

  handleChangeMethod = (event, value) => {
    this.props.dispatch(actionBlockActions.selectMethod(value))
  }

  handleChangeAction = (event) => {
    this.props.dispatch(actionBlockActions.selectAction(event.target.value))
  }

  handleChangeStatus = (event) => {
    this.props.dispatch(actionBlockActions.selectStatus(event.target.value))
  }

  handleValidateClick = () => {
    const {selectedMethod, currentSearchFormValues, selectedRowsValues, selectedStatus, selectedAction} = this.props

    // Change this using Transaction.checked only
    const transactionsWithDetail = []
    selectedRows(selectedRowsValues).forEach(value => transactionsWithDetail.push({
      transactionId: value,
      transactionDetailId: this.props.transactions.find(transaction => transaction.transactionId === value).transactionDetailId,
      v2: this.props.transactions.find(transaction => transaction.transactionId === value).v2
    }))

    switch (selectedMethod) {
      case 'force_status':
        if (selectedStatus != null) {
          const forceTransactionParameters = {
            searchCriteria: currentSearchFormValues,
            transactions: transactionsWithDetail,
            newStatus: {
              type: selectedAction,
              description: selectedStatus,
            },
          }
          this.props.dispatch(actionBlockActions.launchForceStatus(forceTransactionParameters))
        }
        break
      case 'relaunch':
        const relaunchTransactionParameters = {
          searchCriteria: currentSearchFormValues,
          transactions: transactionsWithDetail,
          contract: selectedAction,
        }
        this.props.dispatch(actionBlockActions.launchRelaunchTransact(relaunchTransactionParameters))
        break
      case 'notify':
        const notifyTransactionParameters = {
          searchCriteria: currentSearchFormValues,
          transactions: transactionsWithDetail,
          contract: selectedAction,
        }
        this.props.dispatch(actionBlockActions.launchNotify(notifyTransactionParameters))
        break
      default:
    }
  }
}


TransactionAnalyzerActionsBlock.prototype.getActions = function () {

  const selectedTransactions = selectedRows(this.props.selectedRowsValues)
    .map(id => this.props.transactions.find(transaction => transaction.transactionId === id))

  /* we keep only the actions that are commons to all the selected transactions */
  const availableActions = selectedTransactions
    .map(transaction => transaction.availableActions)
    .reduce((allActions, transactionActions) => allActions.filter(action => transactionActions.includes(action)))

  if (!this.props.selectedMethod || availableActions.length < 1) {
    return []
  }

  let init = [];


  if (this.props.selectedAction === null) {
    init = init.concat(<option key={'init'}/>)
  }

  return init.concat(availableActions.map(action => (
      <option key={"action" + action}
              value={action}>
        {action}
      </option>
    ),
  ))
}

TransactionAnalyzerActionsBlock.prototype.getStatuses = function () {

  const selectedTransactions = selectedRows(this.props.selectedRowsValues)
    .map(id => this.props.transactions.find(transaction => transaction.transactionId === id))

  const availableStates = selectedTransactions[0].availableStates
  // If the action selected is not refund we remove the manual status
  if(this.props.selectedAction !== 'DoRefund'){
    availableStates.filter(state => ! state.endsWith('MANUAL'))
  }

  if (this.isStatusDisable() || availableStates.length < 1) {
    return []
  }

  let init = [];

  if (this.props.selectedStatus === null) {
    init = init.concat(<option key={'init'}/>)
  }

  return init.concat(availableStates.map(status => (
      <option key={"status" + status}
              value={status}>
        {status}
      </option>
    ),
  ))
}

TransactionAnalyzerActionsBlock.prototype.isStatusDefined = function () {
  const {selectedAction, selectedStatus} = this.props

  return (this.props.selectedMethod === 'relaunch' || this.props.selectedMethod === 'notify') ?
    selectedAction !== null :
    selectedAction !== null && selectedStatus !== null
}

TransactionAnalyzerActionsBlock.prototype.isStatusDisable = function () {
  return this.props.selectedMethod === 'relaunch' ||
    this.props.selectedMethod === 'notify'
}

TransactionAnalyzerActionsBlock.propTypes = {
  handleCloseActionsBlock: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    selectedRowsValues: state.analyzer.form.selectedRowsTransaction,
    transactions: state.analyzer.form.transactions,
    actions: actionBlockSelectors.actionsSelector(state),
    statuses: actionBlockSelectors.statusesSelector(state),
    selectedMethod: state.analyzer.actions.selectedMethod,
    selectedAction: state.analyzer.actions.selectedAction,
    selectedStatus: state.analyzer.actions.selectedStatus,
    currentSearchFormValues: state.analyzer.form.currentSearchFormValues,
    enabled: state.analyzer.actions.enabled,
    loading: state.analyzer.actions.loading,
  }
}

export default connect(mapStateToProps)(TransactionAnalyzerActionsBlock)
