import {fetchAPI} from "../utils/UrlHelper"
import * as ServicesURLConstant from "../utils/ServicesURLConstant"
import {createNotification} from "./NotificationApi"
import {
  fetchMeta,
  fetchMetaFailure,
  fetchMetaSuccess,
} from "../actions/MetaActions"

export const init = () => {

  return dispatch => {

    dispatch(fetchMeta())

      fetchAPI(ServicesURLConstant.getMeta())
        .then((response) => response.json())
        .then((responseJson) => dispatch(fetchMetaSuccess(responseJson.build)))
        .catch(error => {
          dispatch(createNotification(error.message, "danger"))
          dispatch(fetchMetaFailure())
        })
    }
}
