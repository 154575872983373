import React, {Component} from 'react'
import {connect} from "react-redux"
import YesNoIcon from "../transactions/details/common/YesNoIcon"
import {actions} from "../../modules/mod-mops-config"
import * as _ from "lodash"
import {isAtLeast} from "../../utils/SecurityTools"

const ContractAttribute = (props) => {
  return <tr>
    <td>{props.contractKey}</td>
    <td className="text-uppercase small">{props.description}</td>
    <td className="has-text-centered"><YesNoIcon value={props.contract.loading ? undefined : !props.optional}
                                                 onClick={props.makeOptional}/></td>
    <td className="has-text-centered"><YesNoIcon value={!props.toDefine} onClick={props.makeToDefine}/></td>
  </tr>
}

const ContractAttributes = (props) => {
  return <tbody>
  {
    props.attributes.map(attribute => {
      return <ContractAttribute key={attribute.key}
                                contractKey={attribute.key}
                                description={attribute.description}
                                optional={attribute.optional}
                                toDefine={attribute.toDefine}
                                contract={props.contract}
                                makeOptional={props.support ? props.update('optional', !attribute.optional, attribute.key, props.contract) : undefined}
                                makeToDefine={attribute.toDefine && props.support ? props.update('toDefine', !attribute.toDefine, attribute.key, props.contract) : undefined}
      />
    })
  }
  </tbody>
}

const ContractAttributesHead = () => {
  return <thead>
  <tr>
    <th style={{width: "20%"}}>Key</th>
    <th style={{width: "60%"}}>Description</th>
    <th className="has-text-centered" style={{width: "10%"}}>Mandatory</th>
    <th className="has-text-centered" style={{width: "10%"}}>Activated</th>
  </tr>
  </thead>
}

const ContractRow = (props) => {
  return <div className="box is-flex is-radiusless" style={{
    flexDirection: "column",
    padding: "15px 0 0 0",
  }}
  >
    <h4 className="subtitle is-5 is-fullwidth">{props.action}</h4>
    <table className="table is-striped is-narrow is-hoverable is-fullwidth">
      <ContractAttributesHead/>
      <ContractAttributes attributes={props.attributes || []}
                          contract={props.contract}
                          update={props.update}
                          support={props.support}
      />
    </table>
  </div>
}

class ContractsView extends Component {

  render() {
    return <div className="box has-text-centered">
      <h1 className="title is-3 is-fullwidth">Contracts</h1>
      {
        this.props.contracts.map(contract => {
          return <ContractRow key={contract.action}
                              action={contract.action}
                              attributes={contract.attributes || []}
                              contract={contract}
                              update={this.update}
                              loading={this.props.loading}
                              support={isAtLeast('ROLE_SUPPORT', this.props.collab, this.props.appClientId)}
          />
        })
      }
    </div>
  }

  update = (key, value, attributeKey, contract) => {
    return () => {

      const updatedContract = _.cloneDeep(contract)

      contract.attributes.forEach((attribute, index) => {
        if (attribute.key === attributeKey) {
          updatedContract.attributes[index][key] = value
        }
      })

      this.props.dispatch(actions.updateContract(this.props.appClient, this.props.payment, updatedContract, attributeKey))
    }
  }

}

const mapStateToProps = (state) => {
  return {
    loading: state.meanOfPayments.contracts.loading,
    contracts: state.meanOfPayments.contracts.data,
    appClient: state.appClient.selectedAppClientExternalId,
    appClientId: state.appClient.selectedAppClientId,
    payment: state.meanOfPayments.mopConfiguration.paymentId,
    collab: state.collab.profile,
  }
}

export default connect(mapStateToProps)(ContractsView)
