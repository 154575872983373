import { combineReducers }          from 'redux'
import { routerReducer as routing } from 'react-router-redux';
import {applicationClientReducer}   from 'modules/mod-client-application';
import {mopsReducer}                from 'modules/mod-mops-config';
import {collabReducer}              from 'modules/mod-collab';
import {messageReducer}             from 'modules/mod-message';
import {getPaymentListWidgetReducer}          from 'modules/mod-paymentListTest';
import {transactionAnalyzerFormReducer}   from 'modules/transaction/mod-transaction-analyzer-form';
import {transactionAnalyzerActionsReducer}   from 'modules/transaction/mod-transaction-analyzer-actions';
import {cacheManagementReducer}         from 'modules/admin/mod-cacheManagement.js';
import {pluginManagementReducer}         from 'modules/admin/mod-pluginManagement.js';

import {batchReducer}          from 'modules/admin/mod-batch';
import {accessReducer}   from 'modules/admin/mod-access';
import {parametersReducer}     from 'modules/mod-parameters';
import {notificationReducer} from "../reducers/NotificationReducer";
import {transactionAnalyzerDetailReducer} from "../reducers/TransactionReducer";
import {logManagementReducer} from "../reducers/LogReducers";
import {metaReducer} from "../reducers/MetaReducers";
import {mopTreeFormReducer} from 'modules/mod-mop-tree-form';

/** Combine all reducers */
const rootReducer = combineReducers({
  routing,
  meta: metaReducer,
  appClient : applicationClientReducer,
  meanOfPayments: mopsReducer,
  message: messageReducer,
  paymentListWidget: getPaymentListWidgetReducer,
  collab: collabReducer,
  admin: combineReducers({
    batch : batchReducer,
    plugins : pluginManagementReducer
  }),
  mopTreeForm : mopTreeFormReducer,
  parameters: parametersReducer,
  analyzer:  combineReducers({
    form : transactionAnalyzerFormReducer,
    actions: transactionAnalyzerActionsReducer
  }),
  transactionDetails:transactionAnalyzerDetailReducer,
  UserDetail:accessReducer,
  log: logManagementReducer,
  cache: cacheManagementReducer,
  notifications: notificationReducer,
});

export default rootReducer;

