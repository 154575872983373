import React, {Component} from 'react';
import {connect}          from 'react-redux';
import Column             from "react-bulma-components/lib/components/columns/components/column";
import Columns            from "react-bulma-components/lib/components/columns";
import {actions}          from 'modules/mod-mop-tree-form';

class FormContext extends Component {
  render() {
    const { data } = this.props;
    return(
      <div>
        <span className="label is-small has-text-warning">Context { data.default && 'DEFAULT' }</span>
        <Columns>
          <Column>
            <label className="label is-small">Type</label>
            <input className="input is-small" name="type" type="text" value={data.type || '' } placeholder={'Type'} onChange={this.handleInputChange} />
          </Column>
          <Column>
            <label className="label is-small">Description</label>
            <input className="input is-small" name="description" type="text" value={data.description || '' } placeholder={'Short description'} onChange={this.handleInputChange} />
          </Column>
        </Columns>
      </div>
    );
  }

  handleInputChange = (event) =>{
    const {dispatch, index, channelIndex} = this.props;
    const target = event.target;
    const value = target.value;
    const name = target.name;
    dispatch(actions.updateFormContext(name, value, index, channelIndex));
  }

}


const mapStateToProps = state => {
  return {
    form : state.mopTreeForm.form
  }
}

export default connect(mapStateToProps)(FormContext);