import React, { Component } from 'react';

import muiThemeable       from 'material-ui/styles/muiThemeable'
import Table from "react-bulma-components/lib/components/table"

import BatchExecutionStepRow from 'components/admin/batch/BatchExecutionStepRow';

class BatchExecutionStepTable extends Component {

  render() {
    return (
      <Table striped className="is-hoverable">
        <thead>
          <tr>
            <th>Step name </th>
            <th>Status</th>
            <th>Exit status</th>
            <th>Reads / skipped</th>
            <th>Writes / skipped</th>
            <th>Processed skipped</th>
            <th>Filtered</th>
            <th>Commits</th>
            <th>Rollbacks</th>
            <th>Start</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {this.getJobExecutionStepRows()}
        </tbody>
      </Table>
    );
  }

  getJobExecutionStepRows(){
    const {jobExecution} = this.props;
    return jobExecution.stepExecutions.map(step =>
      <BatchExecutionStepRow stepExecution={step}/>
    )
  }
}

export default muiThemeable()(BatchExecutionStepTable);
