import React from "react"
import * as PropTypes from "prop-types"
import Icon from "react-bulma-components/lib/components/icon"


const YesNoIcon = (props) => {

  let color = ""
  let icon = ""
  let alt = ""
  let clickable = props.onClick !== undefined
  let onClick = props.onClick

  if (props.value === null || props.value === undefined) {
    color = "dark"
    icon = "circle-notch fa-spin"
    alt = "unknown"
    clickable = false
    onClick = undefined
  } else if (!props.value) {
    color = "danger"
    icon = "ban"
    alt = "no"
  } else if (props.value) {
    color = "success"
    icon = "check"
    alt = "yes"
  }

  return <Icon color={color} aria-label={alt} style={{cursor: clickable ? "pointer" : "unset"}} onClick={onClick}>
    <abbr title={alt}>
      <i className={`fas fa-${icon}`}/>
    </abbr>
  </Icon>
}

YesNoIcon.propTypes = {
  value: PropTypes.bool,
  onClick: PropTypes.func,
}

export default YesNoIcon
