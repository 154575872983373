import React, { Component }   from 'react';
import {connect}              from 'react-redux';
import './index.scss';
import Header       from 'components/Header';
import Snackbar     from 'material-ui/Snackbar';
import AlertWarning from 'material-ui/svg-icons/alert/warning';
import 'typeface-roboto'

import { loadParameters }  from 'modules/mod-parameters';

export class App extends Component {

  componentDidMount(){
    this.props.dispatch(loadParameters());
  }

  render() {
    const snackBarMessage = this.props.message !== undefined ? this.props.message.text : undefined;
    return (
      <div>
        <Header path={this.props.path} />
        <Snackbar
          open={snackBarMessage !== undefined}
          message={<span style={{color: 'red'}}><AlertWarning color="#fff" style={{verticalAlign: 'middle'}}/> {snackBarMessage}</span>}
          onRequestClose={this.handleRequestClose}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    message: state.message
  }
}

export default connect(mapStateToProps)(App);
