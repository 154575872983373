import React, {PureComponent} from "react"
import * as PropTypes from "prop-types"
import YesNoIcon from "./YesNoIcon"
import {getIconFor} from "../../../../utils/Status"
import Icon from "react-bulma-components/lib/components/icon";

const HistoryRowHeader = (props) => {
  return <tr>
    <th>
      <abbr title={"Status and associated date"}>Timeline</abbr>
    </th>
    {props.isOrder ? <th>Transaction</th> : null}
    {props.isOrder ? <th>PSP</th> : null}
    {props.isOrder ? <th>Payment</th> : null}
    <th>Amount</th>
    <th>
      <abbr title={"Has client been notified ?"}>Notify</abbr>
    </th>
    <th>
      <abbr title={"Try count of the action"}># Retry</abbr>
    </th>
  </tr>
}

HistoryRowHeader.propTypes = {
  isOrder: PropTypes.bool,
}

function ErrorIcon(props) {
  return <Icon color="danger"
               align="right"
               size={"small"}
               className="tooltip is-tooltip-danger is-tooltip-multiline"
               data-tooltip={props.dataTooltip}
               style={{marginLeft: "0.5rem"}}
  >
    <i className="fas fa-times-circle"/>
  </Icon>
}

WarningIcon.propTypes = {dataTooltip: PropTypes.string}

function WarningIcon(props) {
  return <Icon color="warning"
               align="right"
               size={"small"}
               className="tooltip is-tooltip-warning is-tooltip-multiline"
               data-tooltip={props.dataTooltip}
               style={{marginLeft: "0.5rem"}}
  >
    <i className="fas fa-exclamation-triangle"/>
  </Icon>
}

WarningIcon.propTypes = {dataTooltip: PropTypes.string}

function InfoIcon(props) {
  return <Icon color="info"
               align="right"
               size={"small"}
               className="tooltip is-tooltip-info is-tooltip-right is-tooltip-multiline"
               data-tooltip={props.dataTooltip}
               style={{marginLeft: "0.5rem"}}
  >
    <i className="fas fa-info-circle"/>
  </Icon>
}

InfoIcon.propTypes = {dataTooltip: PropTypes.string}


export const HistoryRowEntry = (props) => {

  const temporaryStyleIncompatMaterialUi = {verticalAlign: "middle"}

  const {icon, color} = getIconFor(props.transactionStatus)

  return <tr>
    <td className={"timeline"} style={temporaryStyleIncompatMaterialUi}>
      <div className="timeline-item" style={{paddingBottom: "unset"}}>
        <div className={`timeline-marker is-icon is-${color}`}>
          <i className={`fa fa-${icon}`} />
        </div>
        <div className="timeline-content is-flex" style={{flexDirection: "column"}}>
          <p className="heading">{props.date}</p>
          <p className="has-icons-right">
            <span>
              {props.transactionStatus}
            </span>
            {
              props.statusCode && props.statusCode !== "" ?
              <InfoIcon dataTooltip={`${props.statusCode} : ${props.statusDescription}`}/> :
              null
            }
            {
              props.comment && props.comment !== "" ?
                <WarningIcon dataTooltip={props.comment}/> :
                null
            }
          </p>
        </div>
      </div>
    </td>
    {props.isOrder ? <td style={temporaryStyleIncompatMaterialUi}>{props.transactionId}</td> : null}
    {props.isOrder ? <td style={temporaryStyleIncompatMaterialUi}>{props.pspName}</td> : null}
    {props.isOrder ? <td style={temporaryStyleIncompatMaterialUi}>{props.paymentName}</td> : null}
    <td style={temporaryStyleIncompatMaterialUi}>{props.amount !== null ? (props.transactType === "REFUND" ? "- " : "") + props.amount : 0} {props.currency}</td>
    <td style={temporaryStyleIncompatMaterialUi}>
      {
        props.error && props.error !== "" ?
          <ErrorIcon dataTooltip={props.error}/> :
          <YesNoIcon value={props.notified}/>
      }
    </td>
    <td style={temporaryStyleIncompatMaterialUi}>{props.nbRelaunch}</td>
  </tr>
}

HistoryRowEntry.propTypes = {
  transactionId: PropTypes.number,
  transactionStatus: PropTypes.string.isRequired,
  statusCode: PropTypes.string.isRequired,
  statusDescription: PropTypes.string.isRequired,
  pspName: PropTypes.string,
  paymentName: PropTypes.string,
  date: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  transactType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  nbRelaunch: PropTypes.number.isRequired,
  notified: PropTypes.bool
}

export default class HistoryRow extends PureComponent {

  static Header = HistoryRowHeader
  static Entry = HistoryRowEntry

  render() {
    const isOrder = this.props.isOrder

    const body = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {isOrder: isOrder}, child.children))

    return <>
      <thead>
      <HistoryRowHeader isOrder={isOrder} />
      </thead>
      <tbody>
      {body}
      </tbody>
    </>
  }
}

HistoryRow.propTypes = {
  isOrder: PropTypes.bool,
}
