import React, {Component} from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import TransactionAnalyzerForm from 'components/transactions/TransactionAnalyzerForm';
import TransactionAnalyzerResultList from 'components/transactions/TransactionAnalyzerResultList';
import {connect} from "react-redux";
import OnePayNotification from "../shared/OnePayNotification";

class TransactionAnalyzer extends Component {

  render() {
    return (
      <div style={{ ...this.props.muiTheme.pageContainer }}>
        <TransactionAnalyzerForm/>
        <TransactionAnalyzerResultList/>
        <OnePayNotification/>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps)(muiThemeable()(TransactionAnalyzer));
