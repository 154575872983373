import React, {PureComponent, useState} from 'react'
import {connect} from "react-redux"
import Icon from "react-bulma-components/lib/components/icon"
import * as _ from "lodash"
import {actions} from "../../modules/mod-mops-config"
import {isAtLeast} from "../../utils/SecurityTools"

const AttributeRow = (props) => {

  const initialValue = props.attributeEncrypted ? 'n1c3_7Ry-M473!' : props.attributeValue
  const [value, updateValue] = useState(initialValue)

  const disabled = props.attributeLoading || !props.support
  const type = props.attributeEncrypted ? 'password' : 'text'

  const updated = value !== initialValue

  const icon = props.attributeLoading ? 'circle-notch fa-spin' : `lock${props.attributeEncrypted ? '' : '-open'}`
  const iconColor = props.attributeLoading ? 'dark' : props.support ? 'success' : 'warning'

  const submit = (event) => {
    event.preventDefault()
    if (updated && !disabled) {
      props.updateValue(props.attributeKey, value)
    }
  }

  const reset = (event) => {
    event.preventDefault()
    updateValue(initialValue)
  }

  return <tr>
    <td>{props.attributeKey}</td>
    <td>{props.attributeDescription}</td>
    <td>{
      <form className="field has-addons" onSubmit={(event) => submit(event)}>
        <p className="control has-icons-left" style={{flexGrow: 1}}>
          <input className="input is-small"
                 id={props.attributeKey}
                 name={props.attributeKey}
                 value={value}
                 type={type}

                 readOnly={disabled}
                 disabled={disabled}

                 onChange={(event) => updateValue(event.target.value)}
          />
          <Icon className="is-left" color={iconColor}>
            <i className={`fas fa-${icon}`}/>
          </Icon>
        </p>
        {updated ?
          <>
            <p className="control">
              <button
                className={`button is-small is-success${props.attributeLoading ? ' is-loading' : ''}`}
                type="submit"
                onClick={(event) => submit(event)}>
                Apply
              </button>
            </p>
            <p className="control">
              <button
                className={`button is-small is-error${props.attributeLoading ? ' is-loading' : ''}`}
                type="button"
                onClick={(event) => reset(event)}>
                Cancel
              </button>
            </p>
          </> :
          null
        }
      </form>
    }
    </td>
  </tr>
}

function AttributeHead() {
  return <thead>
  <tr>
    <th style={{width: "20%"}}>Key</th>
    <th style={{width: "40%"}}>Description</th>
    <th style={{width: "40%"}}>Value</th>
  </tr>
  </thead>
}

class PaymentAttributesView extends PureComponent {

  render() {
    return <div className="box has-text-centered"
                style={{padding: "15px 0 3px"}}>
      <h1 className="title is-3 is-fullwidth">Payment attributes</h1>
      <table className="table is-striped is-narrow is-hoverable is-fullwidth">
        <AttributeHead/>
        <tbody>
        {Object.keys(this.props.paymentAttributes).map(objectKey =>
          <AttributeRow key={objectKey}
                        attributeKey={this.props.paymentAttributes[objectKey].key}
                        attributeDescription={this.props.paymentAttributes[objectKey].description}
                        attributeValue={this.props.paymentAttributes[objectKey].value}
                        attributeEncrypted={this.props.paymentAttributes[objectKey].encrypted}
                        attributeLoading={!!this.props.paymentAttributes[objectKey].loading}
                        updateValue={(key, value) => this.update(key, value)}
                        support={isAtLeast('ROLE_SUPPORT', this.props.collab, this.props.appClientId)}
          />,
        )}
        </tbody>
      </table>
    </div>
  }

  update = (key, value) => {
    const updatedAttribute = _.cloneDeep(this.props.paymentAttributes)[key]

    updatedAttribute.value = value

    this.props.dispatch(actions.updatePaymentAttribute(this.props.appClient, this.props.selectedChannelId, this.props.payment, updatedAttribute))
  }

}

const mapStateToProps = (state) => {
  return {
    loading: state.meanOfPayments.paymentAttributes.loading,
    paymentAttributes: state.meanOfPayments.paymentAttributes.data,
    appClient: state.appClient.selectedAppClientExternalId,
    appClientId: state.appClient.selectedAppClientId,
    selectedChannelId: state.appClient.selectedChannelId,
    payment: state.meanOfPayments.mopConfiguration.paymentId,
    collab: state.collab.profile,
  }
}

export default connect(mapStateToProps)(PaymentAttributesView)
