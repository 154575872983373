import React, {Component} from 'react'
import * as PropTypes from 'prop-types'
import Table from "react-bulma-components/lib/components/table"
import Columns from "react-bulma-components/lib/components/columns"
import Column from "react-bulma-components/lib/components/columns/components/column"
import LeftColumn from "./common/LeftColumn"
import HistoryRow, {HistoryRowEntry} from "./common/HistoryRow"
import Box from "react-bulma-components/lib/components/box"
import {formatWithMillis} from "../../../utils/DateTools";

class ResultRowTransactionHistory extends Component {

  render() {
    const {transactionId, content} = this.props

    return <tr key={"transactionHistory" + transactionId}>
      <td colSpan={99}>
        <Columns multiline={false}>
          <LeftColumn context={content.contextType} channel={content.channelType} pspName={content.pspName}
                      transactionId={transactionId}/>
          <Column>
            <Box>
              <Table className={"is-hoverable"}>
                <HistoryRow isOrder={false}>
                  {content.transactionActionResults.map((item) => (
                    <HistoryRowEntry key={`${item.transactionStatus}-${item.date}`}
                                     amount={item.amount}
                                     currency={item.currency}
                                     comment={item.comment}
                                     error={item.error}
                                     transactType={item.transactType}
                                     date={formatWithMillis(item.date)}
                                     nbRelaunch={item.nbTryRelaunch}
                                     transactionStatus={item.transactionStatus}
                                     statusCode={item.statusCode}
                                     statusDescription={item.statusDescription}
                                     notified={item.notify}/>
                  ))}
                </HistoryRow>
              </Table>
            </Box>
          </Column>
        </Columns>
      </td>
    </tr>
  }
}

ResultRowTransactionHistory.propTypes = {
  transactionId: PropTypes.number.isRequired,
  content: PropTypes.object.isRequired
}

export default ResultRowTransactionHistory
