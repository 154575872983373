import { UserManager } from "oidc-client";
import { encodeURL } from 'utils/UrlHelper';

export default class AuthService {
	UserManager;
	user;

	constructor() {

		
		this.UserManager = new UserManager({
			authority: window.OnePayConfiguration.serversURLConstant.IDP_URL,
			client_id: window.OnePayConfiguration.idpData.CLIENT_ID,
			redirect_uri: encodeURL(this.getRedirectUrl()),
			response_type: "code",
			scope: "openid profile",
			metadata: {
				issuer: window.OnePayConfiguration.serversURLConstant.IDP_URL,
				jwks_uri: window.OnePayConfiguration.serversURLConstant.IDP_URL + "/pf/JWKS",
				authorization_endpoint: window.OnePayConfiguration.serversURLConstant.IDP_URL + "/as/authorization.oauth2",
				token_endpoint: window.OnePayConfiguration.serversURLConstant.IDP_URL + "/as/token.oauth2",
				userinfo_endpoint: window.OnePayConfiguration.serversURLConstant.IDP_URL + "/idp/userinfo.openid",
				end_session_endpoint: window.OnePayConfiguration.serversURLConstant.IDP_URL + "/idp/startSLO.ping"
			}
		});

		this.UserManager.events.addUserLoaded((user) => {
				if (window.location.href.indexOf(localStorage.getItem("redirectUri") ? localStorage.getItem("redirectUri") : "/transactionAnalyser") !== -1) {
						this.navigateToScreen();
				}
		});
		this.UserManager.events.addSilentRenewError((e) => {
				console.log("silent renew error", e.message);
		});

		this.UserManager.events.addAccessTokenExpired(() => {
			sessionStorage.removeItem(`oidc.user:init:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`)
			sessionStorage.removeItem(`oidc.user:token:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`);
			sessionStorage.removeItem(`oidc.user:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`)
			this.signinSilent();
		});

		this.UserManager.getUser();

		if ( !this.user ) {
			this.UserManager
			.signinRedirectCallback()
			.then((user) => {
				console.log('user signinRedirectCallback', user)
				sessionStorage.setItem(`oidc.user:token:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`, user.access_token);
				sessionStorage.setItem(`oidc.user:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`, JSON.parse(user))
				this.user = user;
			})
			.catch((e) => {
				this.signinSilent();
				return e
			});
		}

		this.UserManager.events.addAccessTokenExpired(() => {
			this.signinSilent();
		});
	}

	signinRedirectCallback = () => {
			this.UserManager.signinRedirectCallback().then(() => {
					"";
			});
	};

	getUser = async () => {
		const user = await this.UserManager.getUser();
		if (!user) {
			return await this.UserManager.signinRedirectCallback();
		}
		return user
	};

	parseJwt = (token) => {
		const base64Url = token.split(".")[1];
		const base64 = base64Url.replace("-", "+").replace("_", "/");
		return JSON.parse(window.atob(base64));
	};

	signinRedirect = () => {
		if ( window.location.pathname !== localStorage.getItem("redirectUri") || !sessionStorage.getItem(`oidc.user:init:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`)) {
			localStorage.setItem("redirectUri", window.location.pathname);
			sessionStorage.setItem(`oidc.user:init:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`, true)
			this.UserManager.signinRedirect({});
		}
	};

	navigateToScreen = () => {
		window.location.replace(localStorage.getItem("redirectUri") ? localStorage.getItem("redirectUri") : "/transactionAnalyser");
	};


	isAuthenticated = () => {
		const oidcStorage = sessionStorage.getItem(`oidc.user:token:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`);
		return oidcStorage;
	};

	signinSilent = () => {
		if ( window.location.pathname !== localStorage.getItem("redirectUri") || !sessionStorage.getItem(`oidc.user:init:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`)) {
			this.UserManager.signinSilent()
				.then((user) => {
					sessionStorage.setItem(`oidc.user:token:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`, user.access_token);
					sessionStorage.setItem(`oidc.user:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`, JSON.parse(user))
					this.user = user;
				})
				.catch((err) => {
						console.log(err);
				});
		}
	};

	signinSilentCallback = () => {
		this.UserManager.signinSilentCallback();
	};

	createSigninRequest = () => {
		return this.UserManager.createSigninRequest();
	};

	logout = () => {
		this.UserManager.removeUser();
		this.UserManager.revokeAccessToken();
		this.UserManager.clearStaleState();
		localStorage.clear();
		this.UserManager.signoutRedirect();
	};

	signoutRedirectCallback = () => {
		this.UserManager.signoutRedirectCallback().then(() => {
				localStorage.clear();
				window.location.replace(process.env.REACT_APP_PUBLIC_URL);
		});
		this.UserManager.clearStaleState();
	};

	getRedirectUrl = () => {
		// remove last slash from url.
		if(window.location.origin.slice(-1) === "/")
		  return window.location.origin + window.window.OnePayConfiguration.serversURLConstant.FRONT_BASE_PATH.slice(-1) + "/transactionAnalyser";
		else
		  return window.location.origin + window.window.OnePayConfiguration.serversURLConstant.FRONT_BASE_PATH + "/transactionAnalyser";
	  }
}