import React from 'react';
import { render } from 'react-dom';
import configureStore from 'store/configureStore';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux';
import routes from 'routes/routes';
import { BrowserRouter } from 'react-router-dom';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { styles } from './styles';
import { AuthProvider } from './utils/authProvider';

const muiTheme = getMuiTheme(styles);
const store = configureStore();
const rootElement = document.getElementById('root');

unregister();

render(
  <Provider store={store}>
    <AuthProvider>
      <BrowserRouter basename={window.OnePayConfiguration.serversURLConstant.FRONT_BASE_PATH}>
        <MuiThemeProvider muiTheme={muiTheme}>
          {routes}
        </MuiThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  </Provider>, rootElement);

registerServiceWorker();
