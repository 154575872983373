import React, {Component} from 'react'
import {roleFilter} from 'utils/SecurityTools'
import * as RoutesURLConstant from '../utils/RoutesURLConstant'
import {actions} from 'modules/mod-collab'
import {connect} from "react-redux"
import {init} from "../api/MetaApi"
import Navbar from "react-bulma-components/lib/components/navbar"
import Link from "react-router-dom/Link"
import Tag from "react-bulma-components/lib/components/tag"
import Button from "react-bulma-components/lib/components/button"
import Icon from "react-bulma-components/lib/components/icon"
import OnePayNotification from "./shared/OnePayNotification";
import { getUserInfo }              from 'modules/mod-collab';


const tabs = [
  {
    name: 'Mop configuration',
    route: RoutesURLConstant.rootURL,
    access: ['ROLE_READER', 'ROLE_WRITER', 'ROLE_SUPPORT', 'ROLE_ADMIN']
  },
  {
    name: 'Transaction analyser',
    route: RoutesURLConstant.transactionAnalyserURL,
    access: ['ROLE_READER', 'ROLE_WRITER', 'ROLE_SUPPORT', 'ROLE_ADMIN']
  },
  {
    name: 'Widget OnePay',
    route: RoutesURLConstant.widgetTestUrl,
    access: ['ROLE_ADMIN'],
  },
  {
    name: 'Administration',
    route: RoutesURLConstant.adminAccessURL,
    access: ['ROLE_WRITER', 'ROLE_SUPPORT', 'ROLE_ADMIN']
  },
]

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.props.getUserInfo();
  }

  handleChangeUrl(event) {
    window.location.href = event.target.value;
  }

  burgerClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    const listUrl = [];
    if ( window.OnePayConfiguration && window.OnePayConfiguration.serversURLConstant ) {
      for (const key of Object.keys(window.OnePayConfiguration.serversURLConstant.LIST_FRONT_URL) ) {
        listUrl.push(<option selected={ window.location.href.indexOf(window.OnePayConfiguration.serversURLConstant.LIST_FRONT_URL[key]) !== -1} value={window.OnePayConfiguration.serversURLConstant.LIST_FRONT_URL[key]}>{key}</option>);
      }
    }

    const headerTabs = this.buildTabs()

    if (this.props.loading) {
      this.props.init()
    }

    return (
      <div>
      <Navbar fixed={"top"} active={this.state.open}>
        <Navbar.Brand>
          <img src={require('images/onepay-darkcontext.svg')} width={120}
               style={{display: 'inlineBlock', verticalAlign: 'middle'}}
               alt="OnePay"/>
          <Navbar.Burger active={this.state.open}
                         onClick={this.burgerClick}/>
        </Navbar.Brand>

        <Navbar.Menu id={'navbarBasicExample'}>
          <Navbar.Container>
            {headerTabs}
          </Navbar.Container>

          <Navbar.Container position={"end"}>
            <Navbar.Brand>
              <div class="select" onChange={this.handleChangeUrl}>
                <select>
                  {listUrl}
                </select>
              </div>
            </Navbar.Brand>
          </Navbar.Container>
          <Navbar.Container position={"end"}>
            <Button onClick={this.logout} color={'primary'}>
              <Icon className="is-left">
                <i className={`fas fa-user-circle`}/>
              </Icon>
              <p>
                {this.props.collab.uid}
              </p>
            </Button>
            <Tag rounded color={'primary'}>{this.props.version}</Tag>
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>
      <OnePayNotification/>
      </div>
    )
  }

  logout = () => {
    localStorage.clear()
    this.props.dispatch(actions.logout())

  }
}

Header.prototype.buildTabs = function () {
  let headerTabs = []
  var showMenu

  tabs.forEach((tab, index) => {

    showMenu = roleFilter(tab.access, this.props.collab.roles)

    if (showMenu) {
      headerTabs.push(
        <Navbar.Item key={index}
                     active={tab.route === this.props.path}
                     className="is-uppercase is-unselectable"
        >
          <Link to={tab.route}>
            {tab.name}
          </Link>
        </Navbar.Item>,
      )
    }
  })

  return headerTabs
}


const mapStateToProps = (store) => {
  return {
    collab: store.collab.profile,
    version: store.meta.version,
    loading: store.meta.loading,
  }
}

export default connect(mapStateToProps, { getUserInfo, init })(Header)
