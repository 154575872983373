import * as ServicesURLConstant   from 'utils/ServicesURLConstant';
import { fetchAPI }               from 'utils/UrlHelper';

const GET_CACHES_REQUEST = 'GET_CACHES_REQUEST';
const GET_CACHES_SUCCESS = 'GET_CACHES_SUCCESS';
const GET_CACHES_FAILURE = 'GET_CACHES_FAILURE';

const CLEAR_CACHES_REQUEST = 'CLEAR_CACHES_REQUEST';
const CLEAR_CACHES_SUCCESS = 'CLEAR_CACHES_SUCCESS';
const CLEAR_CACHES_FAILURE = 'CLEAR_CACHES_FAILURE';

const initialState = {
    caches: [],
    loading: true,
};

export const actions = {
    getCaches:getCaches,
    clearCaches:clearCaches
};

function getCaches(){
    return dispatch => {
        dispatch({ type: GET_CACHES_REQUEST});

        fetchAPI(ServicesURLConstant.GET_CACHES)
            .then((response) => response.json())
            .then((responseJson) => {
                dispatch({
                    type: GET_CACHES_SUCCESS,
                    data: responseJson
                });
            })
            .catch(error => {
                dispatch({
                    type: 'DISPLAY_MESSAGE',
                    data: {
                        text: error.message,
                        type: 'error'
                    }
                });
                dispatch({ type: GET_CACHES_FAILURE });
            })
    }
}

function clearCaches(caches){
    return dispatch => {
        dispatch({ type: CLEAR_CACHES_REQUEST});
        fetchAPI(ServicesURLConstant.CLEAR_CACHES, {method:"POST", body:JSON.stringify({caches: caches.map(cache=>cache.name)})})
            .then((responseJson) => {
                var status=responseJson.ok?CLEAR_CACHES_SUCCESS:CLEAR_CACHES_FAILURE
                dispatch({
                    type: status,
                });
            })
            .catch(error => {
                dispatch({
                    type: 'DISPLAY_MESSAGE',
                    data: {
                        text: error.message,
                        type: 'error'
                    }
                });
                dispatch({ type: CLEAR_CACHES_FAILURE });
            })
    }
}

export function cacheManagementReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CACHES_REQUEST:
            return { ...state, caches:null, loading: true };
        case GET_CACHES_SUCCESS:
            return { ...state, caches:action.data, loading: false };
        case GET_CACHES_FAILURE:
            return { ...state, getMessage:"Failed getting caches", failure: 1, loading: false };
        case CLEAR_CACHES_REQUEST:
            return { ...state, clearMessage:null, failure: -1 };
        case CLEAR_CACHES_FAILURE:
            return { ...state, clearMessage:"Failed clearing caches", failure: 2 };
        case CLEAR_CACHES_SUCCESS:
            return { ...state, failure: 0 };
        default:
            return state;
    }
}
