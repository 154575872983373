
const hierarchy = ['ROLE_READER', 'ROLE_WRITER', 'ROLE_SUPPORT', 'ROLE_ADMIN']

export const isAtLeast = (role, collab, appClient) => {

  if (hierarchy.indexOf(role) === -1) {
    return false
  }

  var sub = [...hierarchy].splice([...hierarchy].indexOf(role))

  var filtered = sub.filter(realRole => {
    if(collab.additionalProperties && collab.additionalProperties[realRole] !== undefined) {
      return collab.additionalProperties[realRole] !== undefined && collab.additionalProperties[realRole].indexOf(appClient) !== -1;
    }
    return false
  })

  return filtered.length > 0
}

/**
 * Clear token in cookies & redirect to authentication form on FED ID.
 */
export function redirectToAuthForm(localWindow) {
  console.log('redirectToAuthForm')
}

/**
 *
 */
export function roleFilter(listRole, collabRole) {
  var showMenu
  if (collabRole === undefined) {
    return false
  } else {
    showMenu = listRole.find(role => collabRole.find(x => (x.code === role)))
    return (showMenu !== undefined)
  }
}

export function findRoleByAppClient(collab, appClient) {
  var asRoleWriter = -1

  if (collab.error) {
    return asRoleWriter
  } else {
    return isAtLeast('ROLE_WRITER', collab, appClient) ? 1 : -1
  }
}
