import React, {Component} from 'react'

import {actions} from '../../modules/transaction/mod-transaction-analyzer-form'
import ResultRowTransactionHistory from './details/ResultRowTransactionHistory'
import ResultRowTransactionHistoryOrder from './details/ResultRowTransactionHistoryByOrder'
import ResultRowTransactionLogs from './details/ResultRowTransactionLogs'
import YesNoIcon from "./details/common/YesNoIcon"
import ButtonGroup from "react-bulma-components/lib/components/button/components/button-group"
import Button from "react-bulma-components/lib/components/button"
import {connect} from "react-redux";
import {openTransactionHistory, openTransactionHistoryOrder, openTransactionLogs} from "../../api/TransactionApi";
import {formatWithMillis} from "../../utils/DateTools";

class TransactionAnalyzerResultRow extends Component {

  render() {
    const {transaction, history, loading} = this.props

    const loadingTransactions = history && loading && history.logType === "transaction"
    const loadingOrder = history && loading && history.logType === "order"
    const loadingLogs = history && loading && history.logType === "logs"

    const resultTransaction = history && !loading && history.logType === "transaction"
    const resultOrder = history && !loading && history.logType === "order"
    const resultLogs = history && !loading && history.logType === "logs"

    const resultRow = resultTransaction ?
      <ResultRowTransactionHistory transactionId={transaction.transactionId} content={history.content}
                                   rowNumber={this.props.rowNumber}/>
      : resultOrder ?
        <ResultRowTransactionHistoryOrder transactionId={transaction.transactionId} content={history.content}
                                          rowNumber={this.props.rowNumber} orderId={transaction.orderId}/>
        : resultLogs ?
          <ResultRowTransactionLogs transactionId={transaction.transactionId} logs={history.content}
                                    rowNumber={this.props.rowNumber}/>
          : null

    return <>
      <tr>
        <td>
          <div className="field">
            <input className="is-checkradio is-info" id={`select-${transaction.transactionId}`} type="checkbox"
                   name={`select-${transaction.transactionId}`}
                   checked={this.props.checked} onChange={this.handleSelection}/>
            <label htmlFor={`select-${transaction.transactionId}`}/>
          </div>
        </td>
        <td>{transaction.orderId}</td>
        <td>{transaction.transactionId}</td>
        <td>{transaction.status}</td>
        <td>{formatWithMillis(transaction.transactionDetailDate)}</td>
        <td>{transaction.paymentName}</td>
        <td>{transaction.amount !== null ? (transaction.refund ? "- " : "") + transaction.amount : 0} {transaction.currency}</td>
        <td><YesNoIcon value={transaction.notified}/></td>
        <td>
          <ButtonGroup hasAddons>
            <Button fullwidth
                    loading={loadingTransactions}
                    disabled={loadingTransactions}
                    color={resultTransaction ? "primary" : "default"}
                    onClick={this.loadHistory}>
              Transaction
            </Button>
            <Button fullwidth
                    loading={loadingOrder}
                    disabled={loadingOrder}
                    color={resultOrder ? "primary" : "default"}
                    onClick={this.loadHistoryOrder}>
              Order
            </Button>
            <Button fullwidth
                    loading={loadingLogs}
                    disabled={loadingLogs}
                    color={resultLogs ? "primary" : "default"}
                    onClick={this.loadTransactionLogs}>
              Details
            </Button>
          </ButtonGroup>
        </td>
      </tr>
      {resultRow}
    </>
  }

  loadHistoryOrder = () => {
    const transactionId = this.props.transaction.transactionId
    const orderId = this.props.transaction.orderId
    this.props.dispatch(openTransactionHistoryOrder(transactionId, orderId))
  }

  loadTransactionLogs = () => {
    const transactionId = this.props.transaction.transactionId
    this.props.dispatch(openTransactionLogs(transactionId))
  }

  loadHistory = () => {
    const transactionId = this.props.transaction.transactionId
    this.props.dispatch(openTransactionHistory(transactionId))
  }

  handleSelection = () => {
    this.props.dispatch(actions.selectRow(this.props.transaction.transactionId))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    history: state.transactionDetails.history.get(ownProps.transaction.transactionId),
    loading: state.transactionDetails.loading,
    checked: state.analyzer.form.selectedRowsTransaction ? state.analyzer.form.selectedRowsTransaction[ownProps.transaction.transactionId] : false,
  }
}


export default connect(mapStateToProps)(TransactionAnalyzerResultRow)
