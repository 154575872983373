import {
  CHANGE_LOG_LEVEL_ERROR, CHANGE_LOG_LEVEL_REQUEST,
  CHANGE_LOG_LEVEL_SUCCESS,
  EXPAND_LOG_SUCCESS,
  FETCH_ALL_LOGS_ERROR,
  FETCH_ALL_LOGS_REQUEST,
  FETCH_ALL_LOGS_SUCCESS
} from "./Types";
import * as _ from "lodash";
import {expand} from "../utils/Logs";

export const fetchAllRequest = (loading = false) => {
  return {
    type: FETCH_ALL_LOGS_REQUEST,
    data: {
      loading: loading
    },
  }
}

export const fetchAllSuccess = (loggers) => {
  let treeHierarchy = {}

  Object.keys(loggers.loggers).forEach(loggerName => {

    const items = loggerName.split(" ")
    const realName = items[items.length - 1]

    treeHierarchy = _.merge(treeHierarchy, expand(realName, loggers.loggers[loggerName]))
  })

  return {
    type: FETCH_ALL_LOGS_SUCCESS,
    data: {
      loggers: treeHierarchy,
      levels: loggers.levels,
    },
  }
}

export const fetchAllError = () => {
  return {
    type: FETCH_ALL_LOGS_ERROR,
  }
}

export const expandLogSuccess = (key) => {
  return {
    type: EXPAND_LOG_SUCCESS,
    data: `${key}.isActive`
  }
}

export const changeLogLevelRequest = () => {
  return {
    type: CHANGE_LOG_LEVEL_REQUEST,
  }
}

export const changeLogLevelSuccess = (key, level, nested) => {
  return {
    type: CHANGE_LOG_LEVEL_SUCCESS,
    data: {
      key: key,
      level: level,
      nested: nested,
    }
  }
}

export const changeLogLevelError = () => {
  return {
    type: CHANGE_LOG_LEVEL_ERROR,
  }
}
