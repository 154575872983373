export const tagsPerLogType = {
  "FROM_CLIENT": "info",
  "TO_CLIENT": "info",
  "FROM_PSP": "success",
  "TO_PSP": "success",
  "UNDEFINED": "danger",
}

export const labelsPerLogType = {
  "FROM_CLIENT": "Merchant to OnePay",
  "TO_CLIENT": "OnePay to Merchant",
  "FROM_PSP": "PSP to OnePay",
  "TO_PSP": "OnePay to PSP",
  "UNDEFINED": "Unknown",
}

export const json = (string) => {
  if (typeof string !== 'string') return [false, string];
  try {
    const result = JSON.parse(string);
    const type = Object.prototype.toString.call(result);
    return [type === '[object Object]' || type === '[object Array]', JSON.stringify(result, undefined, 2)];
  } catch (err) {
    return [false, string];
  }
}

export const iconPerLogLevel = {
  "OFF": "volume-mute",
  "ERROR": "times",
  "WARN": "exclamation",
  "INFO": "info",
  "DEBUG": "bug",
  "TRACE": "terminal",
}

export const colorPerLogLevel = {
  "OFF": "light",
  "ERROR": "danger",
  "WARN": "warning",
  "INFO": "primary",
  "DEBUG": "success",
  "TRACE": "dark",
}

export const expand = (str, val = {}) => {
  return str.split('.').reduceRight((acc, currentValue) => {
    return {[currentValue]: acc}
  }, val)
}
