import React, { Component } from 'react'
import { connect }          from 'react-redux'

import BatchExecutionRow                          from 'components/admin/batch/BatchExecutionRow'
import RelaunchBatchWithParamPopin                from 'components/admin/batch/RelaunchBatchWithParamPopin'
import { getBatchLastExecutions, relaunchBatch, relaunchBatchWithParameters }  from 'modules/admin/mod-batch'

import muiThemeable       from 'material-ui/styles/muiThemeable'
import FlatButton         from 'material-ui/FlatButton'
import Popover            from 'material-ui/Popover'
import { Card, CardActions, CardText, CardTitle }                       from 'material-ui/Card'
import Table from "react-bulma-components/lib/components/table"

class BatchExecutions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      relaunchJobSimplePopinOpen: false,
      relaunchJobWithParamsPopinOpen : false,
      relaunchJobWithParamsJob: null
    };
  }

  componentDidMount() {
    this.props.getBatchLastExecutions();
  }

  render() {

    return (
      <Card>
        {this.props.loading ? <div className="pageloader is-active"><span className="title">Loading batches...</span></div> : null}
        <CardTitle title="Batchs - Last executions"/>
        <CardText className={"admin-batch-last-executions table-container"}>
          <Table striped className="is-hoverable is-fullwidth is-small">
            <thead>
              <tr>
                <th>Active</th>
                <th>Job name</th>
                <th>Job Status</th>
                <th>Exit Status</th>
                <th>Execution Date</th>
                <th>Duration</th>
                <th>Next execution</th>
                <th className="has-text-centered">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.getJobExecutionRows()}
            </tbody>
          </Table>
          <Popover
            open={this.state.relaunchJobSimplePopinOpen}
            anchorEl={this.state.anchorElement}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={this.handleRelaunchPopoverClose}
          >
            <span className={"batch-quick-relaunch"}>
              Are you sure ? <FlatButton label="Yes" onClick={this.handleRelaunchJobClick}/>
            </span>
          </Popover>
          <RelaunchBatchWithParamPopin opened={this.state.relaunchJobWithParamsPopinOpen}
                                       onRequestClose={this.handleRelaunchPopoverClose}
                                       job={this.state.relaunchJobWithParamsJob}
                                       onSubmitRelaunch={this.relaunchJobWithParameter}/>
        </CardText>
        <CardActions>
          <FlatButton label="Refresh" onClick={() => this.props.getBatchLastExecutions()}/>
        </CardActions>
      </Card>
    );
  }

  getJobExecutionRows() {
    const { jobExecutions } = this.props;
    return jobExecutions.map(exec =>
      <BatchExecutionRow key={"job" + exec.job.jobInstanceName}
                         jobExecution={exec}
                         relaunchClick={this.handleRelaunchPopover} />);
  }

  handleRelaunchPopover = (job, anchorElement ) => {
    if (job.executionParameters === null){
      this.setState({
        relaunchJobSimplePopinOpen: true,
        relaunchJobName: job.jobInstanceName,
        anchorElement
      });
    } else {
      this.setState({
        relaunchJobWithParamsPopinOpen: true,
        relaunchJobWithParamsJob : job
      });
    }
  };

  handleRelaunchPopoverClose = () => {
    this.setState({
      relaunchJobSimplePopinOpen: false,
      relaunchJobWithParamsPopinOpen: false
    });
  };

  handleRelaunchJobClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();
    this.props.relaunchBatch(this.state.relaunchJobName);
    this.handleRelaunchPopoverClose();
  };

  relaunchJobWithParameter = () => {
    this.props.relaunchBatchWithParameters(this.state.relaunchJobWithParamsJob);
    this.handleRelaunchPopoverClose();
  };

}

const mapStateToProps = state => {
  return {
    loading: state.admin.batch.isJobExecutionsLoading,
    jobExecutions: state.admin.batch.jobExecutions
  };
}

export default connect(mapStateToProps, { getBatchLastExecutions, relaunchBatch, relaunchBatchWithParameters })(muiThemeable()(BatchExecutions));
