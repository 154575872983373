const iconPerAction = {
  CHECK: "search",
  PREPARE: "window-maximize",
  AUTHENTICATION: "file-signature",
  AUTHORIZATION: "money-check-alt",
  VOID: "user-times",
  CAPTURE: "file-invoice-dollar",
  REFUND: "undo-alt",
  FORCE_REFUND: "exclamation",
  BATCH: "file-export",
  CREDIT: "hand-holding-usd",
  GET_STATUS: "question-circle",
}

const colorPerStatus = {
  SUCCESS: "success",
  ERROR: "danger",
  WAITING: "info",
  PENDING: "grey",
  ACTION: "success",
  RETRY: "warning",
  CUSTOMER: "success",
}

export const getIconFor = (pspStatus) => {

  const split = pspStatus.split("_")
  const action = split[0]
  const status = split[split.length - 1]

  return {
    icon: iconPerAction[action],
    color: colorPerStatus[status]
  }
}
