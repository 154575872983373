import React, {PureComponent} from "react"
import * as PropTypes from "prop-types"
import Icon from "react-bulma-components/lib/components/icon";

export default class SelectFilter extends PureComponent {
  render() {

    const enabled = this.props.options && this.props.options.length > 1
    const color = this.props.initialValue === this.props.value ? "info" : "warning"

    return <div className="control field is-compact">
      <label className="label has-text-centered is-small" htmlFor={this.props.id}>
        <span>{this.props.label}</span>
        {!!this.props.tooltip ?
          <Icon color="info"
                align="right"
                className="tooltip is-tooltip-info is-tooltip-multiline has-text-centered"
                data-tooltip={this.props.tooltip}
                style={{marginLeft: "0.5rem"}}>
            <i className="fas fa-lg fa-question-circle"/>
          </Icon> :
          null
        }
      </label>
      <div className={`control field is-compact ${this.props.onClear ? " has-addons" : ""}`}>
        <div className={`select is-fullwidth is-small is-${color}`}>
          <select onChange={this.props.onChange}
                  disabled={!enabled}
                  value={this.props.value}
          >
            {this.props.options}
          </select>
        </div>
        {
          this.props.onClear &&
          <div className="control">
            <a className="button is-small" href="#clear" onClick={this.props.onClear} style={{border: 0}}>
              <Icon>
                <i className="fas fa-lg fa-times" style={{color: "#4a4a4a"}}/>
              </Icon>
            </a>
          </div>
        }
      </div>
    </div>
  }
}

SelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.node).isRequired,
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number,
  tooltip: PropTypes.string,
  onClear: PropTypes.func,
}
