import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions as actionsPluginManagement } from 'modules/admin/mod-pluginManagement';
import { List, ListItem } from 'material-ui/List';
import { Card, CardText, CardTitle } from 'material-ui/Card';

class PluginManagement extends Component {

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(actionsPluginManagement.getPlugins());
  }

  render() {
    return (
      <div>
        {this.props.loading ? <div className="pageloader is-active"><span className="title">Loading plugins...</span></div> : null}
        <Card>
          <CardTitle title="Plugin Management"/>
          <CardText>
            <h3>
              List of available PSP plugins :
            </h3>
            <List>
              {this.props.plugins ? this.generatePluginList(this.props.plugins) : null}
            </List>
          </CardText>
        </Card>
      </div>
    );
  }

  generatePluginList = function (plugins) {
    if (plugins && plugins.length !== 0) {

      return plugins.map(plugin =>  <ListItem
        key={plugin.pspName}
        primaryText={plugin.pspName + " - " + plugin.pspClassName + " (" + plugin.pspJarName + ")"} />)
    }
    return null;
  }

}

const mapStateToProps = state => {
  return {
    plugins: state.admin.plugins.plugins,
    loading: state.admin.plugins.loading,
  }
}

export default connect(mapStateToProps)(PluginManagement);
