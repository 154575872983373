import React, {PureComponent} from "react"
import * as PropTypes from "prop-types"
import Icon from "react-bulma-components/lib/components/icon";

export default class SelectFilter extends PureComponent {
  render() {

    const enabled = this.props.options && this.props.options.length > 1
    const color = enabled ? "success" : null
    const size = this.props.size ? this.props.size : 1;
    const height = (this.props.style && this.props.style.height) || `calc((${size} * 2 + 1) * 0.75rem)`

    const icon = this.props.icon !== undefined ?
      <Icon align={"left"} color={color}>
        <i className={`fas fa-${this.props.icon}`}/>
      </Icon> :
      null

    return <div className="field">
      <label className={`label is-small`}>{icon}{this.props.label}</label>
      <div className="control">
        <div className={`select is-small is-fullwidth`}>
          <select onChange={this.props.onChange}
                  disabled={!enabled}
                  size={size}
                  style={{height: height, fontWeight: 700}}
                  value={this.props.value}
          >
            {this.props.options}
          </select>
        </div>
      </div>
    </div>
  }
}

SelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.node).isRequired,
  label: PropTypes.any.isRequired,
  size: PropTypes.number,
  icon: PropTypes.string,
}
