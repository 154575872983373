import {fetchAPI} from "../utils/UrlHelper"
import * as ServicesURLConstant from "../utils/ServicesURLConstant"
import {
  fetchOrderHistoryFailure,
  fetchOrderHistoryRequest,
  fetchOrderHistorySuccess,
  fetchTransactionHistoryFailure,
  fetchTransactionHistoryRequest,
  fetchTransactionHistorySuccess,
  fetchTransactionLogsFailure,
  fetchTransactionLogsRequest,
  fetchTransactionLogsSuccess
} from "../actions/TransactionActions"
import {createNotification} from "./NotificationApi"

export const selectedRows = (rows) => {
  return Object.entries(rows).filter(entry => entry[1]).map(entry => entry[0]).filter(key => key !== "all").map(key => parseInt(key))
}


export const openTransactionHistory = (transactionId) => {
  return dispatch => {

    dispatch(fetchTransactionHistoryRequest(transactionId))

    if (transactionId != null) {

      fetchAPI(ServicesURLConstant.getAllTransationByTransactionId(transactionId))
        .then((response) => response.json())
        .then((responseJson) => dispatch(fetchTransactionHistorySuccess(transactionId, responseJson.data)))
        .catch(error => {
          dispatch(createNotification(error.message, "danger"))
          dispatch(fetchTransactionHistoryFailure())
        })
    }
  }
}

export const openTransactionHistoryOrder = (transactionId, orderId) => {
  return dispatch => {
    dispatch(fetchOrderHistoryRequest(transactionId, orderId))

    if (orderId != null) {

      fetchAPI(ServicesURLConstant.getAllTransationByOrderId(orderId))
        .then((response) => response.json())
        .then((responseJson) => dispatch(fetchOrderHistorySuccess(transactionId, orderId, responseJson.data)))
        .catch(error => {
          dispatch(createNotification(error.message, "danger"))
          dispatch(fetchOrderHistoryFailure())
        })
    }
  }
}

export const openTransactionLogs = (transactionId) => {
  return dispatch => {
    dispatch(fetchTransactionLogsRequest(transactionId))

    if (transactionId != null) {

      fetchAPI(ServicesURLConstant.getAllTransationLogs(transactionId))
        .then((response) => response.json())
        .then((responseJson) => dispatch(fetchTransactionLogsSuccess(transactionId, responseJson.data)))
        .catch(
          error => {
            dispatch(createNotification(error.message, "danger"))
            dispatch(fetchTransactionLogsFailure())
          })
    }
  }
}
