import {
  animateNotificationOutSuccess,
  animateNotificationSuccess,
  closeNotificationSuccess,
  createNotificationSuccess
} from "../actions/NotificationActions"
import {MAX_NOTIFICATIONS} from "../utils/Notifications";

let notificationId = 0
let activeNotification = 0

export const closeNotification = (notificationId) => {
  return dispatch => {
    dispatch(closeNotificationSuccess(notificationId))
    activeNotification--
  }
}

export const createNotification = (text, level) => {
  return dispatch => {
    if (activeNotification >= MAX_NOTIFICATIONS) {
      return
    }

    const currentId = ++notificationId
    dispatch(createNotificationSuccess(currentId, text, level))
    activeNotification++

    setTimeout(() => dispatch(closeNotification(currentId)), 3000)
    setTimeout(() => dispatch(animateNotificationSuccess(currentId)), 10)
    setTimeout(() => dispatch(animateNotificationOutSuccess(currentId)), 2700)
  }
}
