import React, { Component } from 'react';

import Table from "react-bulma-components/lib/components/table"
import TablePagination from 'components/shared/TablePagination'

import BatchExecutionHistoryRow from 'components/admin/batch/BatchExecutionHistoryRow'

class BatchExecutionHistoryTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      elementsPerPage: 15
    }
  }

  render() {
    const {history} = this.props;
    let totalElements;
    if (history){
      totalElements = history.total;
    }
    return (
      <div className={"batch-execution-history-container"}>
        <div className={"batch-execution-history-container-list"}>
          <Table striped className="is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>Execution ID</th>
                <th>Current state</th>
                <th>Final status</th>
                <th>Date</th>
                <th>Duration</th>
                <th>Parameters</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.getJobExecutionHistoryRows()}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={10}>
                  <TablePagination total={totalElements}
                                   nbElementsPerPage={this.state.elementsPerPage}
                                   currentPage={this.state.currentPage}
                                   onPageUpdate={this.onPageUpdate}/>
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>

      </div>
    );
  }

  onPageUpdate = (page, itemsPerPage) => {
    this.setState({
      currentPage: page,
      elementsPerPage: itemsPerPage
    });
    this.props.onPageUpdate(page, itemsPerPage);
  };
}

BatchExecutionHistoryTable.prototype.getJobExecutionHistoryRows = function () {
  const { history } = this.props;
  if (history && history.history) {
    return history.history.map(jobExecution => <BatchExecutionHistoryRow jobExecution={jobExecution}/>);
  }
  return [];
};

export default (BatchExecutionHistoryTable);
