import React, {Component} from 'react'
import labels from 'data/labels.json'
import MopAttribute from 'components/mop/MopAttribute'
import _ from 'lodash'
import Box from "react-bulma-components/lib/components/box"

const MOP_ATTRIBUTE_TABLE_TYPE = "PROPERTIES"

class MopTableAttributes extends Component {

  render() {
    const {mopConfiguration} = this.props
    const displayedAttributes = []

    let attributesForType = this.getAttributesForType(MOP_ATTRIBUTE_TABLE_TYPE)

    if (attributesForType.length > 0) {
      const attributeMap = _.groupBy(attributesForType, 'keyActionContext')

      const headerCells = mopConfiguration.availablePaymentProperties.map(value =>
        (<th style={{width: `${100 / (mopConfiguration.availablePaymentProperties.length + 1)}%`}}>{labels.mop.properties[value].label ? labels.mop.properties[value].label : value}</th>))

      headerCells.unshift(<th style={{width: `${100 / (mopConfiguration.availablePaymentProperties.length + 1)}%`}}/>)

      const rows = mopConfiguration.availablePaymentActions.map(action => {

        const cells = mopConfiguration.availablePaymentProperties.map(value => {

          const attributesForAction = attributeMap[action]

          if (attributesForAction) {

            const attribute = attributesForAction.find(attr => (attr.key === value))

            if (attribute !== undefined) {

              const attributeKey = attribute.keyActionContext ? attribute.keyActionContext + " - " + attribute.key : attribute.key

              return (
                <td style={{maxWidth: `${100 / (mopConfiguration.availablePaymentProperties.length + 1)}%`}}>
                  <MopAttribute key={`attribute${attributeKey}`}
                                attribute={attribute}
                                defaultConfiguration={mopConfiguration.defaultConfiguration}
                                compact={true}/>
                </td>
              )
            }
          }

          return <td style={{maxWidth: `${100 / (mopConfiguration.availablePaymentProperties.length + 1)}%`}}/>
        })

        cells.unshift(<td style={{width: `${100 / (mopConfiguration.availablePaymentProperties.length + 1)}%`}}>{action}</td>)

        return <tr>{cells}</tr>
      })

      displayedAttributes.push(<h1 className="title is-3 is-fullwidth has-text-centered" id={`${MOP_ATTRIBUTE_TABLE_TYPE}-title`}>
        {labels.mop.propertyType[MOP_ATTRIBUTE_TABLE_TYPE]}
      </h1>)

      displayedAttributes.push(
        <table className="table is-striped is-hoverable">
          <thead>
            <tr key="attributes_properties">
              {headerCells}
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>)
    }

    return (
      <Box>
        {displayedAttributes}
      </Box>
    )
  }

}

MopTableAttributes.prototype.getAttributesForType = function (type) {
  const {mopAttributes} = this.props

  let attributesForType = mopAttributes[type]
  if (attributesForType) {
    if (this.props.filterSpecificConfiguration === true) {
      attributesForType = attributesForType.filter(attribute => {
        let attributeValue = attribute.newValue
        if (attributeValue === undefined) {
          attributeValue = attribute.value
        }
        return attributeValue !== attribute.parentValue
      })
    }
  }
  return attributesForType
}

export default (MopTableAttributes)
