import * as ServicesURLConstant from 'utils/ServicesURLConstant';
import {fetchAPI} from 'utils/UrlHelper';
import _ from 'lodash';
import {createSelector} from 'reselect';
import FileSaver from 'file-saver';

/***************************************************************/
/* Actions
/***************************************************************/
const INIT_ANALYZER_REFERENCE_DATA_REQUEST = 'INIT_ANALYZER_REFERENCE_DATA_REQUEST';
const INIT_ANALYZER_REFERENCE_DATA_SUCCESS = 'INIT_ANALYZER_REFERENCE_DATA_SUCCESS';
const INIT_ANALYZER_REFERENCE_DATA_FAILURE = 'INIT_ANALYZER_REFERENCE_DATA_FAILURE';
const INIT_ANALYZER_REFERENCE_DATA_ACTION_REQUEST = 'INIT_ANALYZER_REFERENCE_ACTION_DATA_REQUEST';
const INIT_ANALYZER_REFERENCE_DATA_ACTION_SUCCESS = 'INIT_ANALYZER_REFERENCE_ACTION_DATA_SUCCESS';
const INIT_ANALYZER_REFERENCE_DATA_ACTION_FAILURE = 'INIT_ANALYZER_REFERENCE_ACTION_DATA_FAILURE';
const SEARCH_ANALYZER_REFERENCE_REQUEST = 'SEARCH_ANALYZER_REFERENCE_REQUEST';
const SEARCH_ANALYZER_REFERENCE_SUCCESS = 'SEARCH_ANALYZER_REFERENCE_SUCCESS';
const SEARCH_ANALYZER_REFERENCE_FAILURE = 'SEARCH_ANALYZER_REFERENCE_FAILURE';
const EXPORT_SEARCH_ANALYZER_REFERENCE_REQUEST = 'EXPORT_SEARCH_ANALYZER_REFERENCE_REQUEST';
const EXPORT_SEARCH_ANALYZER_REFERENCE_SUCCESS = 'EXPORT_SEARCH_ANALYZER_REFERENCE_SUCCESS';
const EXPORT_SEARCH_ANALYZER_REFERENCE_FAILURE = 'EXPORT_SEARCH_ANALYZER_REFERENCE_FAILURE';
const SELECT_CLIENT_TRANSACTION_ANALYZER = 'SELECT_CLIENT_TRANSACTION_ANALYZER';
const SELECT_CHANNEL_TRANSACTION_ANALYZER = 'SELECT_CHANNEL_TRANSACTION_ANALYZER';
const SELECT_CONTEXT_TRANSACTION_ANALYZER = 'SELECT_CONTEXT_TRANSACTION_ANALYZER';
const SELECT_PSP_TRANSACTION_ANALYZER = 'SELECT_PSP_TRANSACTION_ANALYZER';
const SELECT_PAYMENT_TRANSACTION_ANALYZER = 'SELECT_PAYMENT_TRANSACTION_ANALYZER';
const SELECT_STATUS_TRANSACTION_ANALYZER = 'SELECT_STATUS_TRANSACTION_ANALYZER';
const SELECT_NOTIFIED_TRANSACTION_ANALYZER = 'SELECT_NOTIFIED_TRANSACTION_ANALYZER';
const SELECT_LAST_STATUS_TRANSACTION_ANALYZER = 'SELECT_LAST_STATUS_TRANSACTION_ANALYZER';
const SET_FROM_DATETIME_TRANSACTION_ANALYZER = 'SET_FROM_DATETIME_TRANSACTION_ANALYZER';
const SET_TO_DATETIME_TRANSACTION_ANALYZER = 'SET_TO_DATETIME_TRANSACTION_ANALYZER';
const SET_TRANSACTION_ID_TRANSACTION_ANALYZER = 'SET_TRANSACTION_ID_TRANSACTION_ANALYZER';
const SET_ORDER_ID_TRANSACTION_ANALYZER = 'SET_ORDER_ID_TRANSACTION_ANALYZER';
const RESET_FORM_TRANSACTION_ANALYZER = 'RESET_FORM_TRANSACTION_ANALYZER';
const SET_TABLE_PAGE_TRANSACTION_ANALYZER = 'SET_TABLE_PAGE_TRANSACTION_ANALYZER';
const SET_SEARCH_FORM_VALUES_TRANSACTION_ANALYZER = 'SET_SEARCH_FORM_VALUES_TRANSACTION_ANALYZER';
const SELECT_ROWS_TRANSACTION_ANALYZER = 'SELECT_ROWS_TRANSACTION_ANALYZER';
const SELECT_ROW_TRANSACTION_ANALYZER = 'SELECT_ROW_TRANSACTION_ANALYZER';

function selectClient(value) {
  return dispatch => {
    dispatch({
      type: SELECT_CLIENT_TRANSACTION_ANALYZER,
      data: value
    });
    dispatch(initReferenceData(value));
  }
}

function selectPSPs(value) {
  return selectElement(SELECT_PSP_TRANSACTION_ANALYZER, value);
}

function selectChannels(value) {
  return selectElement(SELECT_CHANNEL_TRANSACTION_ANALYZER, value);
}

function selectContexts(value) {
  return selectElement(SELECT_CONTEXT_TRANSACTION_ANALYZER, value);
}

function selectPayments(value) {
  return selectElement(SELECT_PAYMENT_TRANSACTION_ANALYZER, value);
}

function selectStatus(value) {
  return selectElement(SELECT_STATUS_TRANSACTION_ANALYZER, value);
}

function selectNotified(value) {
  return selectElement(SELECT_NOTIFIED_TRANSACTION_ANALYZER, value);
}

function selectLastStatus(value) {
  return selectElement(SELECT_LAST_STATUS_TRANSACTION_ANALYZER, value);
}

function setFromDateTime(value) {
  return selectElement(SET_FROM_DATETIME_TRANSACTION_ANALYZER, value);
}

function setToDateTime(value) {
  return selectElement(SET_TO_DATETIME_TRANSACTION_ANALYZER, value);
}

function setTransactionId(value) {
  return selectElement(SET_TRANSACTION_ID_TRANSACTION_ANALYZER, value);
}

function setOrderId(value) {
  return selectElement(SET_ORDER_ID_TRANSACTION_ANALYZER, value);
}

function selectRows() {
  return dispatch => dispatch({
    type: SELECT_ROWS_TRANSACTION_ANALYZER,
  });
}

function selectRow(transactionId) {
  return dispatch => dispatch({
    type: SELECT_ROW_TRANSACTION_ANALYZER,
    data: transactionId,
  });
}

/**
 * Generic dispatch function
 *
 * @param selectAction
 * @param value
 * @returns {function(*)}
 */
function selectElement(selectAction, value) {
  return dispatch => {
    dispatch({
      type: selectAction,
      data: value
    });
  }
}

function resetForm() {
  return dispatch => {
    dispatch({type: RESET_FORM_TRANSACTION_ANALYZER});
  };
}

function setCurrentPage(page, itemsPerPage) {
  return selectElement(SET_TABLE_PAGE_TRANSACTION_ANALYZER, {page, itemsPerPage});
}

function setNewSearchFormValues(form) {
  return dispatch => {
    dispatch({
      type: SET_SEARCH_FORM_VALUES_TRANSACTION_ANALYZER,
      data: form
    });
    dispatch(search(form));
  }
}

function initReferenceData(selectedClient) {
  return dispatch => {
    dispatch({
      type: INIT_ANALYZER_REFERENCE_DATA_REQUEST,
      data: selectedClient
    });
    if (selectedClient != null) {
      fetchAPI(ServicesURLConstant.getUrlTransactionInitReferenceData(selectedClient))
        .then((response) => response.json())
        .then((responseJson) => {
          dispatch({
            type: INIT_ANALYZER_REFERENCE_DATA_SUCCESS,
            data: responseJson.data
          });
        })
        .catch(error => {
          dispatch({
            type: 'DISPLAY_MESSAGE',
            data: {
              text: error.message,
              type: 'error'
            }
          });
          dispatch({type: INIT_ANALYZER_REFERENCE_DATA_FAILURE});
      })
    }
  };
}

function initReferenceDataAction(selectedData){
  return dispatch => {
    dispatch({
      type: INIT_ANALYZER_REFERENCE_DATA_ACTION_REQUEST,
      data: selectedData.appClient
    });
    if (selectedData.appClient != null) {
      fetchAPI(ServicesURLConstant.getUrlTransactionInitReferenceDataAction(selectedData.appClient), {method:"POST", body:JSON.stringify(selectedData.pmntId)})
          .then((response) => response.json())
          .then((responseJson) => {
            dispatch({
              type: INIT_ANALYZER_REFERENCE_DATA_ACTION_SUCCESS,
              data: responseJson.data
            });
          })
          .catch(error => {
            dispatch({
              type: 'DISPLAY_MESSAGE',
              data: {
                text: error.message,
                type: 'error'
              }
            });
            dispatch({ type: INIT_ANALYZER_REFERENCE_DATA_ACTION_FAILURE });
          })
    }
  };
}

function search(searchParameters) {
  return dispatch => {
    dispatch({type: SEARCH_ANALYZER_REFERENCE_REQUEST});
    fetchAPI(ServicesURLConstant.TRANSACTIONS_SEARCH_TRANSACTIONS, {
      method: "POST",
      body: JSON.stringify(searchParameters)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch({
          type: SEARCH_ANALYZER_REFERENCE_SUCCESS,
          data: responseJson.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error'
          }
        });
        dispatch({type: SEARCH_ANALYZER_REFERENCE_FAILURE});
      });
  }
}

function launchExport(searchParameters) {
  return dispatch => {
    dispatch({type: EXPORT_SEARCH_ANALYZER_REFERENCE_REQUEST});
    fetchAPI(ServicesURLConstant.EXPORT_TRANSACTIONS_SEARCH_TRANSACTIONS, {
      method: "POST",
      body: JSON.stringify(searchParameters)
    })
      .then((response) => response.blob())
      .then((responseBlob) => {
        dispatch({type: EXPORT_SEARCH_ANALYZER_REFERENCE_SUCCESS});
        FileSaver.saveAs(responseBlob, 'export.csv')
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error'
          }
        });
        dispatch({type: EXPORT_SEARCH_ANALYZER_REFERENCE_FAILURE});
      });
  }
}


export const actions = {
  selectClient: selectClient,
  selectChannels: selectChannels,
  selectContexts: selectContexts,
  selectPSPs: selectPSPs,
  selectPayments: selectPayments,
  selectStatus: selectStatus,
  selectNotified: selectNotified,
  selectLastStatus: selectLastStatus,
  setFromDateTime: setFromDateTime,
  setToDateTime: setToDateTime,
  setTransactionId: setTransactionId,
  setOrderId: setOrderId,
  resetForm: resetForm,
  setNewSearchFormValues: setNewSearchFormValues,
  search: search,
  setCurrentPage: setCurrentPage,
  selectRows: selectRows,
  selectRow: selectRow,
  launchExport: launchExport,
  initReferenceDataAction: initReferenceDataAction
};

/***************************************************************/
/* Reducer functions
/***************************************************************/
const formInitialState = {
  selectedClient: null,
  selectedChannels: [],
  selectedContexts: [],
  selectedPSPs: [],
  selectedPayments: [],
  selectedStatuses: [],
  selectedNotified: null,
  selectedLastStatus: true,
  initReferenceData: null,
  initReferenceDataAction: null,
  fromDateTime: null,
  toDateTime: null,
  transactionId: '',
  orderId: ''
};

// Right now : fill "selectedRowsForTransaction" from "transactions' with "false" and remove "transactionDetail" for "registerForcingTransaction" (use latest transactionDetail)
// Later : gather all in the "transactions" item, included "selected" state
const initialState = {
  ...formInitialState,
  transactionsLoading: false,
  transactionsCount: 0,
  transactions: [],
  currentPage: 0,
  elementsPerPage: 15,
  currentSearchFormValues: {},
  selectedRowsTransaction: {
    all: false,
  },
  exportLaunched: false,
  filterActions: {},
};

export function transactionAnalyzerFormReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_CLIENT_TRANSACTION_ANALYZER:
      let newState = {selectedClient: action.data};
      if (state.selectedClient !== action.data) {
        newState = {...newState, selectedChannels: [], selectedContexts: [], selectedPSPs: [], selectedPayments: [], selectedStatuses: []};
      }
      return {...state, ...newState};
    case SELECT_CHANNEL_TRANSACTION_ANALYZER:
      return {...state, selectedChannels: action.data};
    case SELECT_CONTEXT_TRANSACTION_ANALYZER:
      return {...state, selectedContexts: action.data};
    case SELECT_PSP_TRANSACTION_ANALYZER:
      return {...state, selectedPSPs: action.data, selectedPayments: []};
    case SELECT_PAYMENT_TRANSACTION_ANALYZER:
      return {...state, selectedPayments: action.data};
    case SELECT_STATUS_TRANSACTION_ANALYZER:
      return {...state, selectedStatuses: action.data};
    case SELECT_NOTIFIED_TRANSACTION_ANALYZER:
      return {...state, selectedNotified: action.data};
    case SELECT_LAST_STATUS_TRANSACTION_ANALYZER:
      return {...state, selectedLastStatus: !state.selectedLastStatus};
    case SET_FROM_DATETIME_TRANSACTION_ANALYZER:
      return {...state, fromDateTime: action.data.isValid() ? action.data : null};
    case SET_TO_DATETIME_TRANSACTION_ANALYZER:
      return {...state, toDateTime: action.data.isValid() ? action.data : null};
    case SET_TRANSACTION_ID_TRANSACTION_ANALYZER:
      return {...state, transactionId: action.data};
    case SET_ORDER_ID_TRANSACTION_ANALYZER:
      return {...state, orderId: action.data};
    case INIT_ANALYZER_REFERENCE_DATA_REQUEST:
      return {...state, initReferenceData: null};
    case INIT_ANALYZER_REFERENCE_DATA_SUCCESS:
      return {...state, initReferenceData: action.data};
  case INIT_ANALYZER_REFERENCE_DATA_ACTION_REQUEST:
    return { ...state, initReferenceDataAction: null };
  case INIT_ANALYZER_REFERENCE_DATA_ACTION_SUCCESS:
    return { ...state, initReferenceDataAction: action.data };
    case RESET_FORM_TRANSACTION_ANALYZER:

      const reinitializedState = _.cloneDeep(formInitialState)

      reinitializedState.selectedClient = state.selectedClient
      reinitializedState.initReferenceData = state.initReferenceData

      return {...state, ...reinitializedState};
    case SEARCH_ANALYZER_REFERENCE_REQUEST:
      return {
        ...state, transactionsLoading: true, transactions: [], transactionsCount: 0
      };
    case SEARCH_ANALYZER_REFERENCE_SUCCESS:

      let newRows = Object.assign({}, {...state.selectedRowsTransaction})
      action.data.rows.forEach((transaction) => newRows[transaction.transactionId] = false)

      return {
        ...state, transactionsLoading: false, transactions: action.data.rows,
        transactionsCount: action.data.totalRowCount,
        selectedRowsTransaction: newRows,
      };
    case SEARCH_ANALYZER_REFERENCE_FAILURE:
      return {...state, transactionsLoading: false};
    case SET_TABLE_PAGE_TRANSACTION_ANALYZER:
      return {...state, currentPage: action.data.page, elementsPerPage: action.data.itemsPerPage};
    case SET_SEARCH_FORM_VALUES_TRANSACTION_ANALYZER:

      return {...state, currentSearchFormValues: action.data, currentPage: action.data.currentPage, selectedRowsTransaction: initialState.selectedRowsTransaction};

    case SELECT_ROWS_TRANSACTION_ANALYZER:

      newRows = Object.assign({}, {...state.selectedRowsTransaction})
      newRows.all = !Object.values(newRows).reduce((previousValue, currentValue) => previousValue && currentValue, true)
      Object.keys(newRows).forEach((key) => newRows[key] = newRows.all);

      return {
        ...state,
        selectedRowsTransaction: newRows
      };

    case SELECT_ROW_TRANSACTION_ANALYZER:

      newRows = Object.assign({}, {...state.selectedRowsTransaction});
      newRows[action.data] = !newRows[action.data]
      newRows.all = Object.keys(newRows).filter(key => key !== 'all').map(key => newRows[key]).reduce((previousValue, currentValue) => previousValue && currentValue, true);

      return {
        ...state,
        selectedRowsTransaction: newRows,
      };

    case EXPORT_SEARCH_ANALYZER_REFERENCE_REQUEST:
      return {...state, exportLaunched: true};
    case EXPORT_SEARCH_ANALYZER_REFERENCE_SUCCESS:
    case EXPORT_SEARCH_ANALYZER_REFERENCE_FAILURE:
      return {...state, exportLaunched: false};

    default:
      return state;
  }
}

/***************************************************************/
/* Selector functions
/***************************************************************/

const channelsSelector = createSelector(
  [state => state.appClient.clients,
    state => state.analyzer.form.selectedClient],
  (clients, selectedClient) => {
    if (clients && selectedClient) {
      let channels = clients.find(client => client.id === selectedClient).channels;
      return _.orderBy(channels, ['boDisplayOrder'], ['asc']);
    }
    return [];
  }
);

/**
 * Selector to gets the unique (distinct) PSP from initReferenceData object.
 *
 * @param state the state
 * @returns list of PSP
 */
const pspsSelector = createSelector(
  [state => state.analyzer.form.selectedClient,
    state => state.analyzer.form.initReferenceData],
  (selectedClient, initReferenceData) => {
    if (initReferenceData && initReferenceData.payments) {
      let psp = initReferenceData.payments.map(p => ({pspId: p.psp.pspId, pspName: p.psp.pspName}));
      return _.uniqBy(psp, 'pspId');
    }
    return [];
  }
);

/**
 * Selector to get the psp/payments from the selected psp.
 *
 * @param state the state
 * @returns list of PSP with payments
 */
const paymentSelector = createSelector(
  [state => pspsSelector(state),
    state => state.analyzer.form.selectedPSPs,
    state => state.analyzer.form.initReferenceData],
  (psps, selectedPSPs, initReferenceData) => {

    return psps.filter(p => (selectedPSPs.includes(p.pspId)))
      .map(c => {
        let payments = initReferenceData.payments
          .filter(p => (p.psp.pspId === c.pspId))
          .map(p => ({paymentId: p.paymentMethod.pmId, paymentName: p.paymentMethod.pmName}));
        return {
          ...c,
          payments
        }
      });
  }
);

/**
 * Selector to get the statuses.
 *
 * @param state the state
 * @returns list of statuses, grouped by action (CAPTURE, etc...)
 */
const statusesSelector = createSelector(
  [state => state.analyzer.form.initReferenceData],

  (initReferenceData) => {
    if (initReferenceData && initReferenceData.transactionStatus) {
      let {transactionStatus} = initReferenceData;
      let firstLevelStatesArray = transactionStatus.map(status => (_.split(status.tsDescription, '_', 1)[0]));
      firstLevelStatesArray = _.uniq(firstLevelStatesArray);

      return firstLevelStatesArray.map(statusPrefix => {
        let statuses = transactionStatus
          .filter(ts => (ts.tsDescription.startsWith(statusPrefix)))
          .map(ts => ({statusId: ts.tsId, statusDescription: ts.tsDescription}));
        return {
          statusId: statusPrefix,
          statusDescription: statusPrefix,
          statuses: statuses
        };
      });
    }
    return [];
  });


/**
 * Selector  to get the contexts from the selected channels.
 *
 * @param state the state
 * @returns list of PSP with payments
 */
const contextsSelector = createSelector(
  [state => channelsSelector(state),
    state => state.analyzer.form.selectedChannels],
  (channels, selectedChannels) => {
    return channels.filter(channel => selectedChannels.includes(channel.id))
  }
);

export const selectors = {
  channelsSelector: channelsSelector,
  contextsSelector: contextsSelector,
  pspsSelector: pspsSelector,
  paymentSelector: paymentSelector,
  statusesSelector: statusesSelector,
};
