import React, { Component }       from 'react';
import { connect }                from 'react-redux';
import { actions }                from 'modules/mod-mops-config';
import { findRoleByAppClient }    from 'utils/SecurityTools';
import Mop                        from './Mop';
import FloatingActionButton       from 'material-ui/FloatingActionButton';
import ContentAdd                 from 'material-ui/svg-icons/content/add';
import FlatButton                 from 'material-ui/FlatButton';
import ActionBuildIcon            from 'material-ui/svg-icons/action/build';
import SaveIcon                   from 'material-ui/svg-icons/content/save';
import RefreshIndicator           from 'material-ui/RefreshIndicator';
import { styles }                 from 'styles';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(item => <li><Mop {...item}/></li>);

const SortableList = SortableContainer(({items, collab, selectAppClient}) => {

  var disable = !collab || findRoleByAppClient(collab, selectAppClient) === -1;

  return (
    <ul className={"mop-list-mean-of-payment"} >
      {items.map((item, index) => (
        <SortableItem disabled={disable} key={index} index={index} {...item} />
      ))}
    </ul>
  );
});

class MopsList extends Component {

  render() {
    return (

      <div className={"mop-list"}>
        <div className={"mop-list-title"} >
          <div className="columns">
            <div  className="column is-one-third">
              <h2>Means of payment
                {this.props.collab && this.props.collab.roles.indexOf('ROLE_ADMIN') !== -1 &&
                  <FlatButton label="Configure" secondary={true} icon={<ActionBuildIcon/>} disabled={true}/>
                }
              </h2>
            </div>

            <div className="field column is-one-third">
              <input id="switchInactiveMop" type="checkbox" name="switchInactiveMop" className="switch is-rounded" onChange={this.toggleShowInactif}/>
              <label htmlFor="switchInactiveMop">Hide inactive means of payment</label>
            </div>

            {this.hasModification() &&
              <div className="column is-one-third has-text-right">
                <h2>
                  {this.props.savingInProgress === true &&
                    <RefreshIndicator  left={0} top={0} status="loading" style={styles.refresh}/>
                  }

                  <FlatButton label="Save" backgroundColor={'white'}
                              onClick={this.saveMeanOfPaymentList.bind(this)} icon={ <SaveIcon /> } />
                </h2>
              </div>
            }
          </div>
        </div>
        <div>
          { this.displayOnlyActive() &&
            <SortableList items={this.props.mopsActive || []} collab={false} selectAppClient={this.props.selectedAppClientId} />
          }

          { !this.displayOnlyActive() &&
            <SortableList items={this.props.mops || []} collab={this.props.collab || []} selectAppClient={this.props.selectedAppClientId} onSortEnd={this.onSortEnd.bind(this)} />
          }

          { this.props.collab && this.props.collab.roles.indexOf('ROLE_ADMIN') !== -1 &&
              <FloatingActionButton
                  style={{position: 'fixed', right: 16, bottom: 16}}>
                <ContentAdd/>
              </FloatingActionButton>
          }

        </div>
      </div>
    )
  }

  toggleShowInactif = () => {
    const {dispatch} = this.props;
    dispatch(actions.checkToggleActive());
  };
}

MopsList.prototype.displayOnlyActive = function() {
  const {toggleActiveChecked} = this.props;
  return toggleActiveChecked;
};

/***************************************************************/
/* After list sorting
/***************************************************************/
MopsList.prototype.onSortEnd = function(params) {
  if (params.newIndex !== params.oldIndex){
    this.updateMops({
      mops: arrayMove(this.props.mops, params.oldIndex, params.newIndex),
    });
  }
};

/***************************************************************/
/* Update the modified mops list
/***************************************************************/
MopsList.prototype.updateMops = function(mops) {
  const {dispatch} = this.props;
  dispatch(actions.updateMops(mops));
};

MopsList.prototype.hasModification = function() {
  const {mopsNewOrder, mopsNewActivation} = this.props;
  return mopsNewOrder.length !== 0 || mopsNewActivation.size !== 0;
};

MopsList.prototype.saveMeanOfPaymentList = function(){
  const {dispatch, selectedAppClientId, selectedChannelId, mopsNewOrder, mopsNewActivation} = this.props;
  dispatch(actions.saveMeanOfPaymentList(selectedAppClientId, selectedChannelId, mopsNewOrder, mopsNewActivation));
}

const mapStateToProps = state => {
  return {
    mopsActive: state.meanOfPayments.mopsActive,
    mops: state.meanOfPayments.mops,
    mopsNewOrder: state.meanOfPayments.mopsNewOrder,
    mopsNewActivation: state.meanOfPayments.mopsNewActivation,
    savingInProgress: state.meanOfPayments.savingInProgress,
    selectedAppClientId: state.appClient.selectedAppClientId,
    selectedChannelId: state.appClient.selectedChannelId,
    usersProperties: state.collab.profile.additionalProperties,
    collab: state.collab.profile,
    toggleActiveChecked: state.meanOfPayments.toggleActiveChecked
  }
}

export default connect(mapStateToProps)(MopsList)
