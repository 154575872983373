import * as ServicesURLConstant   from 'utils/ServicesURLConstant';
import { fetchAPI }               from 'utils/UrlHelper';
import {actions as actionsMops} from '../modules/mod-mops-config';

/***************************************************************/
/* Actions
/***************************************************************/
const LIST_APPLICATION_CLIENT = 'LIST_APPLICATION_CLIENT';
const LIST_APPLICATION_CLIENT_SUCCESS = 'LIST_APPLICATION_CLIENT_SUCCESS';
const LIST_ALL_APPLICATION_CLIENT = 'LIST_ALL_APPLICATION_CLIENT';
const LIST_ALL_APPLICATION_CLIENT_SUCCESS = 'LIST_ALL_APPLICATION_CLIENT_SUCCESS';
const SELECT_APPLICATION_CLIENT = 'SELECT_APPLICATION_CLIENT';
const SELECT_CHANNEL_ID = 'SELECT_CHANNEL_ID';
const TOGGLE_ADD_MENU = 'TOGGLE_ADD_MENU';

function listApplicationClient(data) {
  return (dispatch, getState) => {
    dispatch({
      type: LIST_APPLICATION_CLIENT,
      data: data
    });

    fetchAPI(ServicesURLConstant.GET_CLIENT_APP)
    .then((response) => response.json())
    .then((responseJson) => {
      dispatch({
        type: LIST_APPLICATION_CLIENT_SUCCESS,
        data: responseJson
      });
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
    })
  };
}

function listAllApplicationClient(data) {
  return dispatch => {
    dispatch({
      type: LIST_ALL_APPLICATION_CLIENT,
      data: data
    });

    fetchAPI(ServicesURLConstant.GET_CLIENT_APP)
    .then((response) => response.json())
    .then((responseJson) => {
      dispatch({
        type: LIST_ALL_APPLICATION_CLIENT_SUCCESS,
        data: responseJson
      });
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
    })
  };
}

function selectTreeViewElement(appClient, channel, context) {
  return dispatch => {
    dispatch({
      type: SELECT_APPLICATION_CLIENT,
      data: {appClient, channel, context}
    });
    let channelId = context ? context.id : (channel ? channel.id : appClient.channels.find(channel => channel.default).id);
    dispatch({
      type: SELECT_CHANNEL_ID,
      data: channelId
    });
    dispatch(actionsMops.listMops(appClient.id, channelId));
  }
}

function toggleAddMenu(data) {
  return dispatch => {
    dispatch({
      type: TOGGLE_ADD_MENU,
      data: data
    });
  }
}

export const actions = {
  listAllApplicationClient: listAllApplicationClient,
  listApplicationClient: listApplicationClient,
  selectTreeViewElement: selectTreeViewElement,
  toggleAddMenu: toggleAddMenu
};

/***************************************************************/
/* Reducer functions
/***************************************************************/
const initialState = {
  menu: {
    isOpen: false
  },
  clients: [],
  selectedAppClientId: null,
  selectedAppClientExternalId: null,
  selectedChannelId: null
};

export function applicationClientReducer(state = initialState, action) {
  switch (action.type) {
  case LIST_APPLICATION_CLIENT_SUCCESS:
    return {...state, clients: [...action.data]};
  case TOGGLE_ADD_MENU:
    return {...state, menu: {isOpen: !state.menu.isOpen, button: action.data}};
  case LIST_ALL_APPLICATION_CLIENT_SUCCESS:
    return {...state, clients: [...action.data]};
  case SELECT_APPLICATION_CLIENT:
    return reduceSelectApplicationClient(state, action);
  case SELECT_CHANNEL_ID:
    return {...state, selectedChannelId : action.data}
  default:
    return state;
  }
}

function reduceSelectApplicationClient(state, action) {
  let appClientId =  action.data.appClient.id ;
  // select the app client
  let clients = state.clients.map(client => {
    client.selected = client.id === appClientId;

    // select the channel if present.
    let channelId = action.data.channel ? action.data.channel.id : null;
    client.channels.forEach(channel => {
      channel.selected = channel.id === channelId;

      // select context if present.
      let contextId = action.data.context ? action.data.context.id : null;
      channel.contexts.forEach(context => {
        context.selected = context.id === contextId;
      });
    });

    return client;
  });

  return {...state, clients, selectedAppClientId: appClientId, selectedAppClientExternalId: clients.filter((client) => client.id === appClientId)[0].externalId};
}
