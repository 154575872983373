import * as ServicesURLConstant   from 'utils/ServicesURLConstant';
import { fetchAPI }               from 'utils/UrlHelper';
import { redirectToAuthForm }       from 'utils/SecurityTools';

const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export function getUserInfo() {
  return dispatch => {
    dispatch({ type: USER_INFO_REQUEST });

    fetchAPI(ServicesURLConstant.BO_USER_INFO)
    .then((response) => {
      var json = response.json();
      if (response.status === 401){
        redirectToAuthForm(window);
      }
      return json;
    })
    .then((responseJson) => {

      if(responseJson.error === "invalid_token")
        redirectToAuthForm(window);

      dispatch({
        type: USER_INFO_SUCCESS,
        data: responseJson
      });
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
      dispatch({ type: USER_INFO_FAILURE });
    })
  }
}

function logout() {
  return dispatch => {
    dispatch({
      type: USER_LOGOUT_REQUEST
    });

    fetchAPI(ServicesURLConstant.BO_USER_LOGOUT,
        { method: "POST" })
    .then((response) => {
      if (!response.ok) {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: "Error while logout ",
            type: 'error'
          }
        });
      } else {
        dispatch({ type: USER_LOGOUT_SUCCESS });
        redirectToAuthForm(window);
      }
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
      dispatch({ type: USER_LOGOUT_FAILURE });
    })
  };
}

export const actions = {
  logout: logout
}

const initialState = {
  profile: "",
  isAuthenticated: false,
  isAuthenticationLoading: false,
  isUnauthorized: false,
  menuItems: null,
};

export function collabReducer(state = initialState, action) {
  switch (action.type) {

  case USER_INFO_REQUEST: {
    return { ...state, isAuthenticationLoading: true };
  }
  case USER_INFO_SUCCESS: {
    return { ...state, isAuthenticationLoading: false, isAuthenticated: true, profile: action.data };
  }
  case USER_INFO_FAILURE: {
    return { ...state, isAuthenticationLoading: false, isUnauthorized: true, profile: null };
  }

  case USER_LOGOUT_REQUEST: {
    return { ...state, isAuthenticationLoading: true };
  }
  case USER_LOGOUT_SUCCESS: {
    return { ...state, isAuthenticationLoading: false, isAuthenticated: false };
  }
  case USER_LOGOUT_FAILURE: {
    return { ...state, isAuthenticationLoading: false, isUnauthorized: true };
  }
  default:
    return state;
  }
}