import React, {Component} from 'react'
import PropTypes from 'prop-types'

import muiThemeable from 'material-ui/styles/muiThemeable'
import HistoryRow, {HistoryRowEntry} from "./common/HistoryRow"
import Table from "react-bulma-components/lib/components/table"
import Columns from "react-bulma-components/lib/components/columns"
import LeftColumn from "./common/LeftColumn"
import Column from "react-bulma-components/lib/components/columns/components/column"
import Box from "react-bulma-components/lib/components/box"
import {formatWithMillis} from "../../../utils/DateTools";

class ResultRowTransactionHistoryOrder extends Component {

  render() {
    const {content} = this.props
    return <tr>
      <td colSpan={99}>
        <Columns multiline={false}>
          <LeftColumn context={content.contextType} channel={content.channelType}/>
          <Column>
            <Box>
              <Table>
                <HistoryRow isOrder={true}>
                  {content.transactionActionResults.map((item) => (
                    <HistoryRowEntry key={`${item.transactId}-${item.transactionStatus}`}
                                     transactionId={item.transactId}
                                     transactionStatus={item.transactionStatus}
                                     statusCode={item.statusCode}
                                     statusDescription={item.statusDescription}
                                     pspName={item.psp}
                                     paymentName={item.payment}
                                     date={formatWithMillis(item.date)}
                                     amount={item.amount}
                                     transactType={item.transactType}
                                     currency={item.currency}
                                     comment={item.comment}
                                     error={item.error}
                                     notified={item.notify}
                                     nbRelaunch={item.nbTryRelaunch}
                    />))}
                </HistoryRow>
              </Table>
            </Box>
          </Column>
        </Columns>
      </td>
    </tr>
  }
}

ResultRowTransactionHistoryOrder.propTypes = {
  transactionId: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired
}

export default muiThemeable()(ResultRowTransactionHistoryOrder)
