import {
  FETCH_META_DATA,
  FETCH_META_DATA_SUCCESS,
  FETCH_TRANSACTION_HISTORY_FAILURE,
} from "./Types"

export const fetchMeta = () => {
  return {
    type: FETCH_META_DATA,
  }
}

export const fetchMetaSuccess = (build) => {
  return {
    type: FETCH_META_DATA_SUCCESS,
    data: {
      artifact: build.artifact,
      name: build.name,
      version: build.version,
    },
  }
}

export const fetchMetaFailure = () => {
  return {
    type: FETCH_TRANSACTION_HISTORY_FAILURE,
  }
}
