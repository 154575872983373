import React, { Component }       from 'react'
import { connect }                from 'react-redux'
import _                          from 'lodash'
import PropTypes                  from 'prop-types'
import { actions }                from 'modules/mod-mops-config'
import labels                     from 'data/labels.json'

import Toggle       from "../shared/form/Toggle"
import Number       from "../shared/form/Number"
import SelectFilter from "../shared/form/SelectFilter"
import Text         from "../shared/form/Text"

class MopAttribute extends Component {

  render() {
    return this.displayAttributeInput(this.props.compact)
  }

  handleDeleteButton = () => {
    const {dispatch, attribute} = this.props;
    dispatch(actions.updateAttribute(attribute, attribute.parentValue));
  };

  handleChangeState = (attributeValue) => {
    const {dispatch, attribute} = this.props;
    dispatch(actions.updateAttribute(attribute, (attributeValue !== "true").toString()));
  };

  handleChangeValue = (event) => {
    const {dispatch, attribute} = this.props;
    dispatch(actions.updateAttribute(attribute, event.target.value));
  };

  handleSelectChangeValue = (event) => {
    const {dispatch, attribute} = this.props;
    dispatch(actions.updateAttribute(attribute, event.target.value));
  };
}

MopAttribute.prototype.displayAttributeInput = function (compact) {

  const { attribute } = this.props;
  let attributeValue = attribute.newValue;
  if (attributeValue === undefined) {
    attributeValue = attribute.value;
  }

  let labelKey = attribute.keyActionContext ? attribute.keyActionContext + " - " + attribute.key : attribute.key;

  let attributeLabel = labels.mop.properties[labelKey] && labels.mop.properties[labelKey].label ?
                        labels.mop.properties[labelKey].label :
                        labelKey;
  let attributeDescription = labels.mop.properties[labelKey] && labels.mop.properties[labelKey].description ?
                              labels.mop.properties[labelKey].description :
                              null;

  if (attribute.valueType === "Boolean") {
    return this.displayAttributeInputBoolean(attributeLabel, attributeDescription, attributeValue, attribute.value, compact);
  }
  else if (attribute.valueType === "Number") {
    return this.displayAttributeInputNumber(attributeLabel, attributeDescription, attributeValue, attribute.value, compact);
  }
  else if (attribute.valueType === "Double") {
    return this.displayAttributeInputDouble(attributeLabel, attributeDescription, attributeValue, attribute.value, compact);
  }
  else if (attribute.valueType !== "String" && !_.isEmpty(attribute.availableValues)) {
    return this.displayAttributeInputSelect(attributeLabel, attributeDescription, attributeValue, attribute.value, attribute.availableValues);
  } else{
    return this.displayAttributeInputText(attributeLabel, attributeDescription, attributeValue, attribute.value, compact);
  }
};

/**
 * Display Attribute of type Boolean.
 *
 * @param attributeLabel the attribute label
 * @param attributeDescription the description shown in the tooltip
 * @param attributeValue the attribute value
 * @param initialValue the initial value
 * @param compact is compact
 * @returns {XML} element displayed.
 */
MopAttribute.prototype.displayAttributeInputBoolean = function (attributeLabel, attributeDescription, attributeValue, initialValue, compact) {
  return <Toggle id={attributeLabel}
                 key={attributeLabel}
                 name={attributeLabel}
                 label={attributeLabel}
                 tooltip={attributeDescription}
                 onChange={() => this.handleChangeState(attributeValue)}
                 checked={attributeValue === "true"}
                 initialChecked={initialValue === "true"}
                 compact={compact}
                 size="small"
                 onClear={this.isSpecificConfiguration() ? this.handleDeleteButton : undefined}
  />;
};

/**
 * Display Attribute of type Number (Integer).
 *
 * @param attributeLabel the attribute label
 * @param attributeDescription the description shown in the tooltip
 * @param attributeValue the attribute value
 * @param initialValue the initial value
 * @param compact is compact
 * @returns {XML} element displayed.
 */
MopAttribute.prototype.displayAttributeInputNumber = function (attributeLabel, attributeDescription, attributeValue, initialValue, compact) {

  let value = _.isEmpty(attributeValue) ? null : _.parseInt(attributeValue);
  let validValue = _.isEmpty(initialValue) ? null : _.parseInt(initialValue);

  return <Number id={attributeLabel}
                 key={attributeLabel}
                 name={attributeLabel}
                 label={attributeLabel}
                 tooltip={attributeDescription}
                 value={value}
                 initialValue={validValue}
                 error={_.isNumber(value) === false && 'Not a number'}
                 onChange={this.handleChangeValue}
                 compact={compact}
                 onClear={this.isSpecificConfiguration() ? this.handleDeleteButton : undefined}
  />;

};

/**
 * Display Attribute of type Double.
 *
 * @param attributeLabel the attribute label
 * @param attributeDescription the description shown in the tooltip
 * @param attributeValue the attribute value
 * @param initialValue the initial value
 * @param compact is compact
 * @returns {XML} element displayed.
 */
MopAttribute.prototype.displayAttributeInputDouble = function (attributeLabel, attributeDescription, attributeValue, initialValue, compact) {

  let value = _.isEmpty(attributeValue) ? null : parseFloat(attributeValue);
  let validValue = _.isEmpty(initialValue) ? null : _.parseInt(initialValue);

  return <Number id={attributeLabel}
                 key={attributeLabel}
                 name={attributeLabel}
                 label={attributeLabel}
                 tooltip={attributeDescription}
                 value={value}
                 initialValue={validValue}
                 error={_.isNumber(value) === false && 'Not a number'}
                 onChange={this.handleChangeValue}
                 compact={compact}
                 onClear={this.isSpecificConfiguration() ? this.handleDeleteButton : undefined}
  />;
};

/**
 * Display Attribute of type String (or any other default type without available values).
 *
 * @param attributeLabel the attribute label
 * @param attributeDescription the description shown in the tooltip
 * @param attributeValue the attribute value
 * @param initialValue the initial value
 * @param compact is compact
 * @returns {XML} element displayed.
 */
MopAttribute.prototype.displayAttributeInputText = function (attributeLabel, attributeDescription, attributeValue, initialValue, compact) {

  return <Text id={attributeLabel}
               key={attributeLabel}
               name={attributeLabel}
               label={attributeLabel}
               tooltip={attributeDescription}
               value={attributeValue}
               initialValue={initialValue}
               error={_.isEmpty(attributeValue) === false && 'Not a valid value'}
               onChange={() => this.handleChangeValue(attributeValue)}
               compact={compact}
               onClear={this.isSpecificConfiguration() ? this.handleDeleteButton : undefined}
  />

};

/**
 * Display Attribute of type 'select' (other than above types, and a list of available values).
 *
 * @param attributeLabel the attribute label
 * @param attributeDescription the description shown in the tooltip
 * @param attributeValue the attribute value
 * @param initialValue the initial value
 * @param availableValues all values to choose from
 * @returns {XML} element displayed.
 */
MopAttribute.prototype.displayAttributeInputSelect = function (attributeLabel, attributeDescription, attributeValue, initialValue, availableValues) {

  const options = availableValues &&
                  availableValues.map(value => (
                    <option id={`attributeValue-${attributeLabel}-${value}`}
                            key={`attributeValue-${attributeLabel}-${value}`}
                            value={value}>
                      {value}
                    </option>
                            )
                  )

  return <SelectFilter id={attributeLabel}
                       name={attributeLabel}
                       label={attributeLabel}
                       tooltip={attributeDescription}
                       value={attributeValue}
                       initialValue={initialValue}
                       options={options}
                       onChange={this.handleSelectChangeValue}
                       onClear={this.isSpecificConfiguration() ? this.handleDeleteButton : undefined}
  />

};

/**
 * Indicate if the attribute has a specific value.
 *
 * @returns {boolean} true if specific value (changed compared to parent value)
 */
MopAttribute.prototype.isSpecificConfiguration =function() {
  const {defaultConfiguration, attribute} = this.props;
  let attributeValue = attribute.newValue;
  if (attributeValue === undefined){
    attributeValue = attribute.value;
  }
  return defaultConfiguration === false && attributeValue !== attribute.parentValue;
};

MopAttribute.propTypes = {
  attribute: PropTypes.object.isRequired,
  defaultConfiguration: PropTypes.bool.isRequired,
  compact: PropTypes.bool
};

MopAttribute.defaultProps = {
  compact: false
};

export default connect()(MopAttribute)
