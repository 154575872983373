export const styles = {
  mainContainer: {
    minHeight: '92vh',
    backgroundColor: '#f9f9f9'
  },
  palette: {
    primary1Color: '#0082C3',
    accent1Color: '#f16c1f'
  },
  appBar: {
    paddingTop: 0,
    paddingBottom: 0,
    borderBottom: '1px solid rgba(255,255,255,0.3)',
    boxShadow: 'none'
  },
  pageContainer: {
    padding: 13
  },
  mop: {
    padding: 16,
    content: {
      display: 'flex',
      alignItems: 'center'
    },
    toggle: {
      flex: '0 0 auto',
      width: 'auto',
      marginRight: 16
    },
    name: {
      margin: 0,
      flex: '1 1 auto'
    }
  },
  batch: {
    table: {
      td: {
        paddingLeft: 12,
        paddingRight: 12,
        whiteSpace: 'pre-wrap'
      },
      disabledTd: {
        paddingLeft: 12,
        paddingRight: 12,
        color: 'lightgrey',
        fontStyle: 'italic',
        whiteSpace: 'pre-wrap'
      }
    }
  },
  accessControl: {
    property: {
      admin: {
        radioGroup: {
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 10
        },
        radioIcon: {
          marginLeft: 20,
          float: 'none'
        }
      },
      filter: {
        radioGroup: {
          margin: '0 auto',
          display: 'flex',
          textAlign: 'center',
          width: 510
        }
      }
    }
  },
  refresh: {
    position: 'relative',
    margin: '0 auto',
    marginBottom: 10
  },
  transactions: {
    table: {
      padding: '0 5px',
    }
  },
  modifiedAttribute: {
    borderColor: "#f16c1f",
    borderWidth: 2,
    color: "#f16c1f"
  },
  toggleTrackModifiedAttribute: {
    backgroundColor: '#EDA978'
  },
  toggleThumbOnModifiedAttribute: {
    backgroundColor: '#EF5700'
  },
  toggleThumbOffModifiedAttribute: {
    backgroundColor: '#EAC9BE'
  },
  attributeTable: {
    td: {
      paddingLeft: 12,
      paddingRight: 12,
      overflowWrap: 'break-word',
      whiteSpace: 'normal'
    },
    disabledTd: {
      paddingLeft: 12,
      paddingRight: 12,
      color: 'lightgrey',
      fontStyle: 'italic',
      overflowWrap: 'break-word',
      whiteSpace: 'normal'
    }
  },
  logManangementRoot: {
    marginBottom: 24,
    marginRight: 24,
    width: '100%',
  },
  logManangementContainer: {
    border: 'solid 1px #d9d9d9',
    borderBottom: 'none',
    height: 'auto',
    overflow: 'hidden',
  },
  logManangementBottomTear: {
    display: 'block',
    position: 'relative',
    marginTop: -10,
    maxWidth: 360,
  },
  selectAll: {
      borderBottom: '1px solid rgba(0,0,0,0.4)'
  },
  clearCacheButton: {
    margin:'0 0 20px 20px'
  }
}
