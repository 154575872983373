import {
  ANIMATE_NOTIFICATION_OUT_SUCCESS,
  ANIMATE_NOTIFICATION_SUCCESS,
  CLOSE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_SUCCESS, DEFAULT
} from "../actions/Types"

const initialState = {
  notifications: {},
  notificationCount: 0
}

export const notificationReducer = (state = initialState, action = DEFAULT) => {

  switch (action.type) {

    case CREATE_NOTIFICATION_SUCCESS:
      let newNotifications = {...state.notifications}

      newNotifications[action.id] = {
        id: action.id,
        level: action.level,
        text: action.text
      }

      return Object.assign({}, state, {notifications: newNotifications, notificationCount: action.id})

    case ANIMATE_NOTIFICATION_SUCCESS:
      newNotifications = {...state.notifications}

      newNotifications[action.id] = Object.assign({}, newNotifications[action.id], {
        show: true
      })

      return Object.assign({}, state, {notifications: newNotifications, notificationCount: action.id})

    case ANIMATE_NOTIFICATION_OUT_SUCCESS:
      newNotifications = {...state.notifications}

      newNotifications[action.id] = Object.assign({}, newNotifications[action.id], {
        show: false
      })

      return Object.assign({}, state, {notifications: newNotifications, notificationCount: action.id})


    case CLOSE_NOTIFICATION_SUCCESS:
      const closedNotifications = {...state.notifications}

      delete closedNotifications[action.id]

      return Object.assign({}, state, {notifications: closedNotifications})

    default :
      return state

  }
}
