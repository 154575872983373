import React, {PureComponent} from 'react'
import * as PropTypes from 'prop-types'
import Icon from "react-bulma-components/lib/components/icon"

export default class Toggle extends PureComponent {

  render() {

    const color = this.props.initialChecked === undefined || (this.props.initialChecked === this.props.checked) ?
      "info" :
      "warning"

    return (
      <div className="control field is-compact">
        <input id={this.props.id}
               type="checkbox"
               name={this.props.name}
               className={`switch is-rounded is-${color} is-small`}
               checked={this.props.checked}
               onChange={this.props.onChange}
        />
        {
          !this.props.compact ?
            <label htmlFor={this.props.id}>
              <span>{this.props.label}</span>
              {
                !!this.props.tooltip ?
                  <Icon color="info"
                        align="right"
                        className="tooltip is-tooltip-info is-tooltip-multiline has-text-centered"
                        data-tooltip={this.props.tooltip}
                        style={{marginLeft: "0.5rem"}}>
                    <i className="fas fa-lg fa-question-circle"/>
                  </Icon> :
                  null
              }
            </label> :
            null
        }
        {
          this.props.onClear &&
          <span className="control">
            <a className="button is-small" href="#clear" onClick={this.props.onClear} style={{border: 0}}>
              <Icon>
                <i className="fas fa-lg fa-times" style={{color: "#4a4a4a"}}/>
              </Icon>
            </a>
          </span>
        }
      </div>
    )
  }
}

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  initialChecked: PropTypes.bool,
  tooltip: PropTypes.string,
  compact: PropTypes.bool.isRequired,
  onClear: PropTypes.func,
}
