import * as ServicesURLConstant   from 'utils/ServicesURLConstant';
import { fetchAPI }               from 'utils/UrlHelper';

const LOAD_PARAMETERS_REQUEST = 'LOAD_PARAMETERS_REQUEST';
const LOAD_PARAMETERS_SUCCESS = 'LOAD_PARAMETERS_SUCCESS';
const LOAD_PARAMETERS_FAILURE = 'LOAD_PARAMETERS_FAILURE';

export function loadParameters() {
  return dispatch => {
    dispatch({ type: LOAD_PARAMETERS_REQUEST });

    fetchAPI(ServicesURLConstant.APP_PARAMETERS_URL)
    .then((response) => response.json())
    .then((responseJson) => {
      dispatch({
        type: LOAD_PARAMETERS_SUCCESS,
        data: responseJson
      });
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
      dispatch({ type: LOAD_PARAMETERS_FAILURE });
    });

  }
}

const initialState = {};

export function parametersReducer(state = initialState, action) {
  switch (action.type) {
  case LOAD_PARAMETERS_SUCCESS:
    return action.data;
  case LOAD_PARAMETERS_REQUEST:
  case LOAD_PARAMETERS_FAILURE:
  default:
    return state;
  }
}