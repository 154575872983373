import * as ServicesURLConstant from 'utils/ServicesURLConstant';
import {fetchAPI} from 'utils/UrlHelper';
import _ from 'lodash';
import {createSelector} from 'reselect';
import {createNotification} from "../../api/NotificationApi"

/***************************************************************/
/* Actions
/***************************************************************/
const SET_USER_ADMIN_ACCESS = 'SET_USER_ADMIN_ACCESS';
const SELECT_ACTION_TA_ACTION_BLOCK = 'SELECT_ACTION_TA_ACTION_BLOCK';
const SELECT_STATUS_TA_ACTION_BLOCK = 'SELECT_STATUS_TA_ACTION_BLOCK';
const FORCE_TRANSACTION_STATUS_REQUEST = 'FORCE_TRANSACTION_STATUS_REQUEST';
const FORCE_TRANSACTION_STATUS_SUCCESS = 'FORCE_TRANSACTION_STATUS_SUCCESS';
const FORCE_TRANSACTION_STATUS_FAILURE = 'FORCE_TRANSACTION_STATUS_FAILURE';
const NOTIF_TRANSACTION_STATUS_REQUEST = 'NOTIF_TRANSACTION_STATUS_REQUEST';
const NOTIF_TRANSACTION_STATUS_SUCCESS = 'NOTIF_TRANSACTION_STATUS_SUCCESS';
const NOTIF_TRANSACTION_STATUS_FAILURE = 'NOTIF_TRANSACTION_STATUS_FAILURE';
const ACTION_TRANSACTION_STATUS_REQUEST = 'ACTION_TRANSACTION_STATUS_REQUEST';
const ACTION_TRANSACTION_STATUS_SUCCESS = 'ACTION_TRANSACTION_STATUS_SUCCESS';
const ACTION_TRANSACTION_STATUS_FAILURE = 'ACTION_TRANSACTION_STATUS_FAILURE';

function selectElement(selectAction, value) {
  return dispatch => {
    dispatch({
      type: selectAction,
      data: value
    });
  }
}

function selectMethod(value) {
  return selectElement(SET_USER_ADMIN_ACCESS, value);
}

function selectAction(action) {
  return selectElement(SELECT_ACTION_TA_ACTION_BLOCK, action);
}

function selectStatus(action) {
  return selectElement(SELECT_STATUS_TA_ACTION_BLOCK, action);
}

function launchForceStatus(parameters) {
  return dispatch => {
    dispatch({type: FORCE_TRANSACTION_STATUS_REQUEST});
    fetchAPI(ServicesURLConstant.TRANSACTIONS_SAVE_FORCING_TRANSACTION, {
      method: "POST",
      body: JSON.stringify(parameters)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch({
          type: 'SEARCH_ANALYZER_REFERENCE_SUCCESS',
          data: responseJson.data
        });
        dispatch({
          type: FORCE_TRANSACTION_STATUS_SUCCESS
        });
        dispatch(createNotification('Successfully forced transaction status', 'success'));
      })
      .catch(error => {
        dispatch(createNotification(error.message, 'danger'));
        dispatch({type: FORCE_TRANSACTION_STATUS_FAILURE});
      });
  }
}

function launchNotify(parameters) {
  return dispatch => {
    dispatch({type: NOTIF_TRANSACTION_STATUS_REQUEST});
    fetchAPI(ServicesURLConstant.TRANSACTIONS_SAVE_RELAUNCH_NOTIF_TRANSACTION, {
      method: "POST",
      body: JSON.stringify(parameters)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch({
          type: 'SEARCH_ANALYZER_REFERENCE_SUCCESS',
          data: responseJson.data
        });
        dispatch({
          type: NOTIF_TRANSACTION_STATUS_SUCCESS
        });
        dispatch(createNotification('Successfully marked transactions for notification relaunch', 'success'));
      })
      .catch(error => {
        dispatch(createNotification(error.message, 'danger'));
        dispatch({type: NOTIF_TRANSACTION_STATUS_FAILURE});
      });
  }
}

function launchRelaunchTransact(parameters) {
  return dispatch => {
    dispatch({type: ACTION_TRANSACTION_STATUS_REQUEST});
    fetchAPI(ServicesURLConstant.TRANSACTIONS_SAVE_RELAUNCH_ACTION_TRANSACTION, {
      method: "POST",
      body: JSON.stringify(parameters)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch({
          type: 'SEARCH_ANALYZER_REFERENCE_SUCCESS',
          data: responseJson.data
        });
        dispatch({
          type: ACTION_TRANSACTION_STATUS_SUCCESS
        });
        dispatch(createNotification('Successfully marked transaction for retrial', 'success'));
      })
      .catch(error => {
        dispatch(createNotification(error.message, 'danger'));
        dispatch({type: ACTION_TRANSACTION_STATUS_FAILURE});
      });
  }
}

export const actions = {
  selectMethod: selectMethod,
  selectAction: selectAction,
  selectStatus: selectStatus,
  launchForceStatus: launchForceStatus,
  launchNotify: launchNotify,
  launchRelaunchTransact: launchRelaunchTransact
};

/***************************************************************/
/* Reducer functions
/***************************************************************/
const initialState = {
  selectedAction: null,
  selectedStatus: null,
  loading: false,
};

export function transactionAnalyzerActionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_ADMIN_ACCESS:
      return {...state, selectedMethod: action.data, selectedStatus: null};
    case SELECT_ACTION_TA_ACTION_BLOCK:
      return {...state, selectedAction: action.data, selectedStatus: null};
    case SELECT_STATUS_TA_ACTION_BLOCK:
      return {...state, selectedStatus: action.data};

    case NOTIF_TRANSACTION_STATUS_REQUEST:
    case FORCE_TRANSACTION_STATUS_REQUEST:
    case ACTION_TRANSACTION_STATUS_REQUEST:
      return {...state, loading: true};

    case NOTIF_TRANSACTION_STATUS_FAILURE:
    case FORCE_TRANSACTION_STATUS_FAILURE:
    case ACTION_TRANSACTION_STATUS_FAILURE:
    case NOTIF_TRANSACTION_STATUS_SUCCESS:
    case FORCE_TRANSACTION_STATUS_SUCCESS:
    case ACTION_TRANSACTION_STATUS_SUCCESS:
      return {...initialState};
    default:
      return state;
  }
}

/***************************************************************/
/* selectors function
/***************************************************************/

const actionsSelector = createSelector(
  [state => state.analyzer.form.initReferenceData],

  (initReferenceData) => {
    if (initReferenceData && initReferenceData.contractActions) {
      let {contractActions} = initReferenceData;
      let actions = contractActions.map(contractAction => ({
        actionId: contractAction.cactType,
        action: contractAction.cactDescription
      }));
      return actions;
    }
    return [];
  });

const statusesSelector = createSelector(
  [state => state.analyzer.form.initReferenceData,
    state => state.analyzer.form.transactions,
    state => state.analyzer.form.selectedRowsTransaction,
    state => state.analyzer.actions.selectedAction],

  (initReferenceData, transactions, selectedRowsTransactionId, selectedAction) => {

    if (initReferenceData
      && transactions.length > 0
      && selectedRowsTransactionId
      && selectedAction) {

      let statusesByTransaction = transactions.filter(tr => (selectedRowsTransactionId[tr.transactionId]))
        .map(transaction => (transaction.availablePSPStatuses
          .filter(status => selectedAction === status.transactionStatusContractActionType)
          .map(status => ({
            statusId: {
              type: status.transactionStatusContractActionType,
              description: status.transactionStatusDescription
            }, statusDescription: status.transactionStatusDescription
          }))));

      return _.uniqBy(_.intersectionBy(...statusesByTransaction, 'statusDescription'), 'statusDescription');
    }
    return [];
  });

export const selectors = {
  actionsSelector: actionsSelector,
  statusesSelector: statusesSelector
};
