import {
  DEFAULT,
  FETCH_META_DATA,
  FETCH_META_DATA_ERROR,
  FETCH_META_DATA_SUCCESS,
} from "../actions/Types"

const initialState = {
  artifact: "",
  name: "",
  version: "",
  loading: true
};

export function metaReducer(state = initialState, action = DEFAULT) {

  switch (action.type) {
    case FETCH_META_DATA:
      return {...state, loading: true}
    case FETCH_META_DATA_SUCCESS:
      return {
        ...state,
        artifact: action.data.artifact,
        name: action.data.name,
        version: action.data.version,
        loading: false
      }
    case FETCH_META_DATA_ERROR:
      break;
    case DEFAULT:
      break;
    default:
      return state;
  }
}
