import _ from "lodash";
import {createSelector} from "reselect";
import { fetchAPI } from '../utils/UrlHelper';
import moment from "moment";


/***************************************************************/
/* Actions
/***************************************************************/
const SELECT_CLIENT_TO_GET_PAYMENT_LIST = 'SELECT_CLIENT_TO_GET_PAYMENT_LIST';
const SELECT_CHANNEL_TO_GET_PAYMENT_LIST = 'SELECT_CHANNEL_TO_GET_PAYMENT_LIST';
const SELECT_CONTEXT_TO_GET_PAYMENT_LIST = 'SELECT_CONTEXT_TO_GET_PAYMENT_LIST';
const ORDERAMOUNT_TO_GET_PAYMENT_LIST = 'ORDERAMOUNT_TO_GET_PAYMENT_LIST';
const ONECLICK_TO_GET_PAYMENT_LIST = 'ONECLICK_TO_GET_PAYMENT_LIST';
const PRIVATECUSTOMER_TO_GET_PAYMENT_LIST = 'PRIVATECUSTOMER_TO_GET_PAYMENT_LIST';
const PROFESSIONALCUSTOMER_TO_GET_PAYMENT_LIST = 'PROFESSIONALCUSTOMER_TO_GET_PAYMENT_LIST';
const PAYMENTID_TO_GET_PAYMENT_LIST = 'PAYMENTID_TO_GET_PAYMENT_LIST';
const FORLOYALTYCUSTOMER_TO_GET_PAYMENT_LIST = 'FORLOYALTYCUSTOMER_TO_GET_PAYMENT_LIST';
const FORNOTLOYALTYCUSTOMER_TO_GET_PAYMENT_LIST = 'FORNOTLOYALTYCUSTOMER_TO_GET_PAYMENT_LIST';
const REGULARDELIVERY_TO_GET_PAYMENT_LIST = 'REGULARDELIVERY_TO_GET_PAYMENT_LIST';
const PAYMENTONDELIVERY_TO_GET_PAYMENT_LIST = 'PAYMENTONDELIVERY_TO_GET_PAYMENT_LIST';
const VERSION_TO_GET_PAYMENT_LIST = 'VERSION_TO_GET_PAYMENT_LIST';
const TYPE_TO_GET_PAYMENT_LIST = 'TYPE_TO_GET_PAYMENT_LIST';
const HEADER_KEYS_TO_GET_PAYMENT_LIST = 'HEADER_KEYS_TO_GET_PAYMENT_LIST';
const HEADER_VALUES_TO_GET_PAYMENT_LIST = 'HEADER_VALUES_TO_GET_PAYMENT_LIST';
const CHANGE_SHOW_PARAMS_STATE = 'CHANGE_SHOW_PARAMS_STATE';
const ADD_HEADER = 'ADD_HEADER';
const DELETE_HEADER = 'DELETE_HEADER';
const GET_PAYMENT_LIST = 'GET_PAYMENT_LIST';
const GET_PAYMENT_LIST_SUCCESS = 'GET_PAYMENT_LIST_SUCCESS';
const METHOD_FORM = 'METHOD_FORM';

const UPDATE_CUSTOMER_DATA = 'UPDATE_CUSTOMER_DATA';
const VALIDATE_CUSTOMER_CODE = 'VALIDATE_CUSTOMER_CODE';
const UPDATE_DATA = 'UPDATE_DATA';
const VALIDATE_CODE = 'VALIDATE_CODE';

const UPDATE_JSON = 'UPDATE_JSON';


function changeMethodForm(data) {
  return dispatch => {
    dispatch({
      type: METHOD_FORM,
      data: data
    });
  };
}

function updateJSON(json) {
  return dispatch => {
    dispatch({
      type: UPDATE_JSON,
      data: json
    });
  };
}

/**
 * Customer Data
 */
function updateCustomerData(data) {
  return dispatch => {
    dispatch({
      type: UPDATE_CUSTOMER_DATA,
      data: data
    });
  };
}

function validateCustomerCode(data) {
  return dispatch => {
    dispatch({
      type: VALIDATE_CUSTOMER_CODE,
      data: data
    });
  };
}
/**
 * Payment Data
 */
function updateData(data) {
  return dispatch => {
    dispatch({
      type: UPDATE_DATA,
      data: data
    });
  };
}

function validateCode(data) {
  return dispatch => {
    dispatch({
      type: VALIDATE_CODE,
      data: data
    });
  };
}
/**
 * Generic dispatch function
 *
 * @param selectAction
 * @param value
 * @returns {function(*)}
 */
function selectElement(selectAction, value) {
  return dispatch => {
    dispatch({
      type: selectAction,
      data: value
    });
  }
}

function getPaymentListSelectClient(value){
  return selectElement(SELECT_CLIENT_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListSelectChannel(value){
  return selectElement(SELECT_CHANNEL_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListSelectContext(value){
  return selectElement(SELECT_CONTEXT_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListOrderAmount (value){
  return selectElement(ORDERAMOUNT_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListOneClick (value){
  return selectElement(ONECLICK_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListOnePrivateCustomer (value){
  return selectElement(PRIVATECUSTOMER_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListOneProfessionalCustomer  (value){
  return selectElement(PROFESSIONALCUSTOMER_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListPaymentId (value){
  return selectElement(PAYMENTID_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListForLoyaltyCustomer (value){
  return selectElement(FORLOYALTYCUSTOMER_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListForNotLoyaltyCustomer (value){
  return selectElement(FORNOTLOYALTYCUSTOMER_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListRegularDelivery (value){
  return selectElement(REGULARDELIVERY_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListPaymentOnDelivery (value){
  return selectElement(PAYMENTONDELIVERY_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListVersion (value){
  return selectElement(VERSION_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListType (value){
  return selectElement(TYPE_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListHeaderKeys (value){
  return selectElement(HEADER_KEYS_TO_GET_PAYMENT_LIST, value);
}

function getPaymentListHeaderValues (value){
  return selectElement(HEADER_VALUES_TO_GET_PAYMENT_LIST, value);
}

function deleteHeader (value){
  return selectElement(DELETE_HEADER, value);
}

function addHeader (){
  return dispatch => {
    dispatch({
      type: ADD_HEADER,
    });
  }
}

function changeShowParamsState(){
  return dispatch => {
    dispatch({
      type: CHANGE_SHOW_PARAMS_STATE,
    });
  }
}

function getPaymentList(customerData, paymentData, paymentListParams, collab) {
  var formatCustomerData = _.cloneDeep(customerData);
  const paymentListUrl = buildPaymentListUrl(paymentListParams);

  return dispatch => {
    dispatch({
      type: GET_PAYMENT_LIST
    });

    // Replace user data
    formatCustomerData.immutable_attributes.person.firstName = collab.additionalProperties.familyName;
    formatCustomerData.immutable_attributes.person.lastName = collab.additionalProperties.givenName;
    formatCustomerData.immutable_attributes.person.email = collab.additionalProperties.mail;

    const headerObject = {}
    paymentListParams.headers.forEach(header => headerObject[header.key] = header.value)

    formatCustomerData.immutable_attributes.order_desc = JSON.stringify(formatCustomerData.immutable_attributes.order_desc);
    formatCustomerData.immutable_attributes.person = JSON.stringify(formatCustomerData.immutable_attributes.person);
    formatCustomerData.immutable_attributes.shipping_address = JSON.stringify(formatCustomerData.immutable_attributes.shipping_address);
    formatCustomerData.mutable_attributes.billing_address = JSON.stringify(formatCustomerData.mutable_attributes.billing_address);
    
    fetchAPI(paymentListUrl, { method: "POST", body: JSON.stringify(formatCustomerData), headers: headerObject})
    .then((response) => response.json())
    .then((responseJson) => {
      dispatch({
        type: GET_PAYMENT_LIST_SUCCESS,
        data: responseJson
      });
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
    })
  }
}

function buildPaymentListUrl(params){
  var urlParam = '';

  Object.keys(params).forEach(function(key) {
    if(params[key] !== null){
      urlParam += '&'+key+'='+encodeURI(params[key]);
    }
  });

  return window.OnePayConfiguration.serversURLConstant.FRONT_API_URL + "/v3/payments?" + urlParam.substring(1);
}

export const actions = {
  getPaymentListSelectClient : getPaymentListSelectClient,
  getPaymentListSelectChannel : getPaymentListSelectChannel,
  getPaymentListSelectContext : getPaymentListSelectContext,
  getPaymentListOrderAmount : getPaymentListOrderAmount,
  getPaymentListOneClick : getPaymentListOneClick,
  getPaymentListOnePrivateCustomer : getPaymentListOnePrivateCustomer,
  getPaymentListOneProfessionalCustomer : getPaymentListOneProfessionalCustomer,
  getPaymentListPaymentId : getPaymentListPaymentId,
  getPaymentListForLoyaltyCustomer : getPaymentListForLoyaltyCustomer,
  getPaymentListForNotLoyaltyCustomer : getPaymentListForNotLoyaltyCustomer,
  getPaymentListRegularDelivery : getPaymentListRegularDelivery,
  getPaymentListPaymentOnDelivery : getPaymentListPaymentOnDelivery,
  getPaymentListVersion : getPaymentListVersion,
  getPaymentListType : getPaymentListType,
  getPaymentListHeaderKeys : getPaymentListHeaderKeys,
  getPaymentListHeaderValues : getPaymentListHeaderValues,
  changeShowParamsState : changeShowParamsState,
  addHeader : addHeader,
  deleteHeader : deleteHeader,
  getPaymentList : getPaymentList,
  updateCustomerData: updateCustomerData,
  validateCustomerCode: validateCustomerCode,
  updateData: updateData,
  validateCode: validateCode,
  changeMethodForm: changeMethodForm,
  updateJSON: updateJSON
};

/***************************************************************/
/* Reducer functions
/***************************************************************/
const initialCustomerData = {
  "immutable_attributes": {
    "currency": "EUR",
    "ip": "127.0.0.1",
    "iso_language": "FR",
    "order_amount": 18.6,
    "order_date": "10/12/2018",
    "order_desc": {itemArray:[{productType:1,ref:1858796,qty:1,productName:"Kindershorty 100 voor surfen neopreen",unitPrice:7.99,description:"Kindershorty 100 voor surfen neopreen"},{productType:1,ref:2102120,qty:1,productName:"Verzendkosten",unitPrice:10.61,description:"Verzendkosten"}]},
    "order_id": `ADMIN_TEST_${moment().unix()}`,
    "person": {id:"80014741709",civility:"M",lastName:"##LASTNAME##",firstName:"##FIRSTNAME##",telHome:"0611111111",email:"##EMAIL##",birthDate:"1987-05-10T00:00:00+0100",accountCreatedDate:"2018-08-28T14:33:08+0100"},
    "shipping_address": {street1:"Heathrowstraat 5",postalCode:"1043CE",city:"Amsterdam",country:"Nederland",countryCode:"NL",firstName:"##FIRSTNAME##",lastName:"##LASTNAME##",email:"##EMAIL##"},
    "tp": "http://google.fr",
    "external_action_id": `TEST-250512515-${moment().unix()}`,
  },
  "mutable_attributes": {
    "billing_address": {street1:"Heathrowstraat 5",postalCode:"1043CE",city:"Amsterdam",countryCode:"NL",firstName:"##FIRSTNAME##",lastName:"##LASTNAME##",email:"##EMAIL##"}
  }
};

const initialPaymentData = {
  "config": {
    "apiKey": window.OnePayConfiguration.serversURLConstant.API_KEY,
    "compactMode": false,
    "locale": {
      "country": "fr",
      "language": "FR"
    },
    "currency": "EUR",
    "phoneRegex": "^((\\+)33|0)[1-9](\\d{2}){4}$",
    "homeUrl" : "http://localhost:5000/",
    "onepayApiUrl": window.OnePayConfiguration.serversURLConstant.WIDGET_API_URL,
    "onepayApiSimulator": window.OnePayConfiguration.serversURLConstant.API_SIMULATOR,
    "redirectionUrl": "http://localhost:5000/confirmationpage.html",
    "declineUrl": "http://localhost:5000/confirmationpage.html?IS_ERROR=true",
    "orderTrackingUrl": "http://localhost:9000/nl/account/orderTracking",
    "paymentUrl": "http://localhost:9000/nl/checkout/payments",
    "additionnalInformations":[{
      "BRAINTREE_-_PAYPAL":{
        "saveInWalletDefault":true,
        "saveInWallet":true
      },
      "PAYON_-_CREDITCARD":{
        "saveInWalletDefault":false,
        "saveInWallet":true,
        "newCardFlag3D": false,
      }
    }],
    "prefilledPaymentGroups":{
      "PAYON_-_CREDITCARD": [
        {
          "expirationDate": {
            "time": 1577750400000
          },
          "externalTokenId": "750000000000001083",
          "paymentTypeId": 221,
          "cancelAmountRefunded": 0,
          "loyaltyToken": "Ydl/F7pQENz6PHXdtC/i60QUfUfA1maxNTci7hwUGlz/nSj+nYTTlYiU/xJG/TsB9wgg7FxdomCkmdNMHpIufGvcZ6x6ranTGp6LG0f4ISs=",
          "favorite": false,
          "brandName": "MASTER",
          "amount": null,
          "token": "8a82944954bf044c0154cd48e8c9618a",
          "balance": null,
          "creationDate": {
            "time": 1506527890761
          },
          "pinCode": "",
          "name": "PAYON_-_CREDITCARD",
          "owner": "Test",
          "paymentNameKey": null,
          "paymentGroupId": null,
          "cardNumber": "**** 5454",
          "flag3D": false,
        }
      ],
      "FIRST_DATA_-_GIFTCARD" : [
        {
          "expirationDate": 1577750400000,
          "payment_id": 11,
          "token": "7777091582677411",
          "pinCode": "3238",
          "cardNumber": "**** 7411"
        }
      ]
    }
  }
};

const formInitialState = {
  selectedClient : null,
  selectedChannel : null,
  selectedContext : null,
  orderAmount : 18.6,
  oneClick  : null,
  privateCustomer : null,
  professionalCustomer : null,
  paymentId : null,
  forLoyaltyCustomer : null,
  forNotLoyaltyCustomer : null,
  regularDelivery : null,
  paymentOnDelivery : null,
  version : null,
  type : null,
  showParams : true,
  headers : [],
  paymentListWidget : null,
  meanOfPaymentList:[]
}

const initialState = {
  ...formInitialState,
  methodForm: 'input',
  typingPaymentData: initialPaymentData,
  typingCustomerData: initialCustomerData
};

function updateHeaderKey(currentHeaders, key, index) {
  const headers = [...currentHeaders]
  headers[index].key = key

  return headers
}

function updateHeaderValue(currentHeaders, value, index) {
  const headers = [...currentHeaders]
  headers[index].value = value

  return headers
}

export function getPaymentListWidgetReducer(state =  initialState, action){
  switch(action.type){
    case SELECT_CLIENT_TO_GET_PAYMENT_LIST:
      let newState = { selectedClient: action.data };
      if (state.selectedClient !== action.data) {
        // client has changed
        newState = { ...newState, selectedChannel: null, selectedContext:null, saved: false };
      }
      return { ...state, ...newState };
    case SELECT_CHANNEL_TO_GET_PAYMENT_LIST:
    return {...state, selectedChannel: action.data, saved: false};
    case SELECT_CONTEXT_TO_GET_PAYMENT_LIST:
    return {...state, selectedContext: action.data, saved: false};
    case ORDERAMOUNT_TO_GET_PAYMENT_LIST:
    return {...state, orderAmount: action.data, saved: false};
    case ONECLICK_TO_GET_PAYMENT_LIST:
    return {...state, oneClick: action.data, saved: false};
    case PRIVATECUSTOMER_TO_GET_PAYMENT_LIST:
    return {...state, privateCustomer: action.data, saved: false};
    case PROFESSIONALCUSTOMER_TO_GET_PAYMENT_LIST:
    return {...state, professionalCustomer: action.data, saved: false};
    case PAYMENTID_TO_GET_PAYMENT_LIST:
    return {...state, paymentId: action.data, saved: false};
    case FORLOYALTYCUSTOMER_TO_GET_PAYMENT_LIST:
    return {...state, forLoyaltyCustomer: action.data, saved: false};
    case FORNOTLOYALTYCUSTOMER_TO_GET_PAYMENT_LIST:
    return {...state, forNotLoyaltyCustomer: action.data, saved: false};
    case REGULARDELIVERY_TO_GET_PAYMENT_LIST:
    return {...state, regularDelivery: action.data, saved: false};
    case PAYMENTONDELIVERY_TO_GET_PAYMENT_LIST:
    return {...state, paymentOnDelivery: action.data, saved: false};
    case VERSION_TO_GET_PAYMENT_LIST:
    return {...state, version: action.data, saved: false};
    case TYPE_TO_GET_PAYMENT_LIST:
    return {...state, type: action.data, saved: false};
    case HEADER_KEYS_TO_GET_PAYMENT_LIST:
      return {...state, headers: updateHeaderKey(state.headers, action.data.value, action.data.index), saved: false};
    case HEADER_VALUES_TO_GET_PAYMENT_LIST:
      return {...state, headers: updateHeaderValue(state.headers, action.data.value, action.data.index), saved: false};
    case CHANGE_SHOW_PARAMS_STATE:
      return {...state, showParams: !state.showParams, saved: false};
    case ADD_HEADER:
      const biggerHeaders = [...state.headers]
      biggerHeaders.push({key: "", value: ""})
      return {...state, headers: biggerHeaders, saved: false};
    case DELETE_HEADER:
      const smallerHeaders = [...state.headers]
      smallerHeaders.splice(action.data, 1)
      return {...state, headers: smallerHeaders, saved: false};
    case GET_PAYMENT_LIST_SUCCESS:
      return {...state, meanOfPaymentList : action.data, saved: true};
    case UPDATE_CUSTOMER_DATA:
      return {...state, typingCustomerData: action.data.code, saved: false};
    case VALIDATE_CUSTOMER_CODE:
      return {...state, hasError: action.data.errors.length > 0};
    case UPDATE_DATA:
      return {...state, typingPaymentData: action.data.code, saved: false};
    case VALIDATE_CODE:
      return {...state, hasError: action.data.errors.length > 0};
    case METHOD_FORM:
      return {...state, methodForm: action.data};
    case UPDATE_JSON:
      return action.data;
    default:
      return state;
  }
}

/***************************************************************/
/* Selector functions
/***************************************************************/

const channelsSelector = createSelector(
    [state => state.appClient.clients,
      state => state.paymentListWidget.selectedClient],
    (clients, selectedClient) => {
      if (clients && selectedClient) {
        const foundClient = clients.find(client => (client.externalId === selectedClient));
        return foundClient ?  _.orderBy(foundClient.channels, ["boDisplayOrder"], ["asc"]) : [];
      }
      return [];
    }
);

/**
 * Selector  to get the contexts from the selected channels.
 *
 * @param state the state
 * @returns list of PSP with payments
 */
const contextsSelector = createSelector(
    [state => channelsSelector(state),
      state => state.paymentListWidget.selectedChannel],
    (channels, selectedChannels) => {
      return channels.filter(c => (selectedChannels === c.type));
    }
);

export const selectors = {
  channelsSelector: channelsSelector,
  contextsSelector: contextsSelector,
};
