import * as ServicesURLConstant from 'utils/ServicesURLConstant'
import {fetchAPI} from 'utils/UrlHelper'
import _ from 'lodash'
import {createSelector} from 'reselect'
import {createNotification} from "./../api/NotificationApi";

/***************************************************************/
/* Actions
/***************************************************************/
const LIST_MOPS = 'LIST_MOPS'
const LIST_MOPS_SUCCESS = 'LIST_MOPS_SUCCESS'
const UPDATE_MOPS_ORDER = 'UPDATE_MOPS_ORDER'
const UPDATE_MOP_ACTIVATION = 'UPDATE_MOP_ACTIVATION'
const CHECK_TOGGLE_ACTIVE = 'CHECK_TOGGLE_ACTIVE'
const SAVE_MOP_LIST_REQUEST = 'SAVE_MOP_LIST_REQUEST'
const SAVE_MOP_LIST_SUCCESS = 'SAVE_MOP_LIST_SUCCESS'
const SAVE_MOP_LIST_FAILURE = 'SAVE_MOP_LIST_FAILURE'
const GET_MOP_CONFIGURATION_REQUEST = 'GET_MOP_CONFIGURATION_REQUEST'
const GET_MOP_CONFIGURATION_SUCCESS = 'GET_MOP_CONFIGURATION_SUCCESS'
const GET_CONTRACTS_REQUEST = 'GET_CONTRACTS_REQUEST'
const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS'
const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST'
const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS'
const GET_PAYMENT_ATTRIBUTES_REQUEST = 'GET_PAYMENT_ATTRIBUTES_REQUEST'
const GET_PAYMENT_ATTRIBUTES_SUCCESS = 'GET_PAYMENT_ATTRIBUTES_SUCCESS'
const UPDATE_PAYMENT_ATTRIBUTE_REQUEST = 'UPDATE_PAYMENT_ATTRIBUTE_REQUEST'
const UPDATE_PAYMENT_ATTRIBUTE_SUCCESS = 'UPDATE_PAYMENT_ATTRIBUTE_SUCCESS'
const MOP_CONFIGURATION_CANCEL = 'MOP_CONFIGURATION_CANCEL'
const UPDATE_MOP_ATTRIBUTE = 'UPDATE_MOP_ATTRIBUTE'
const UPDATE_MOP_PAYMENT_CLIENT_REFUND = 'UPDATE_MOP_PAYMENT_CLIENT_REFUND'
const UPDATE_MOP_PAYMENT_CLIENT_NBO = 'UPDATE_MOP_PAYMENT_CLIENT_NBO'
const UPDATE_MOP_PAYMENT_CLIENT_STABLE = 'UPDATE_MOP_PAYMENT_CLIENT_STABLE'
const SAVE_MOP_REQUEST = 'SAVE_MOP_REQUEST'
const SAVE_MOP_SUCCESS = 'SAVE_MOP_SUCCESS'
const SAVE_MOP_FAILURE = 'SAVE_MOP_FAILURE'

function listMops(appClientId, channelId) {
  return dispatch => {
    dispatch({
      type: LIST_MOPS,
    })

    fetchAPI(ServicesURLConstant.getMeansOfPaymentForClientAndChannelTypeUrl(appClientId, channelId))
      .then((response) => {
        if (!response.ok) {
          dispatch(createNotification(response.statusText, 'danger'))
        }
        return response.json()
      })
      .then((responseJson) => {
        dispatch({
          type: LIST_MOPS_SUCCESS,
          data: responseJson,
        })
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error',
          },
        })
      })
  }
}

function updateMops(mops) {
  return dispatch => {
    dispatch({
      type: UPDATE_MOPS_ORDER,
      data: {
        mops: mops,
        mopsNewOrder: mops.mops.map((mop, index) => ({pcctId: mop.pcctId, newOrder: index})),
      },
    })
  }
}

function activateMop(mopId, activated) {
  return dispatch => {
    dispatch({
      type: UPDATE_MOP_ACTIVATION,
      data: {mopId, activated},
    })
  }
}

function checkToggleActive() {
  return dispatch => {
    dispatch({
      type: CHECK_TOGGLE_ACTIVE
    })
  }
}

function saveMeanOfPaymentList(selectedAppClientId, selectedChannelId, mopsNewOrder, mopsNewActivation) {
  return dispatch => {
    dispatch({
      type: SAVE_MOP_LIST_REQUEST,
    })
    let updateOrderData = {
      mopsOrders: mopsNewOrder,
      mopsActivation: Array.from(mopsNewActivation.entries(), entry => ({pcctId: entry[0], activated: entry[1]})),
    }

    fetchAPI(ServicesURLConstant.setMeansOfPaymentForClientAndChannelTypeUrl(selectedAppClientId, selectedChannelId),
      {method: "PUT", body: JSON.stringify(updateOrderData)})
      .then((response) => {
        if (!response.ok) {
          dispatch({
            type: 'DISPLAY_MESSAGE',
            data: {
              text: "Error while saving ",
              type: 'error',
            },
          })
        } else {
          dispatch({type: SAVE_MOP_LIST_SUCCESS})
        }
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error',
          },
        })
        dispatch({type: SAVE_MOP_LIST_FAILURE})
      })
  }
}

function saveMeanOfPaymentAttributes(selectedAppClientId, selectedChannelId, mopId, meanOfPaymentAttributes) {
  return dispatch => {
    dispatch({
      type: SAVE_MOP_REQUEST,
    })

    fetchAPI(ServicesURLConstant.setMeanOfPaymentForClientAndChannelTypeUrl(selectedAppClientId, selectedChannelId, mopId),
      {method: "PUT", body: JSON.stringify(meanOfPaymentAttributes)})
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch({type: SAVE_MOP_SUCCESS, data: responseJson})
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error',
          },
        })
        dispatch({type: SAVE_MOP_FAILURE})
      })
  }
}


function getMopConfiguration(selectedAppClientId, selectedChannelId, mopId) {
  return dispatch => {
    dispatch({
      type: GET_MOP_CONFIGURATION_REQUEST,
    })
    fetchAPI(
      ServicesURLConstant.getMeanOfPaymentForClientAndChannelTypeUrl(selectedAppClientId, selectedChannelId, mopId))
      .then((response) => response.json())
      .then((responseJson) => {

        const sorted = Object.assign({}, responseJson);

        sorted.attributes = sorted.attributes.sort(
          (a, b) => {
            return (a.attributeCategory > b.attributeCategory * 10) + (a.valueType < b.valueType)
          }
        )

        return sorted
      })
      .then((responseJson) => {
        dispatch({
          type: GET_MOP_CONFIGURATION_SUCCESS,
          data: responseJson,
        })
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error',
          },
        })
      })
  }
}

function getContracts(selectedAppClientId, mopId) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACTS_REQUEST,
    })
    fetchAPI(
      ServicesURLConstant.contracts(selectedAppClientId, mopId))
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch({
          type: GET_CONTRACTS_SUCCESS,
          data: responseJson,
        })
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error',
          },
        })
      })
  }
}

function updateContract(selectedAppClientId, mopId, contract) {
  return dispatch => {
    dispatch({
      type: UPDATE_CONTRACT_REQUEST,
      contract: contract,
    })
    fetchAPI(ServicesURLConstant.contractsSupport(selectedAppClientId, mopId), {
      method: "PUT",
      body: JSON.stringify({...contract}),
    })
      .then(response => response.text())
      .then(body => {
        if (!body || /^\s*$/.test(body)) {
          dispatch({
            type: UPDATE_CONTRACT_SUCCESS,
            data: contract,
          })
        } else {
          dispatch({
            type: 'DISPLAY_MESSAGE',
            data: {
              text: body,
              type: 'error',
            },
          })
        }
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error',
          },
        })
      })
  }
}

function getPaymentAttributes(selectedAppClientId, selectedChannelId, mopId) {
  return dispatch => {
    dispatch({
      type: GET_PAYMENT_ATTRIBUTES_REQUEST,
    })
    fetchAPI(
      ServicesURLConstant.paymentAttributes(selectedAppClientId, selectedChannelId, mopId))
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch({
          type: GET_PAYMENT_ATTRIBUTES_SUCCESS,
          data: responseJson,
        })
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error',
          },
        })
      })
  }
}

function updatePaymentAttribute(selectedAppClientId, selectedChannelId, mopId, paymentAttribute) {
  return dispatch => {
    dispatch({
      type: UPDATE_PAYMENT_ATTRIBUTE_REQUEST,
      attribute: paymentAttribute,
    })
    fetchAPI(ServicesURLConstant.paymentAttributesSupport(selectedAppClientId, selectedChannelId, mopId), {
      method: "PUT",
      body: JSON.stringify({...paymentAttribute}),
    })
      .then(response => response.text())
      .then(body => {
        if (!body || /^\s*$/.test(body)) {
          dispatch({
            type: UPDATE_PAYMENT_ATTRIBUTE_SUCCESS,
            attribute: paymentAttribute,
          })
        } else {
          dispatch({
            type: 'DISPLAY_MESSAGE',
            data: {
              text: body,
              type: 'error',
            },
          })
        }
      })
      .catch(error => {
        dispatch({
          type: 'DISPLAY_MESSAGE',
          data: {
            text: error.message,
            type: 'error',
          },
        })
      })
  }
}

function cancelMeanOfPaymentConfiguration() {
  return dispatch => {
    dispatch({
      type: MOP_CONFIGURATION_CANCEL,
    })
  }
}

function updateAttribute(attribute, value) {
  return dispatch => {
    dispatch({
      type: UPDATE_MOP_ATTRIBUTE,
      data: {attribute, value},
    })
  }
}

function updatePaymentClientStable(attribute, value) {
  return dispatch => {
    dispatch({
      type: UPDATE_MOP_PAYMENT_CLIENT_STABLE,
      data: {attribute, value},
    })
  }
}

function updatePaymentClientRefund(attribute, value) {
  return dispatch => {
    dispatch({
      type: UPDATE_MOP_PAYMENT_CLIENT_REFUND,
      data: {attribute, value},
    })
  }
}

function updatePaymentClientNbo(attribute, value) {
  return dispatch => {
    dispatch({
      type: UPDATE_MOP_PAYMENT_CLIENT_NBO,
      data: {attribute, value},
    })
  }
}

export const actions = {
  listMops: listMops,
  updateMops: updateMops,
  activateMop: activateMop,
  checkToggleActive: checkToggleActive,
  saveMeanOfPaymentList: saveMeanOfPaymentList,
  saveMeanOfPaymentAttributes: saveMeanOfPaymentAttributes,
  getMopConfiguration: getMopConfiguration,
  getContracts: getContracts,
  updateContract: updateContract,
  getPaymentAttributes: getPaymentAttributes,
  updatePaymentAttribute: updatePaymentAttribute,
  cancelMeanOfPaymentConfiguration: cancelMeanOfPaymentConfiguration,
  updateAttribute: updateAttribute,
  updatePaymentClientStable: updatePaymentClientStable,
  updatePaymentClientRefund: updatePaymentClientRefund,
  updatePaymentClientNbo: updatePaymentClientNbo,
}

/***************************************************************/
/* Reducer functions
/***************************************************************/

const initialState = {
  loading: false,
  mops: [],
  mopsNewOrder: [],
  mopsNewActivation: new Map(),
  savingInProgress: false,
  mopLoading: false,
  mopConfiguration: null,
  lastMopModification: null,
  savingMopInProgress: false,
  contracts: {
    loading: true,
    data: [],
  },
  paymentAttributes: {
    loading: true,
    data: {},
  },
}

export function mopsReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_MOPS:
      return {
        ...state,
        loading: true,
        mops: [],
        mopsNewOrder: [],
        mopsNewActivation: new Map(),
        mopConfiguration: null,
        mopsActive: [],
        contracts: initialState.contracts,
        paymentAttributes: initialState.paymentAttributes,
      }
    case LIST_MOPS_SUCCESS:
      return {...state, mops: action.data, mopsActive: action.data.filter(mop => mop.pcctFoEnable === true) ,loading: false}
    case UPDATE_MOPS_ORDER:
      return {...state, mops: action.data.mops.mops, mopsNewOrder: action.data.mopsNewOrder}
    case UPDATE_MOP_ACTIVATION:
      let mops = state.mops.map(mop => {
        if (mop.pcctId === action.data.mopId) {
          mop.pcctFoEnable = action.data.activated
          if (state.mopsNewActivation.has(mop.pcctId)) {
            state.mopsNewActivation.delete(mop.pcctId)
          } else {
            state.mopsNewActivation.set(mop.pcctId, action.data.activated)
          }
        }
        return mop
      })
      return {...state, mops}
    case CHECK_TOGGLE_ACTIVE :
      return {...state, toggleActiveChecked : !state.toggleActiveChecked}
    case SAVE_MOP_LIST_REQUEST:
      return {...state, savingInProgress: true}
    case SAVE_MOP_LIST_SUCCESS:
      state.mopsNewActivation.clear()
      return {...state, mopsNewOrder: [], savingInProgress: false, mopsActive: state.mops.filter(mop => mop.pcctFoEnable === true) }
    case SAVE_MOP_LIST_FAILURE:
      return {...state, savingInProgress: false}
    case GET_MOP_CONFIGURATION_REQUEST:
      return {...state, mopLoading: true, mopConfiguration: null}
    case GET_MOP_CONFIGURATION_SUCCESS:
      return {...state, mopLoading: false, mopConfiguration: action.data, lastMopModification: new Date()}

    case GET_CONTRACTS_REQUEST:
      const loadingContracts = {...state.contracts}
      loadingContracts.loading = true
      return {...state, contracts: loadingContracts}

    case GET_CONTRACTS_SUCCESS:
      const newContracts = {...state.contracts}
      newContracts.loading = false
      newContracts.data = []

      action.data.definedContracts.forEach((contract) => {
        let contractAttributeToDefine = action.data.toDefineContracts.filter(toDefineContract => toDefineContract.action === contract.action)
        contractAttributeToDefine = contractAttributeToDefine.length > 0 ? contractAttributeToDefine[0] : {attributes: []}

        let mergedAttributes = [...contract.attributes]
        mergedAttributes.forEach(defined => defined.toDefine = false)
        contractAttributeToDefine.attributes.forEach((toDefine) => {
          toDefine.toDefine = true
          toDefine.loading = false
          mergedAttributes.push(toDefine)
        })

        const mergedContract = {...contract, attributes: mergedAttributes}
        newContracts.data.push({...mergedContract})
      })

      action.data.toDefineContracts.forEach((contract) => {
        let contractAttributeDefined = action.data.definedContracts.filter(definedContract => definedContract.action === contract.action)
        if (contractAttributeDefined.length <= 0) {
          let mergedAttributes = [...contract.attributes]
          mergedAttributes.forEach(toDefine => toDefine.toDefine = true)
          const mergedContract = {...contract, attributes: mergedAttributes}
          newContracts.data.push({...mergedContract})
        }
      })

      return {...state, contracts: {...newContracts}}
    case UPDATE_CONTRACT_REQUEST:

      const loadingContract = _.cloneDeep(state.contracts)

      loadingContract.loading = true

      loadingContract.data.forEach((updatedContract, i) => {
        if (updatedContract.action === action.contract.action) {
          loadingContract.data[i].loading = true
        }
      })

      return {...state, contracts: loadingContract}

    case UPDATE_CONTRACT_SUCCESS:

      const newContract = _.cloneDeep(state.contracts)

      state.contracts.data.forEach((item, currentIndex) => {
        if (item.action === action.data.action) {
          newContract.data[currentIndex] = action.data
        }
      })

      newContract.loading = false

      return {...state, contracts: {...newContract}}

    case GET_PAYMENT_ATTRIBUTES_REQUEST:

      const loadingAttributes = {...state.paymentAttributes}
      loadingAttributes.loading = true
      return {...state, paymentAttributes: loadingAttributes}

    case GET_PAYMENT_ATTRIBUTES_SUCCESS:

      const newAttributes = {...state.paymentAttributes}
      newAttributes.loading = false
      newAttributes.data = action.data
      return {...state, paymentAttributes: newAttributes}

    case UPDATE_PAYMENT_ATTRIBUTE_REQUEST:

      const updatingAttribute = _.cloneDeep(state.paymentAttributes)

      Object.keys(updatingAttribute.data).forEach(key => {
        if (key === action.attribute.key) {
          updatingAttribute.data[key].loading = true
        }
      })

      return {...state, paymentAttributes: updatingAttribute}

    case UPDATE_PAYMENT_ATTRIBUTE_SUCCESS:

      const updatedAttribute = _.cloneDeep(state.paymentAttributes)
      updatedAttribute.loading = false

      Object.keys(updatedAttribute.data).forEach(key => {
        if (key === action.attribute.key) {
          updatedAttribute.data[key].loading = false
          updatedAttribute.data[key].value = action.attribute.value
        }
      })

      return {...state, paymentAttributes: updatedAttribute}

    case MOP_CONFIGURATION_CANCEL:
      return {...state, mopLoading: false, mopConfiguration: null}
    case UPDATE_MOP_ATTRIBUTE:
      let mopConfiguration = state.mopConfiguration
      let {attribute} = action.data
      let newAttributeValue = action.data.value
      if (attribute.valueType === "Number" && !_.isEmpty(newAttributeValue)) {
        newAttributeValue = _.parseInt(newAttributeValue).toString()
        if (isNaN(newAttributeValue)) {
          newAttributeValue = "0"
        }
      }
      if (attribute.valueType === "Double" && !_.isEmpty(newAttributeValue)) {
        newAttributeValue = parseFloat(newAttributeValue).toString()
        if (isNaN(newAttributeValue)) {
          newAttributeValue = "0"
        }
      }

      let clonedAttribute = _.cloneDeep(attribute)
      clonedAttribute.newValue = newAttributeValue
      clonedAttribute.valueChanged = true

      //clone attribute and replace in list to force view refresh
      let index = _.findIndex(mopConfiguration.attributes, attribute)
      mopConfiguration.attributes.splice(index, 1, clonedAttribute)

      return {...state, lastMopModification: new Date()}
    case UPDATE_MOP_PAYMENT_CLIENT_STABLE:
      let mopConfigurationStable = state.mopConfiguration;
      let {attributeStable} = action.data;
      let newAttributeValueStable = action.data.value;

      let clonedAttributeStable = _.cloneDeep(attributeStable);
      clonedAttributeStable = {...clonedAttributeStable, newValue : newAttributeValueStable, valueChanged : true }

      mopConfigurationStable.paymentClient.isStable = clonedAttributeStable;
      return {...state, lastMopModification: new Date()}
    case UPDATE_MOP_PAYMENT_CLIENT_REFUND:
      let mopConfigurationRefund = state.mopConfiguration;
      let {attributeRefund} = action.data;
      let newAttributeValueRefund = action.data.value;

      let clonedAttributeRefund = _.cloneDeep(attributeRefund);
      clonedAttributeRefund = {...clonedAttributeRefund, newValue : newAttributeValueRefund, valueChanged : true }

      mopConfigurationRefund.paymentClient.pcPaymentMethodsUsedToRefund = clonedAttributeRefund;
      return {...state, lastMopModification: new Date()}
    case UPDATE_MOP_PAYMENT_CLIENT_NBO:
      let mopConfigurationNbo = state.mopConfiguration;
      let {attributeNbo} = action.data;
      let newAttributeValueNbo = action.data.value;

      let clonedAttributeNbo = _.cloneDeep(attributeNbo);
      clonedAttributeNbo = {...clonedAttributeNbo, newValue : newAttributeValueNbo, valueChanged : true }

      mopConfigurationNbo.paymentClient.nboId = clonedAttributeNbo;
      return {...state, lastMopModification: new Date()}
    case SAVE_MOP_REQUEST:
      return {...state, savingMopInProgress: true}
    case SAVE_MOP_SUCCESS:
      return {...state, savingMopInProgress: false, mopConfiguration: action.data, lastMopModification: new Date()}
    case SAVE_MOP_FAILURE:
      return {...state, savingMopInProgress: false}
    default:
      return state
  }
}

const mopConfigurationAttributesSelector = createSelector(
  [state => state.meanOfPayments.mopConfiguration,
    state => state.meanOfPayments.lastMopModification],

  (mopConfiguration, lastMopModification) => {
    if (mopConfiguration != null) {
      return _.groupBy(mopConfiguration.attributes, 'attributeCategory')
    }
    return []
  })

export const selectors = {
  attributesSelector: mopConfigurationAttributesSelector,
}
