import React, { Component }       from 'react';
import { connect }                from 'react-redux';

import _                  from 'lodash';
import { styles }         from 'styles';
import RefreshIndicator   from 'material-ui/RefreshIndicator';
import Toggle             from '../shared/form/Toggle';
import labels             from 'data/labels.json';
import FlatButton                       from 'material-ui/FlatButton';
import RaisedButton                     from 'material-ui/RaisedButton';
import { Card, CardActions, CardText }  from 'material-ui/Card';
import { actions, selectors }           from 'modules/mod-mops-config';
import MopAttribute from 'components/mop/MopAttribute';
import MopTableAttributes from 'components/mop/MopTableAttributes';
import ContractsView from './ContractsView';
import PaymentAttributesView from "./PaymentAttributesView"
import Box from "react-bulma-components/lib/components/box"
import SelectFilter from "../shared/form/SelectFilter";

const MOP_ATTRIBUTE_ALL_TYPES = ["AVAILABILITY", "BEHAVIOUR", "PROPERTIES"];
const MOP_ATTRIBUTE_LIST_TYPES = ["AVAILABILITY", "BEHAVIOUR"];

class MopConfiguration extends Component {

  constructor(props){
    super(props);
    this.state={
      filterSpecificConfiguration : false
    }
  }

  componentWillReceiveProps(nextProps) {

    const {mopConfiguration} = nextProps;
    if (mopConfiguration && mopConfiguration.paymentClient ) {
      if (mopConfiguration && mopConfiguration.paymentClient.isStable !== undefined ) {
        if(mopConfiguration && mopConfiguration.paymentClient.isStable.valueChanged === undefined) {
          mopConfiguration.paymentClient.isStable = {newValue: mopConfiguration.paymentClient.isStable, valueChanged: false};
        }
      }
      if(mopConfiguration && (mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund === null || mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund.valueChanged === undefined)) {
        mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund = {newValue: mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund, valueChanged: false};
      }
      if(mopConfiguration && mopConfiguration.paymentClient.nboId === undefined) {
        mopConfiguration.paymentClient.nboId = {newValue: mopConfiguration.paymentClient.nbo.nboId, valueChanged: false};
      }
    }
  }

  componentDidMount(){
    this.setState(() => ({filterSpecificConfiguration : false}));
  }

  render() {
    const {mopConfiguration, mopLoading, mopAttributes} = this.props;
    return (
      <div className={"mop-configuration"}>
        <h2>Means of payment > {mopConfiguration !== null && `${this.props.mopConfiguration.paymentName} (${this.props.mopConfiguration.paymentId})`}</h2>
        <div>
          {(mopLoading || mopConfiguration === null)&& <RefreshIndicator size={40} left={10} top={5} status="loading" style={styles.refresh}/>}
          {!mopLoading && mopConfiguration !== null &&
          <Card style={{marginBottom: 16, paddingLeft:20}}>
            <CardText>
              <Toggle
                id="filterConfiguration"
                name="filterConfiguration"
                label="Filter specific configuration"
                tooltip="Display only configuration relative to this specific channel/context"
                checked={this.state.filterSpecificConfiguration}
                onChange={this.toggleFilterSpecificConfiguration}
              />

              <div className="columns">
              {this.displayAttributes()}
                {mopConfiguration !== null && mopConfiguration.defaultConfiguration &&
                <div className="column">
                <Box id="STATUS" key="STATUS" className="is-half"
                     style={{height: "100%"}}>
                  <h1 className="title is-3 is-fullwidth has-text-centered"
                      id={`STATUS-title`}>
                    {labels.mop.propertyType["STATUS"]}
                  </h1>
                  <div id={`STATUS-attributes`}>
                  {mopConfiguration && mopConfiguration.paymentClient &&
                  <>
                    {mopConfiguration && mopConfiguration.paymentClient.isStable !== undefined && 
                      <Toggle id="stable"
                              key="stable"
                              name="stale"
                              label="Is payment stable"
                              tooltip="Is payment stable"
                              checked={mopConfiguration.paymentClient.isStable.newValue}
                              initialChecked={mopConfiguration.paymentClient.isStable.newValue}
                              size="small"
                              onChange={() => this.handleChangeStateStable(mopConfiguration.paymentClient.isStable.newValue)}
                              onClear={undefined} />
                    }
                    <SelectFilter id="refund-backup"
                                  name="refund-backup"
                                  label="Refund Backup"
                                  tooltip="Refund Backup"
                                  value={mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund.newValue}
                                  initialValue={mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund.newValue}
                                  options={this.refundOption()}
                                  onChange={this.handleSelectChangeValueRefund}
                                  onClear={undefined}
                    />

                    <SelectFilter id="nbo"
                                  name="nbo"
                                  label="NBO"
                                  tooltip="NBO"
                                  value={mopConfiguration.paymentClient.nboId.newValue}
                                  initialValue={mopConfiguration.paymentClient.nboId.newValue}
                                  options={this.nboOption()}
                                  onChange={this.handleSelectChangeValueNbo}
                                  onClear={undefined}
                    />
                  </>
                  }
                  </div>
                </Box>
                </div>}
              </div>
              <MopTableAttributes mopConfiguration={mopConfiguration}
                                  mopAttributes={mopAttributes}
                                  filterSpecificConfiguration={this.state.filterSpecificConfiguration}/>

              <ContractsView />

              <PaymentAttributesView />

            </CardText>

            <CardActions style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ alignSelf: 'flex-end' }}>
                {this.props.savingMopInProgress === true &&
                  <RefreshIndicator  left={0} top={0} status="loading" style={styles.refresh}/>
                }
                <FlatButton label="Cancel" onClick={this.handleCancelButton}/>
                <RaisedButton primary={true} label="Save" onClick={this.saveMeanOfPayment.bind(this)} disabled={!this.hasModification()}/>
              </div>
            </CardActions>

          </Card>
          }
        </div>
      </div>
    )
  }

  handleChangeStateStable = (value) => {
    const {dispatch} = this.props;
    dispatch(actions.updatePaymentClientStable("isStable", !value));
  };

  handleSelectChangeValueRefund = (event) => {
    const {dispatch} = this.props;
    dispatch(actions.updatePaymentClientRefund("refund", event.target.value));
  };

  handleSelectChangeValueNbo = (event) => {
    const {dispatch} = this.props;
    dispatch(actions.updatePaymentClientNbo("nbo", event.target.value));
  };

  handleCancelButton= () => {
    this.props.dispatch(actions.cancelMeanOfPaymentConfiguration());
  };

  toggleFilterSpecificConfiguration = () => {
    this.setState({filterSpecificConfiguration: !this.state.filterSpecificConfiguration});
  };
}

MopConfiguration.prototype.refundOption = function () {
  return [<option id="attributeValue-" key="attributeValue-" value="">AUTO</option>,
    <option id="attributeValue-CUSTOMER" key="attributeValue-CUSTOMER" value="CUSTOMER">CUSTOMER</option>,
    <option id="attributeValue-MANUAL" key="attributeValue-MANUAL" value="MANUAL">MANUAL</option>,
    <option id="attributeValue-OLD_MANUAL" key="attributeValue-OLD_MANUAL" value="OLD_MANUAL">OLD_MANUAL</option>
  ];
}

MopConfiguration.prototype.nboOption = function () {
  const {mopConfiguration} = this.props;
  return mopConfiguration.paymentClient.nbo.nboAvailable.map(nbo => (<option id={`attributeValue-${nbo.nboId}`}
                                                                             key={`attributeValue-${nbo.nboId}`}
                                                                             value={nbo.nboId}>
    {nbo.nboCodeNum} - {nbo.nboPmTextCode} / {nbo.nboPmType}
  </option>));
}

MopConfiguration.prototype.displayAttributes = function(){
  const {mopConfiguration, mopAttributes} = this.props;
  const displayedAttributes = [];

  MOP_ATTRIBUTE_LIST_TYPES.forEach(type => {

    const displayedDiv = [];
    let attributesForType = mopAttributes[type];

    if (attributesForType) {
      if (this.state.filterSpecificConfiguration === true) {
        attributesForType = attributesForType.filter(attribute => {
          let attributeValue = attribute.newValue;
          if (attributeValue === undefined) {
            attributeValue = attribute.value;
          }
          return attributeValue !== attribute.parentValue
        });
      }

      if (attributesForType.length > 0) {

        attributesForType.forEach(attribute => {
          let attributeKey = attribute.keyActionContext ? attribute.keyActionContext + " - " + attribute.key : attribute.key;
          displayedDiv.push(<MopAttribute key={`attribute-${attributeKey}`}
                                          attribute={attribute}
                                          defaultConfiguration={mopConfiguration.defaultConfiguration}
          />);
        })

        displayedAttributes.push(
          <div className="column">
            <Box id={type} key={type} className="is-half" style={{height: "100%"}}>
              <h1 className="title is-3 is-fullwidth has-text-centered" id={`${type}-title`}>
                {labels.mop.propertyType[type]}
              </h1>
              <div id={`${type}-attributes`}>
                {displayedDiv}
              </div>
            </Box>
          </div>
        );

      }
    }
  });

  return displayedAttributes;
}

MopConfiguration.prototype.hasModification = function() {
  const {mopAttributes, mopConfiguration} = this.props;
  let nbModifiedElts = MOP_ATTRIBUTE_ALL_TYPES.map(type => {
    let attributesForType = mopAttributes[type];
    if (attributesForType){
      return attributesForType.filter(attribute =>
        (attribute.valueChanged === true
          && attribute.newValue !== attribute.value)).length;
    }
    return 0;
  }).reduce((v1, v2) => v1 + v2);

  let stable = false;
  let refund = false;
  let nbo = false;

  if ( mopConfiguration.paymentClient ) {
    stable = mopConfiguration.paymentClient.isStable?mopConfiguration.paymentClient.isStable.valueChanged:false;
    refund = mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund?mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund.valueChanged:false;
    nbo = mopConfiguration.paymentClient.nboId?mopConfiguration.paymentClient.nboId.valueChanged:false;
  }

  return nbModifiedElts > 0 || stable || refund || nbo;
};

MopConfiguration.prototype.saveMeanOfPayment = function(){
  if (this.props.savingMopInProgress !== true){
    const {dispatch, selectedAppClientId, selectedChannelId, mopConfiguration, mopAttributes} = this.props;
    const modifiedAttributes = MOP_ATTRIBUTE_ALL_TYPES.map(type => {
      let attributesForType = mopAttributes[type];
      if (attributesForType){
        return attributesForType.filter(attribute =>
          (attribute.valueChanged === true
            && attribute.newValue !== attribute.value));
      }
      return [];
    });

    if(this.props.mopConfiguration.paymentClient.isStable.valueChanged) {
      modifiedAttributes.push({"attributeCategory": "PAYMENT_STATUS",
        "key": "STABLE",
        "newValue": this.props.mopConfiguration.paymentClient.isStable.newValue,
        "valueChanged": true,
        "valueType": "Boolean"});
    }

    if(this.props.mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund.valueChanged) {
      modifiedAttributes.push({"attributeCategory": "PAYMENT_STATUS",
        "key": "REFUND_BACKUP",
        "newValue": this.props.mopConfiguration.paymentClient.pcPaymentMethodsUsedToRefund.newValue,
        "valueChanged": true,
        "valueType": "String"});
    }

    if(this.props.mopConfiguration.paymentClient.nboId.valueChanged) {
      modifiedAttributes.push({"attributeCategory": "PAYMENT_STATUS",
        "key": "NBO",
        "newValue": this.props.mopConfiguration.paymentClient.nboId.newValue,
        "valueChanged": true,
        "valueType": "Integer"});
    }

    dispatch(actions.saveMeanOfPaymentAttributes(selectedAppClientId, selectedChannelId, mopConfiguration.pcctId, _.flatten(modifiedAttributes)));
  }
};

const mapStateToProps = state => {
  return {
    mopLoading: state.meanOfPayments.mopLoading,
    mopConfiguration: state.meanOfPayments.mopConfiguration,
    mopAttributes: selectors.attributesSelector(state),
    savingMopInProgress: state.meanOfPayments.savingMopInProgress,
    selectedAppClientId: state.appClient.selectedAppClientId,
    selectedChannelId: state.appClient.selectedChannelId
  }
}

export default connect(mapStateToProps)(MopConfiguration)
