import React, {Component} from 'react'
import {connect} from "react-redux"

import {actions} from 'modules/admin/mod-access'
import PropTypes from "prop-types"

class AccessManagementResult extends Component {

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.getAllRoles());
  }

  render() {
    const {content} = this.props;

    const adminChecked = this.props.isAdmin === undefined && this.props.content.isAdmin ? {checked: "checked"} : {}
    const notAdminChecked = this.props.isAdmin === undefined && !this.props.content.isAdmin ? {checked: "checked"} : {}

    return (

      <div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <span className={`flag-icon flag-icon-${content.country.toLowerCase()}`} />
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input className="input is-info" type="text" value={content.userId} readOnly />
                <span className="icon is-left">
                  <i className="fas fa-user" />
                </span>
              </p>
            </div>

            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input className="input is-info" type="text" value={content.name} readOnly />
                <span className="icon is-left">
                  <i className="fas fa-id-card" />
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label">Admin</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <input className="is-checkradio"
                       id="is-admin"
                       type="radio"
                       name="is-admin"
                       disabled={content.isAdminDisabled}
                       onClick={() => this.handleChangeAdmin("true")}
                       {...adminChecked}
                />
                <label htmlFor="is-admin">Yes</label>
                <input className="is-checkradio"
                       id="is-not-admin"
                       type="radio"
                       name="is-admin"
                       disabled={content.isAdminDisabled}
                       onClick={() => this.handleChangeAdmin("false")}
                       {...notAdminChecked}
                />
                <label htmlFor="is-not-admin">No</label>
              </div>
            </div>
          </div>
        </div>

        <div className={"admin-access-management"}>
          <div className="field is-horizontal">
            <div className="field-label" />
            <div className="field-body">
              <div className="field">
                <div className="control is-pulled-right">
                  <button className={`button is-primary${this.props.isSaving ? " is-loading" : ""}`}
                          disabled={this.props.isSaving}
                          onClick={this.handleSaveButton}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">Filter</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control">
                  <input className="is-checkradio"
                         id="have-all"
                         type="radio"
                         name="filter"
                         onClick={() => this.handleChangeFilter("all")}
                  />
                  <label htmlFor="have-all">All</label>
                  <input className="is-checkradio"
                         id="have-no-rights"
                         type="radio"
                         name="filter"
                         onClick={() => this.handleChangeFilter("no_rights")}
                  />
                  <label htmlFor="have-no-rights">Only merchants I don't have access</label>
                  <input className="is-checkradio"
                         id="have-rights"
                         type="radio"
                         name="filter"
                         onClick={() => this.handleChangeFilter("rights")}
                  />
                  <label htmlFor="have-rights">Only merchants I have access</label>
                </div>
              </div>
            </div>
          </div>

          <table className="table is-striped is-fullwidth">
            <thead>
            <tr>
              <th width="30%">Client</th>
              <th width="70%">Access</th>
            </tr>
            </thead>
            <tbody>
            {this.showAppclient()}
            </tbody>
          </table>

          <div className="field is-horizontal">
            <div className="field-label" />
            <div className="field-body">
              <div className="field">
                <div className="control is-pulled-right">
                  <button className={`button is-primary${this.props.isSaving ? " is-loading" : ""}`}
                          disabled={this.props.isSaving}
                          onClick={this.handleSaveButton}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
        );
  }

  handleChangeAdmin = (value) => {
    this.props.dispatch(actions.selectAdmin(value));
  };

  handleChangeFilter = (value) => {
    this.props.dispatch(actions.selectFilter(value));
  };

  handleChangeReadAccess = (name) => (event, index, value) => {
    this.props.dispatch(actions.selectRole(event.target.value, name));
  };

  handleSaveButton= () => {
    this.props.dispatch(actions.saveAccess(this.getSaveParameters(), this.props.content.userId));
  };
}

AccessManagementResult.prototype.showAppclient =  function () {
  const { content } = this.props;
  var showClient = false;

  return content.access.filter(x => x.appClientId !== "").map((item, index) => {

    switch (this.props.filter) {
      case "no_rights":
        showClient = (item.roleId == null);
        break;
      case "rights":
        showClient = (item.roleId != null);
        break;
      case "all":
      default:
        showClient = true;
        break;
    }

    if(showClient) {
      return (<tr key={"row_"+item.appClientId}>
        <td>{item.appClient}</td>
        <td>
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className={`select is-small is-fullwidth${!this.props.roles ? " is-loading" : ""}`}>
                    <select onChange={this.handleChangeReadAccess(item.appClientId)}
                            value={item.roleId !== null ? item.roleId : ""}
                            disabled={!item.isEditable}>
                      <option disabled value=""> -- Select an option -- </option>
                      {this.getAllRoles()}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>

      </tr>);
    }else
      return "";
  })
};

AccessManagementResult.prototype.getAllRoles = function () {

  const { roles } = this.props;
  if(roles != null)
    return roles.map(role =>
      <option value={role.rolesId} key={"client_" + role.rolesId}>
        {role.rolesDescription}
      </option>
    );
};

AccessManagementResult.prototype.getSaveParameters= function() {
  return {
    userId: this.props.content.userId,
    isAdmin: this.props.isAdmin,
    access: this.props.access,
  }
};

AccessManagementResult.propTypes = {
  content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    roles: state.UserDetail.roles,
    isAdmin: state.UserDetail.isAdmin,
    isSaving: state.UserDetail.isSaving,
    access: state.UserDetail.access,
    filter: state.UserDetail.filter,
    showClient: state.UserDetail.showClient,
  }
}

export default connect(mapStateToProps)(AccessManagementResult);
