import React, { Component } from 'react';
import { roleFilter }        from 'utils/SecurityTools';
import muiThemeable         from 'material-ui/styles/muiThemeable';
import Box from "react-bulma-components/lib/components/box"
import MenuItem             from 'material-ui/MenuItem';
import PersonAdd            from 'material-ui/svg-icons/social/person-add';
import ContentLink          from 'material-ui/svg-icons/content/link';
import Assignement          from 'material-ui/svg-icons/action/assignment';
import Cached               from 'material-ui/svg-icons/action/cached'
import PluginIcon           from 'material-ui/svg-icons/action/settings-input-svideo'
import { Link }                 from 'react-router-dom';
import * as RoutesURLConstant   from 'utils/RoutesURLConstant';
import {connect} from "react-redux";

const subTabs = [
  {
    name: 'Access',
    value: 'access',
    route: RoutesURLConstant.adminAccessURL,
    icon: <PersonAdd />,
    access: ['ROLE_WRITER', 'ROLE_SUPPORT', 'ROLE_ADMIN']
  },
  {
    name: 'Batch',
    value: 'batch',
    route: RoutesURLConstant.adminBatchURL,
    icon: <ContentLink />,
    access: ['ROLE_ADMIN']
  },
  {
    name: 'Log Levels',
    value: 'access',
    route: RoutesURLConstant.logsURL,
    icon: <Assignement />,
    access: ['ROLE_ADMIN']
  },
    {
    name: 'Cache Management',
    value: 'cache',
    route: RoutesURLConstant.cacheManagementURL,
    icon: <Cached />,
    access: ['ROLE_ADMIN']
  },
  {
    name: 'Plugin Management',
    value: 'plugin',
    route: RoutesURLConstant.pluginManagementURL,
    icon: <PluginIcon />,
    access: ['ROLE_ADMIN']
  }
];

class AdminMenu extends Component {

  render() {
    const headerSubTabs = this.buildSubTabs();
    return (<>
      <div style={{position: "relative"}}>
        <Box style={{position: "sticky", top: "60px"}}>
            {headerSubTabs}
        </Box>
      </div>
      </>
    );
  }
}

AdminMenu.prototype.buildSubTabs = function() {
  let headerSubTabs = [];
  var showMenu;

  subTabs.forEach((tab,index) => {

    showMenu = roleFilter(tab.access, this.props.collab.roles);

    if(showMenu) {
      headerSubTabs.push(
        <MenuItem primaryText={tab.name}
                  leftIcon={tab.icon}
                  value={tab.value}
                  onClick={tab.onClick}
                  style={{ maxWidth: 300}}
                  containerElement={
                    <Link to={tab.route}/>
                  }
        />
        )
    }
  });

  return headerSubTabs;

};


const mapStateToProps = (state) => {
  return {
    collab: state.collab.profile
  }
}

export default connect(mapStateToProps)(muiThemeable()(AdminMenu));
