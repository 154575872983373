import {fetchAPI} from "../utils/UrlHelper";
import * as ServicesURLConstant from "../utils/ServicesURLConstant";
import {
  changeLogLevelError,
  changeLogLevelRequest, changeLogLevelSuccess,
  expandLogSuccess,
  fetchAllError,
  fetchAllRequest,
  fetchAllSuccess
} from "../actions/LogActions";
import {createNotification} from "./NotificationApi";

//get all calss/package
export const getAllLogs = (loading = false) => {
  return dispatch => {
    dispatch(fetchAllRequest(loading))

    fetchAPI(ServicesURLConstant.LOGGERS_ENDPOINT)
      .then(response => response.json())
      .then(json => dispatch(fetchAllSuccess(json)))
      .catch(error => {
        dispatch(fetchAllError())
        dispatch(createNotification(error.message, 'danger'))
      })
  }
}

export const expandLog = (key) => {
  return dispatch => {
    dispatch(expandLogSuccess(key))
  }
}

export const changeLogLevel = (key, level, nested) => {
  return dispatch => {
    dispatch(changeLogLevelRequest())

    if(key === "ROOT" && level === ""){
      dispatch(createNotification("Cannot reset ROOT logger", 'info'))
      dispatch(changeLogLevelError())
      return
    }

    fetchAPI(`${ServicesURLConstant.LOGGERS_ENDPOINT}/${key}`, {
      method: "POST",
      body: JSON.stringify({
        configuredLevel: level,
      })
    })
      .then(response => {
        if(response.status >= 200 && response.status < 400){
          dispatch(changeLogLevelSuccess(key, level, nested)) &&
          dispatch(getAllLogs())
        }else {
          dispatch(changeLogLevelError()) &&
          dispatch(createNotification(`Received ${response.status} when updating logger`, 'danger'))
        }
      })
      .catch(error => {
        dispatch(changeLogLevelError())
        dispatch(createNotification(error.message, 'danger'))
      })
  }
}
