import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import _ from 'lodash';
import PropTypes from 'prop-types';

class RelaunchBatchWithParamPopin extends Component {
  render() {
    const { opened, onRequestClose, job } = this.props;
    if (job !== null) {
      const actions = [
        <FlatButton label="Cancel" primary={true} onClick={onRequestClose}/>,
        <FlatButton label="Relaunch" primary={true} keyboardFocused={true} onClick={this.submitRelaunchBatch}/>,
      ];
      return (
        <Dialog
          title={"Relaunch job : " + job.jobInstanceName}
          actions={actions}
          modal={false}
          open={opened}
          onRequestClose={onRequestClose}
        >
          Specify batch parameters : <br/>
          {this.getBatchParameters()}
        </Dialog>
      );
    }
    return null;
  }

  submitRelaunchBatch = () => {
    const { job } = this.props;
    if (job !== null && job.executionParameters !== null) {
      for (const arrayIndex in job.executionParameters) {
        const param = job.executionParameters[arrayIndex];
        if (param.value === null) {
          return;
        }
      }
      this.props.onSubmitRelaunch()
    }
  };

}

RelaunchBatchWithParamPopin.prototype.getBatchParameters = function () {
  const { job } = this.props;
  if (job !== null && job.executionParameters !== null) {
    return job.executionParameters.map(parameter => {
      if (parameter.type === "LONG") {
        return <TextField floatingLabelText={parameter.name} type={"tel"} style={{ width: 400 }}
                          errorText={_.isNumber(parameter.value) === false && 'Not a number'}
                          onChange={(event, value) => {
                            let intValue = _.isEmpty(value) ? null : _.parseInt(value);
                            if (isNaN(intValue)) {
                              intValue = 0;
                            }
                            parameter.value = intValue;
                            this.forceUpdate();
                          }} value={parameter.value} key={ "launchParam" + parameter.name }/>;
      }
      // default text.
      return <TextField floatingLabelText={parameter.name} style={{ width: 400 }}
                        errorText={_.isEmpty(parameter.value) === true && 'Required'}
                        onChange={(event, value) => { parameter.value = value; this.forceUpdate(); }}
                        value={parameter.value} key={ "launchParam" + parameter.name }/>;
    });
  }
  return null;
};

RelaunchBatchWithParamPopin.propTypes = {
  opened: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmitRelaunch: PropTypes.func.isRequired,
  job: PropTypes.object
};

export default RelaunchBatchWithParamPopin;