import React, {Component} from 'react'
import {connect} from 'react-redux'
import Tabs from "react-bulma-components/lib/components/tabs"
import {Field, Input, Textarea} from "react-bulma-components/lib/components/form"
import Columns from "react-bulma-components/lib/components/columns"
import Box from "react-bulma-components/lib/components/box"
import Control from "react-bulma-components/lib/components/form/components/control"
import Tag from "react-bulma-components/lib/components/tag"
import {labelsPerLogType, tagsPerLogType, json} from "../../../utils/Logs"
import * as PropTypes from "prop-types"
import LeftColumn from "./common/LeftColumn"
import Icon from "react-bulma-components/lib/components/icon";
import {setCurrentAction} from "../../../actions/TransactionActions";
import {formatWithMillis} from "../../../utils/DateTools";

const ActionTabs = (props) => {
  return <Tabs
    type={"boxed"}
    fullwidth={true}
    align={"centered"}
    style={{
      margin: "0 -1px",
    }}
  >
    {Object.keys(props.logs).map((action) => (
      <Tabs.Tab key={action}
                active={props.currentAction === action}
                onClick={() => props.display(action)}>{action}</Tabs.Tab>
    ))}
  </Tabs>
}

ActionTabs.propTypes = {
  logs: PropTypes.object,
  currentAction: PropTypes.string,
  display: PropTypes.func,
}

const LogField = (props) => {

  let valueBox = <Input placeholder="Value is null" disabled />

  if(!!props.log.value){
    const [isJson, object] = json(props.log.value);
    valueBox = isJson ?
      <Textarea className={"json"} value={object} rows={object.split(/\r\n|\r|\n/).length} readOnly/> :
      <Input value={object} readOnly/>
  }

  return <Field>
    <Field multiline kind="group">
      <Control>
        <Tag.Group gapless>
          <p className="has-icons-left has-icons-right">
                                  <span className="tag is-small">
                                    <Icon align={"left"}>
                                      <i className="fas fa-clock"/>
                                    </Icon>
                                    <span/>
                                    {formatWithMillis(props.log.created)}
                                  </span>
          </p>
          <Tag color="primary">{props.log.key}</Tag>
          <Tag color={tagsPerLogType[props.log.purpose]}>{labelsPerLogType[props.log.purpose]}</Tag>
          <Tag color="light">{props.log.id}</Tag>
        </Tag.Group>
      </Control>
    </Field>
    {valueBox}
  </Field>
}

LogField.propTypes = {log: PropTypes.object}

class ResultRowTransactionLogs extends Component {

  render() {
    const {transactionId, logs} = this.props

    return <tr>
        <td colSpan={99}>
          <Columns multiline={false}>
            <LeftColumn channel={logs.channel} context={logs.context} transactionId={transactionId}/>
            <Columns.Column>
              <ActionTabs logs={logs.logs} currentAction={this.props.currentAction} display={this.display}/>
              <Box style={{borderRadius: 0,}}>
                <div>
                  {this.props.currentAction === undefined ? null :
                    <div className={`action ${this.props.currentAction}`}>
                      {logs.logs[this.props.currentAction].map((log) => (
                        <LogField key={log.id} log={log}/>
                      ))}
                    </div>
                  }
                </div>
              </Box>
            </Columns.Column>
          </Columns>
        </td>
      </tr>
  }

  display = (action) => {
    this.props.dispatch(setCurrentAction(action))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    transactionId: ownProps.transactionId,
    currentAction: state.transactionDetails.currentAction,
  }
}

export default connect(mapStateToProps)(ResultRowTransactionLogs)
