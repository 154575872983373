import React, {Component} from 'react'
import {connect} from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable'
import RaisedButton from 'material-ui/RaisedButton'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import {
  actions as actionsPaymentListWidget,
  selectors,
} from 'modules/mod-paymentListTest'
import {actions as actionsClient} from 'modules/mod-client-application';
import FormPaymentListTest from './FormPaymentListTest.js'
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'brace/mode/json';
import _ from "lodash"

class PaymentListTest extends Component {

  constructor(props) {
    super(props)
    this.state = {
      openFormErrorDialog: false,
      cssUrl: window.localStorage.getItem('cssUrl') || window.OnePayConfiguration.serversURLConstant.FRONT_CUBE,
      messagesUrl: window.localStorage.getItem('messagesUrl') || window.OnePayConfiguration.serversURLConstant.TRANSLATE_CUBE,
    }
  }

  componentDidMount() {
    const {dispatch} = this.props
    dispatch(actionsClient.listAllApplicationClient())

    this.updateIframeData()
  }

  componentWillReceiveProps(nextProps) {
    // select first value by default.
    if (nextProps.selectedClient === null && !_.isEmpty(nextProps.clients)) {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListSelectClient(nextProps.clients[0].externalId))
    }

    if (nextProps.selectedChannel === null && !_.isEmpty(nextProps.channels)) {
      this.props.dispatch(actionsPaymentListWidget.getPaymentListSelectChannel('DEFAULT'))
    }
  }

  componentDidUpdate() {
    if (this.props.paymentListWidget.saved === true) {
      this.updateIframeData()
    }
  }

  render() {

    const style = this.props.showParams ? {} : {left: "-20px"}
    return (
      <div style={{
        display: 'flex', ...this.props.muiTheme.pageContainer,
        paddingLeft: "0px",
      }}>
        <div className="box is-radiusless is-marginless"
             style={{
               position: 'relative',
               display: this.props.showParams ? "unset" : "none",
             }}>
          <div class="tabs is-fullwidth is-centered">
            <ul>
              <li className={ this.props.methodForm === 'input' ? 'is-active' : ''} onClick={() => {this.chooseMethod('input')}}>
                <a href="#input" onClick={() => {this.chooseMethod('input')}}>
                  <span class="icon"><i class="fas fa-list" aria-hidden="true"></i></span>
                  <span>Input</span>
                </a>
              </li>
              <li className={ this.props.methodForm === 'json' ? 'is-active' : ''} onClick={() => {this.chooseMethod('json')}}>
                <a href="#json" onClick={() => {this.chooseMethod('json')}}>
                  <span class="icon"><i class="far fa-file" aria-hidden="true"></i></span>
                  <span>JSON</span>
                </a>
              </li>
            </ul>
          </div>
          { this.props.methodForm === 'input' &&
            <FormPaymentListTest></FormPaymentListTest>
          }
          { this.props.methodForm === 'json' &&
          <div style={{width: '500px'}} class="form-all-json">
            <Editor
              onChange={(json) => this.updateJSON(json)}
              value={this.props.paymentListWidget}
              mode={'code'}
            />
          </div>
          }
          
          <RaisedButton
            disabled={this.props.paymentListWidget.hasError}
            onClick={() => this.getPaymentList(this.props.paymentListWidget.typingCustomerData, this.props.paymentListWidget.typingPaymentData)}
            label="Save"
            primary={true}
          />
        </div>

        <div className="displayer" style={{width: "16px"}}>
          <a href="#show" className="button"
             onClick={this.changeShowParamsState}
             style={{
               bottom: "15px",
               position: "fixed",
               borderRadius: "0 35px 35px 0",
               borderLeft: "none",
               boxShadow: "2px 2px 3px rgba(10, 10, 10, 0.1), 1px 0px 0 0px rgba(10, 10, 10, 0.1)",
               zIndex: 100,
               ...style,
             }}>
            <span className="icon is-small">
              <i
                className={`fas fa-angle-double-${this.props.showParams ? "left" : "right"}`}/>
            </span>
          </a>
        </div>

        <Dialog
          title="Error"
          actions={<FlatButton label="Ok" primary={true} onClick={() => {
            this.setState({formErrorDialogOpened: false})
          }}/>}
          modal={false}
          open={this.state.formErrorDialogOpened}
          onRequestClose={() => {
            this.setState({formErrorDialogOpened: false})
          }}>

          Please fill in with correct type and make sure you fill in a customer

        </Dialog>

        <iframe title="widget-iframe" id="widget-iframe" style={{width: '100%', height: 1200, position: 'relative'}}>
        </iframe>
      </div>
    )
  }

  chooseMethod = (value) => {
    this.props.dispatch(actionsPaymentListWidget.changeMethodForm(value))
  }

  changeShowParamsState = () => {
    this.props.dispatch(actionsPaymentListWidget.changeShowParamsState())
  }

}


PaymentListTest.prototype.updateJSON = function (json) {
  const {dispatch} = this.props;
  if ( json.selectedClient ) {
    dispatch(actionsPaymentListWidget.updateJSON(
      json
    ))
  }
}

/********************************************************/
/* Create / update the widget iframe
/********************************************************/
PaymentListTest.prototype.updateIframeData = function () {
  const iframe = window.frames['widget-iframe']
  const iframeDocument = (iframe.contentDocument)
    ? iframe.contentDocument
    : iframe.contentWindow.document

  const iframeBody = iframeDocument.getElementsByTagName('body')[0]
  const iframeHead = iframeDocument.getElementsByTagName('head')[0]

  iframeBody.innerHTML = ''
  iframeHead.innerHTML = ''

  const stylesheet = iframeDocument.createElement('link')
  stylesheet.rel="stylesheet"
  stylesheet.href=window.localStorage.getItem('cssUrl') || window.OnePayConfiguration.serversURLConstant.FRONT_CUBE;
  iframeHead.appendChild(stylesheet)

  /* Translation script injection */
  const translates = iframeDocument.createElement('script')
  translates.src = window.localStorage.getItem('messagesUrl') || window.OnePayConfiguration.serversURLConstant.TRANSLATE_CUBE
  iframeHead.appendChild(translates)

  /* Bundle script injection */
  const bundle = iframeDocument.createElement('script')
  bundle.src = window.OnePayConfiguration.serversURLConstant.WIDGET_APP_URL
  iframeHead.appendChild(bundle)

  /* PaymentData injection */
  const paymentData = iframeDocument.createElement('script')
  let dataWidget = this.props.paymentListWidget.typingPaymentData;
  dataWidget.data =  this.getPaymentParameters();
  dataWidget.meanOfPaymentList = this.props.meanOfPaymentList;
  
  paymentData.textContent = `window.onePay = ${JSON.stringify(dataWidget)};`
  paymentData.type = 'text/javascript'
  iframeHead.appendChild(paymentData)
  iframe.contentWindow.onePay.notifier = this.getNotifier();

  /* PaymentData injection */
  const configuration = iframeDocument.createElement('script')
  configuration.textContent = `window.OnePayConfiguration = ${JSON.stringify(window.OnePayConfiguration)}`
  configuration.type = 'text/javascript'
  iframeHead.appendChild(configuration)

  const divWidget = iframeDocument.createElement('div')
  divWidget.class = "box is-radiusless"
  divWidget.id = "dkt-payment-kit"
  divWidget.style = "padding: 32px"
  iframeBody.appendChild(divWidget)

  /* Pay button */
  const button = iframeDocument.createElement('button')
  button.className = 'pay-button'
  button.style = "width: 85px; height:50px;"
  button.name = 'Pay'
  button.textContent = 'Pay'
  button.onclick = function () {
    iframe.contentWindow.onePay.processPayment()
  }
  iframeBody.appendChild(button)
}

PaymentListTest.prototype.intIsValid = function (x) {
  if (x === null || x.length === 0) {
    return true
  }
  let parsed = parseInt(x, 10)
  if (isNaN(parsed)) {
    return 0
  }
  return parsed
}

PaymentListTest.prototype.getPaymentList = function (customerData, paymentData) {
  if (this.validateForm()) {
    this.props.dispatch(actionsPaymentListWidget.getPaymentList(customerData, paymentData, this.getPaymentListParameters(), this.props.collab))
  }
}

PaymentListTest.prototype.validateForm = function () {
  const isValid = this.props.selectedClient !== null
    && this.intIsValid(this.props.orderAmount) !== 0 && this.intIsValid(this.props.version) !== 0
    && this.props.selectedChannel !== null

  if (!isValid) {
    this.openFormErrorDialog()
  }

  this.props.dispatch(actionsPaymentListWidget.changeShowParamsState())
  return isValid
}

PaymentListTest.prototype.openFormErrorDialog = function () {
  this.setState({formErrorDialogOpened: true})
}

/**
 * Builds the "data" part to inject the widget
 */
PaymentListTest.prototype.getPaymentParameters = function () {
  return {
    client_name: this.props.selectedClient,
    channel_type: this.props.selectedChannel,
    context_name: this.props.selectedContext,
    order_amount: this.props.orderAmount ? this.props.orderAmount : 0,
    order_id: "test",
    payment_version: 1,
  }
}

PaymentListTest.prototype.getNotifier = function () {
  return {
    success: message => console.log("success : " + message),
    error: message => console.log("error : " + message),
    preSubmit: () => new Promise((resolve, reject) => {
      console.log("Pre submit event fired")
      resolve("Ok")
    })
  }
}
  
/**
 * Builds the object for listPayment service call.
 */
PaymentListTest.prototype.getPaymentListParameters = function () {
  return {
    client_name: this.props.selectedClient,
    channel_type: this.props.selectedChannel,
    context_name: this.props.selectedContext,
    one_click: this.props.oneClick,
    order_amount: this.props.orderAmount ? this.props.orderAmount : 0,
    private_customer: this.props.privateCustomer,
    professional_customer: this.props.professionalCustomer,
    payment_id: this.props.paymentId,
    for_loyalty_customer: this.props.forLoyaltyCustomer,
    for_not_loyalty_customer: this.props.forNotLoyaltyCustomer,
    regular_delivery: this.props.regularDelivery,
    payment_on_delivery: this.props.paymentOnDelivery,
    version: this.props.version ? this.props.version : 1,
    type: this.props.type,
    headers: this.props.headers,
    showParams: this.props.showParams,
  }
}

const mapStateToProps = state => {
  return {
    clients: state.appClient.clients,
    channels: selectors.channelsSelector(state),
    contexts: selectors.contextsSelector(state),
    selectedClient: state.paymentListWidget.selectedClient,
    selectedChannel: state.paymentListWidget.selectedChannel,
    selectedContext: state.paymentListWidget.selectedContext,
    oneClick: state.paymentListWidget.oneClick,
    orderAmount: state.paymentListWidget.orderAmount,
    privateCustomer: state.paymentListWidget.privateCustomer,
    professionalCustomer: state.paymentListWidget.professionalCustomer,
    paymentId: state.paymentListWidget.paymentId,
    forLoyaltyCustomer: state.paymentListWidget.forLoyaltyCustomer,
    forNotLoyaltyCustomer: state.paymentListWidget.forNotLoyaltyCustomer,
    regularDelivery: state.paymentListWidget.regularDelivery,
    paymentOnDelivery: state.paymentListWidget.paymentOnDelivery,
    version: state.paymentListWidget.version,
    type: state.paymentListWidget.type,
    headers: state.paymentListWidget.headers,
    showParams: state.paymentListWidget.showParams,
    meanOfPaymentList: state.paymentListWidget.meanOfPaymentList,
    typingPaymentData: state.typingPaymentData,
    typingCustomerData: state.typingCustomerData,
    paymentListWidget: state.paymentListWidget,
    collab: state.collab.profile,
    methodForm: state.paymentListWidget.methodForm
  }
}

export default connect(mapStateToProps)(muiThemeable()(PaymentListTest))
