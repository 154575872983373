import moment from "moment"
import 'moment/min/locales'

export const formatWithMillis = (date) => {
  return moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss.SSS [GMT]Z')
}

export const formatForShort = (date) => {
  return date.format('DD/MM/YY HH:mm')
}
