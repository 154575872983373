import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import _                        from 'lodash';

import NavigateLeftIcon       from 'material-ui/svg-icons/navigation/chevron-left';
import NavigateRightIcon      from 'material-ui/svg-icons/navigation/chevron-right';
import IconButton             from 'material-ui/IconButton';
import SelectField            from 'material-ui/SelectField';
import MenuItem               from 'material-ui/MenuItem';

const AVAILABLE_ITEMS_PER_PAGE = [15, 30, 50, 100, 200];

/**
 * Simple pagination for material ui table.
 */
class TablePagination extends Component {
 
  render() {
    const iconSize = { width: 26, height: 26 };

    return (
      <div style={{display: 'flex', alignItems:'center'}}>
        <span className={'pagination-info'}>{this.getCurrentElementStart()}-{this.getCurrentElementEnd()} of {this.props.total}</span>
        <IconButton iconStyle={iconSize} className={'pagination-navigate-left'}
                    onClick={this.navigateLeft}
                    disabled={this.navigateLeftDisabled()}
                    style={{marginLeft:20}}>
          <NavigateLeftIcon/>
        </IconButton>

        <IconButton iconStyle={iconSize} className={'pagination-navigate-right'}
                    onClick={this.navigateRight}
                    disabled={this.navigateRightDisabled()}>
          <NavigateRightIcon/>
        </IconButton>

        <SelectField
          value={this.props.nbElementsPerPage}
          onChange={this.handleChangeElementsPerPage}
          floatingLabelText="Items per page" style={{marginLeft:20}}
        >
          {AVAILABLE_ITEMS_PER_PAGE.map(t => (<MenuItem key={t} value={t} primaryText={t} />))}
        </SelectField>
    </div>
    );
  }

  handleChangeElementsPerPage = (event, index, value) => {
    this.props.onPageUpdate(0, value);
  };

  navigateLeft = () => {
    let newPage = this.props.currentPage - 1;
    this.props.onPageUpdate(newPage, this.props.nbElementsPerPage);
  };

  navigateRight = () => {
    let newPage = this.props.currentPage + 1;
    this.props.onPageUpdate(newPage, this.props.nbElementsPerPage);
  };
}


TablePagination.prototype.getCurrentElementStart= function(){
  return (this.props.total === 0 ? 0 : (this.props.nbElementsPerPage * this.props.currentPage) + 1);
};

TablePagination.prototype.getCurrentElementEnd= function(){
  return _.min([this.props.nbElementsPerPage * (this.props.currentPage + 1), this.props.total]);
};

TablePagination.prototype.navigateLeftDisabled= function(){
  return this.props.currentPage <= 0;
};

TablePagination.prototype.navigateRightDisabled= function(){
  return this.props.nbElementsPerPage * (this.props.currentPage + 1) >= this.props.total;
};

TablePagination.propTypes = {
  total: PropTypes.number.isRequired,
  nbElementsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageUpdate: PropTypes.func.isRequired
}

export default TablePagination;