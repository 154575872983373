import React, {Component} from 'react';
import {connect}          from 'react-redux';
import {actions}          from 'modules/mod-mop-tree-form';
import MopTreeForm        from 'components/mop/MopTreeForm';

import {Modal} from "react-bulma-components/lib";
import ModalCard from "react-bulma-components/lib/components/modal/components/card";
import ModalCardHead from "react-bulma-components/lib/components/modal/components/card/head";
import ModalCardBody from "react-bulma-components/lib/components/modal/components/card/body";
import ModalCardFoot from "react-bulma-components/lib/components/modal/components/card/foot";


class ModalAddMopTree extends Component {

  render() {
    if(!this.props.modalState) {
      return null;
    }
    return(
      <Modal onClose={this.props.closeModal} 
          show={!!this.props.modalState}
          closeOnEsc closeOnBlur showClose>
        <ModalCard onClose={this.props.closeModal}
                  className="is-radiusless">
          <ModalCardHead 
            className={'has-background-info has-text-white is-size-4'}
            showClose onClose={this.props.closeModal}
                        style={{justifyContent: "space-between"}}>
            {this.props.client.id ? 'Update Client' : 'Create Client'}
          </ModalCardHead>
          <ModalCardBody>
            <MopTreeForm/>
          </ModalCardBody>
          <ModalCardFoot style={{justifyContent: "space-between"}}>
            <button className="button" onClick={this.props.closeModal}>
              <span className="icon is-small">
                <i className="fas fa-times-circle"></i>
              </span>
              <span>Cancel</span>
            </button>
            <button className={`button is-primary ${this.props.isLoading ? ' is-loading' : ''}`} 
              disabled={this.props.isLoading}
              type="submit" onClick={() => this.saveClient()}>
              <span className="icon is-small">
                <i className="fas fa-save"></i>
              </span>
              <span>{this.props.client.id ? 'Update' : 'Create'}</span>
            </button>
          </ModalCardFoot>
        </ModalCard>
      </Modal>
    );
  }

  saveClient = () => {
    const {dispatch, client} = this.props;
    dispatch(actions.saveClient(client));
  }

}

const mapStateToProps = state => {
  return {
    appClient: state.appClient,
    client: state.mopTreeForm.form,
    isLoading: state.mopTreeForm.isLoading
  }
}

export default connect(mapStateToProps)(ModalAddMopTree);