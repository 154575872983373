import * as ServicesURLConstant from 'utils/ServicesURLConstant';
import {fetchAPI} from 'utils/UrlHelper';
import {createNotification} from "./../api/NotificationApi";
import {actions as actionsClientApplication} from './mod-client-application';

import _ from 'lodash';
/***************************************************************/
/* Actions
/***************************************************************/
const MODAL_STATUS_ADD_ITEM = 'MODAL_STATUS_ADD_ITEM';
const SELECT_CLIENT_MOP_TREE_FORM = 'SELECT_CLIENT_MOP_TREE_FORM';
const UPDATE_CLIENT_MOP_TREE_FORM = 'UPDATE_CLIENT_MOP_TREE_FORM';
const UPDATE_CHANNEL_MOP_TREE_FORM = 'UPDATE_CHANNEL_MOP_TREE_FORM';
const UPDATE_CONTEXT_MOP_TREE_FORM = 'UPDATE_CONTEXT_MOP_TREE_FORM';
const SAVE_CLIENT_REQUEST = 'SAVE_CLIENT_REQUEST';
const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS';
const SAVE_CLIENT_FAILURE = 'SAVE_CLIENT_FAILURE';
const ADD_CHANNEL_MOP_TREE_FORM = 'ADD_CHANNEL_MOP_TREE_FORM';
const ADD_CONTEXT_MOP_TREE_FORM = 'ADD_CONTEXT_MOP_TREE_FORM';

function changeModalStatus() {
  return dispatch => {
    dispatch({
      type: MODAL_STATUS_ADD_ITEM
    });
  }
}

function selectClient(value) {
  return dispatch => {
    dispatch({
      type: SELECT_CLIENT_MOP_TREE_FORM,
      data: value
    });
  }
}

function addChannel() {
  return dispatch => {
    dispatch({
      type: ADD_CHANNEL_MOP_TREE_FORM
    });
  }
}

function addContext(channelIndex) {
  return dispatch => {
    dispatch({
      type: ADD_CONTEXT_MOP_TREE_FORM,
      data: channelIndex
    });
  }
}

function updateFormClient(name, value) {
  var data = {};
  data[name] = value;
  return dispatch => {
    dispatch({
      type: UPDATE_CLIENT_MOP_TREE_FORM,
      data
    });
  }
}

function updateFormContext(name, value, index, channelIndex) {
  var data = {};
  data[name] = value;
  return dispatch => {
    dispatch({
      type: UPDATE_CONTEXT_MOP_TREE_FORM,
      data : { data, index, channelIndex}
    });
  }
}

function updateFormChannel(name, value, index) {
  var data = {};
  data[name] = value;
  return dispatch => {
    dispatch({
      type: UPDATE_CHANNEL_MOP_TREE_FORM,
      data : { data, index}
    });
  }
}

function saveClient(client) {
  if ( client.id ) {
    return updateClient(client)
  }
  return dispatch => {
    dispatch({type: SAVE_CLIENT_REQUEST});
    fetchAPI(ServicesURLConstant.MOP_CLIENT, {
      method: "POST",
      body: JSON.stringify(client)
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(createNotification(response.statusText, 'danger'))
        }
        return response.json()
      })
      .then((responseJson) => {
        dispatch({
          type: SAVE_CLIENT_SUCCESS,
          data: responseJson.data
        });
        dispatch({
          type: MODAL_STATUS_ADD_ITEM
        });
        dispatch(actionsClientApplication.listApplicationClient())
      })
      .catch(error => {
        dispatch(createNotification(error.message, 'danger'))
        dispatch({type: SAVE_CLIENT_FAILURE});
      });
  }
}

function updateClient(client) {
  return dispatch => {
    dispatch({type: SAVE_CLIENT_REQUEST});
    fetchAPI(`${ServicesURLConstant.MOP_CLIENT}/${client.id}`, {
      method: "PATCH",
      body: JSON.stringify(client)
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(createNotification(response.statusText, 'danger'))
        }
        return response.json()
      })
      .then((responseJson) => {
        dispatch({
          type: SAVE_CLIENT_SUCCESS,
          data: responseJson.data
        });
        dispatch({
          type: MODAL_STATUS_ADD_ITEM
        });
        dispatch(actionsClientApplication.listApplicationClient())
      })
      .catch(error => {
        dispatch(createNotification(error.message, 'danger'))
        dispatch({type: SAVE_CLIENT_FAILURE});
      });
  }
}

export const actions = {
  selectClient: selectClient,
  updateFormClient: updateFormClient,
  updateFormChannel: updateFormChannel,
  updateFormContext: updateFormContext,
  saveClient: saveClient,
  changeModalStatus: changeModalStatus,
  addChannel: addChannel,
  addContext: addContext
}

/***************************************************************/
/* Reducer functions
/***************************************************************/


const initChannel = {
  id: null,
  type : null,
  description : null,
  foEnable : null,
  boDisplayOrder : null,
  default : null,
  contexts : []
}

const initContext = {
  id: null,
  type : null,
  description : null,
  foEnable : null,
  boDisplayOrder : null,
  default : null
}

const initForm = {
  id: null,
  description : null,
  externalId : null,
  externalIdIso : null,
  currency : null,
  channels : [],
  defaultChannelId : null
}

const initialState = {
  selectedClient: null,
  listChannels: [],
  listContexts: [],
  form: initForm,
  formClientValid : false,
  modalStatusAddItem: false,
  isLoading : false
}

export function mopTreeFormReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_CLIENT_REQUEST:
      return {...state, isLoading: true};
    case SELECT_CLIENT_MOP_TREE_FORM:
      let form = _.cloneDeep(initForm);
      if ( action.data ) {
        form = action.data;
      }
      return {...state, form, selectedClient: action.data };
    case UPDATE_CLIENT_MOP_TREE_FORM:
      const formClient = {...state.form};
      _.assign(formClient, action.data);
      return {...state, form : formClient}
    case UPDATE_CHANNEL_MOP_TREE_FORM:
      const updateChannelForm = {...state.form};
      _.assign(updateChannelForm.channels[action.data.index], action.data.data);
      return {...state, form: updateChannelForm}
    case UPDATE_CONTEXT_MOP_TREE_FORM:
      const updateContextForm = {...state.form};
      _.assign(updateContextForm.channels[action.data.channelIndex].contexts[action.data.index], action.data.data);
      return {...state, form: updateContextForm}
    case ADD_CHANNEL_MOP_TREE_FORM:
      const addChannelForm = {...state.form};
      let addChannel = _.cloneDeep(initChannel);
      if ( addChannelForm.channels.length === 0 ) {
        addChannel.default = true;
      }
      addChannelForm.channels.push(addChannel);
      return {...state, form : addChannelForm}
    case ADD_CONTEXT_MOP_TREE_FORM:
      const addContextForm = {...state.form};
      addContextForm.channels[action.data].contexts.push(_.cloneDeep(initContext));
      return {...state, form : addContextForm} 
    case MODAL_STATUS_ADD_ITEM:
      return {...state, modalStatusAddItem : !state.modalStatusAddItem, form: _.cloneDeep(initForm)}
    case SAVE_CLIENT_FAILURE:
      return {...state, isLoading: false};
    case SAVE_CLIENT_SUCCESS:
      return {...state, isLoading: false};
    default:
      return state;
  }
}
