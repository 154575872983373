import React, {PureComponent} from 'react'
import {connect} from "react-redux";
import {changeLogLevel, expandLog, getAllLogs} from "../../../api/LogApi";
import * as PropTypes from 'prop-types'
import {colorPerLogLevel, iconPerLogLevel} from "../../../utils/Logs";
import Confirmation from "../../shared/Confirmation";
import Box from "react-bulma-components/lib/components/box"

class LevelSteps extends PureComponent {

  state = {
    askingConfirmation: {},
  }

  render() {
    return <div className="steps is-small is-flex">
      {this.props.levels.map((level, index) => {

        const isIncluded = this.props.levels.indexOf(this.props.currentLevel) >= index
        const needsConfirmation = level === 'TRACE'

        return <React.Fragment key={`${level}-fragment`}>
          <Confirmation key={`${level}-confirmation`}
                        headerColor={"danger"}
                        headerText={"white"}
                        header={"Are you sure ?"}
                        content={"Setting level to TRACE will highly impact performance and the I/O operations."}
                        active={this.state.askingConfirmation[level]}
                        accept={() => this.confirm(level)}
                        cancel={() => this.closeConfirmation(level)}/>
          <div key={level}
               className={`is-clickable step-item${isIncluded ? ` is-completed is-${colorPerLogLevel[level]}` : ""}`}
               onClick={needsConfirmation ? () => this.showConfirmation(level) : () => this.props.onClick(level)}>
            <div className="step-marker">
            <span className="icon">
              <i className={`fa fa-${iconPerLogLevel[level]}`}/>
            </span>
            </div>
            <div className="step-details">
              <p className="step-title">{level}</p>
            </div>
          </div>
        </React.Fragment>
      })}
      <div key="reset"
           className={`step-item is-separated is-${this.props.resettable ? "clickable is-danger is-completed" : "not-clickable"}`}
           onClick={() => this.props.onClick("")}>
        <div className="step-marker">
          <span className="icon">
            <i className="fas fa-undo"/>
          </span>
        </div>
        <div className="step-details">
          <p className="step-title">RESET</p>
        </div>
      </div>
    </div>
  }

  showConfirmation = (level) => {
    const newConfirmation = Object.assign({}, this.state.askingConfirmation)
    newConfirmation[level] = true
    this.setState({askingConfirmation: newConfirmation})
  }

  closeConfirmation = (level) => {
    const newConfirmation = Object.assign({}, this.state.askingConfirmation)
    newConfirmation[level] = false
    this.setState({askingConfirmation: newConfirmation})
  }

  confirm = (level) => {
    this.props.onClick(level)
    this.closeConfirmation(level)
  }
}

LevelSteps.propTypes = {
  currentLevel: PropTypes.string.isRequired,
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
  resettable: PropTypes.bool.isRequired
}

class LoggerName extends PureComponent {
  render() {
    // eslint-disable-next-line
    return <a className={this.props.active ? " is-active" : ""}>
      <p className={`has-icon-left is-uppercase is-family-code is-size-7`}
         style={{
           paddingLeft: `${this.props.insetLevel}rem`,
         }}>
                        <span className="icon is-small is-left">
                          <i className={`fas fa-${this.props.icon}`}/>
                        </span>
        <span>
                          {this.props.name}
                        </span>
      </p>
    </a>
  }
}

LoggerName.propTypes = {
  active: PropTypes.bool,
  insetLevel: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

class Logs extends PureComponent {

  componentDidMount() {
    this.props.dispatch(getAllLogs(true))
  }

  render() {
    return(
      <Box paddingless radiusless>
        {this.props.loading ? <div className="pageloader is-active"><span className="title">Loading loggers...</span></div> : null}
        <table className="table is-striped is-hoverable is-fullwidth">
          <thead>
          <tr>
            <th>Name</th>
            <th style={{width: "30rem"}}>Level</th>
          </tr>
          </thead>
          <tbody>
          {this.iterate(this.props.log.loggers)}
          </tbody>
        </table>
      </Box>
    )
  }

  iterate(obj, currentKey = "", insetLevel = 0) {
    const current = []

    if (obj !== null &&
      typeof obj === 'object' &&
      (obj.constructor === Object || obj.constructor === Array)) {
      Object.keys(obj).forEach(key => {

          if (typeof obj[key] === 'object' && obj[key] !== null) {

            const nextKey = `${currentKey ? `${currentKey}.` : ""}${key}`
            const isLast = Object.keys(obj[key]).length < 3 ||
              (obj[key].isActive !== undefined && Object.keys(obj[key]).length < 4)
            const icon = isLast ? "dot-circle" : obj[key].isActive ? "chevron-down" : "chevron-right"

            current.push(<React.Fragment key={nextKey}>
                <tr className="">
                  <td className="is-clickable"
                      onClick={() => this.props.dispatch(expandLog(nextKey))}
                      style={{
                        verticalAlign: "middle",
                      }}>
                    <LoggerName active={obj[key].isActive}
                                insetLevel={insetLevel}
                                icon={icon}
                                name={nextKey}/>
                  </td>

                  <td>
                    <LevelSteps key={nextKey}
                                resettable={nextKey !== "ROOT"}
                                levels={this.props.log.levels}
                                currentLevel={obj[key].effectiveLevel}
                                onClick={(level) => this.props.dispatch(changeLogLevel(nextKey, level, obj[key]))}
                    />
                  </td>
                </tr>
                {
                  obj[key].isActive ?
                    this.iterate(obj[key], nextKey, insetLevel + 1) :
                    null
                }
              </React.Fragment>
            )
          }
        }
      )
      return current
    }
  }

}

const mapStateToProps = store => {
  return {
    log: store.log,
    loading: store.log.loaders.loading,
  }
}

export default connect(mapStateToProps)(Logs)
