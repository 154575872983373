import React              from 'react';
import { Switch, Route }  from 'react-router';

import * as RoutesURLConstant from 'utils/RoutesURLConstant';
import App                  from 'App';
import AdminMenu            from 'components/admin/AdminMenu'
import TransactionAnalyzer  from 'components/transactions/TransactionAnalyzer';
import Home                 from 'components/Home';
import PaymentListTest      from 'components/paymentListTest/PaymentListTest';
import Logs                 from 'components/admin/logs/Logs';
import BatchExecutions      from 'components/admin/batch/BatchExecutions';
import AccessManagement     from 'components/admin/access/AccessManagement';
import CacheManagement      from 'components/admin/cache/CacheManagement';
import PluginManagement     from 'components/admin/plugins/PluginManagement';
import { AuthConsumer } from '../utils/authProvider';

// default page template for Administration
const AdminLayout = ({component: Component, ...rest}) => {
  return (
    <PrivateRoute {...rest} component={matchProps => (
      <div className="is-flex">
        <AdminMenu />
        <div style={{width: "100%", marginLeft: 16}}>
          <Component {...matchProps} />
        </div>
      </div>
    )} />
  )
};

const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = (Component) => (props) => (
      <AuthConsumer>
          {({ isAuthenticated, signinRedirect }) => {
              if (!!Component && isAuthenticated()) {
                  return <div style={{backgroundColor: '#f9f9f9'}}>
                  <App  {...rest}/>
                  <Component {...props} />
                </div>;
              } else {
                  signinRedirect();
                  return <div id="root">
                  <div className="pageloader is-active">
                    <span className="title">Loading application...</span>
                  </div>
                </div>;
              }
          }}
      </AuthConsumer>
  );

  return <Route {...rest} render={renderFn(component)} />;
};

export default (
  <Switch>
    <PrivateRoute exact path={RoutesURLConstant.rootURL} component={Home}/>
    <PrivateRoute exact path={RoutesURLConstant.transactionAnalyserURL} component={TransactionAnalyzer} />
    <PrivateRoute exact path={RoutesURLConstant.widgetTestUrl} component={PaymentListTest} />
    <AdminLayout exact path={RoutesURLConstant.adminAccessURL} component={AccessManagement} />
    <AdminLayout exact path={RoutesURLConstant.adminBatchURL} component={BatchExecutions} />
    <AdminLayout exact path={RoutesURLConstant.logsURL} component={Logs} />
    <AdminLayout exact path={RoutesURLConstant.cacheManagementURL} component={CacheManagement} />
    <AdminLayout exact path={RoutesURLConstant.pluginManagementURL} component={PluginManagement} />
  </Switch>
);
