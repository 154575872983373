import React, {Component} from 'react'
import {connect} from 'react-redux'

import {actions as accessUser} from 'modules/admin/mod-access'
import AccessManagementResult from 'components/admin/access/AccessManagementResult'
import muiThemeable from "material-ui/styles/muiThemeable"
import Box from "react-bulma-components/lib/components/box"
import Icon from "react-bulma-components/lib/components/icon"
import Button from "react-bulma-components/lib/components/button"

class AccessManagement extends Component {

  render() {

    const {userData } = this.props;

    const userNotFound = userData && !userData.userFound && !userData.loading;
    const userFound = userData && userData.userFound && !userData.loading;

    const fieldColor = userNotFound ? "danger" : userFound ? "success" : "info"
    const fieldIcon = userNotFound ? "-times" : userFound ? "-check" : ""

    return (
        <Box>
          <form onSubmit={this.loadUser}>
            <div className="field has-addons" style={{justifyContent: "center"}}>
              <p className="control has-icons-left">
                <input className={`input is-${fieldColor}`}
                       name="user"
                       value={this.props.userId}
                       type="text"
                       placeholder="UID"

                       readOnly={userData && userData.loading}
                       disabled={userData && userData.loading}

                       onChange={this.handleChangeUserId}
                />
                {userNotFound ? <p className="help is-danger">User not found</p> : ""}

                <Icon align="left" color={fieldColor}>
                  <i className={`fas fa-user${fieldIcon}`}/>
                </Icon>
              </p>

              <div className="control">
                <Button submit
                        color="info"
                        onClick={this.loadUser}
                        disabled={userData && userData.loading}
                        loading={userData && userData.loading}
                >
                  <Icon>
                    <i className={`fas fa-search`}/>
                  </Icon>
                </Button>
              </div>
            </div>

          </form>
          <div className="section">
            {
              userData && userData.userFound && !userData.loading ?
                <AccessManagementResult content={userData.content} /> :
                ""
            }
          </div>
        </Box>
    );
  }

  loadUser = (event) => {
    event.preventDefault();
    this.props.dispatch(accessUser.getUserInformations(this.props.userId));
  };

  handleChangeUserId = (event) => {
    this.props.dispatch(accessUser.setUserId(event.target.value))
  };
}


const mapStateToProps = (state) => {
  return {
    userId: state.UserDetail.userId,
    userData: state.UserDetail.userData,
  }
}

export default connect(mapStateToProps)(muiThemeable()(AccessManagement));
