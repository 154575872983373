import React, {Component} from 'react';
import {connect} from 'react-redux';
import {List, ListItem} from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton/RaisedButton';
import {actions as actionsCacheManagement} from 'modules/admin/mod-cacheManagement';
import { Card, CardText, CardTitle } from 'material-ui/Card';
import Snackbar from 'material-ui/Snackbar';
import { styles }         from 'styles';

class CacheManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caches: [],
            checked: [],
            selectAll: false,
            failure: 0
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ failure: nextProps.failure });
    }

    componentWillMount() {
        const {dispatch} = this.props;
        dispatch(actionsCacheManagement.getCaches());
    }

    generateCaches = function (caches) {
        if (caches && caches.length !== 0) {
            var selectAll=[
                <ListItem
                    style={styles.selectAll}
                    key={"selectAll"}
                    role={undefined}
                    primaryText={"Select/Deselect All"}
                    leftIcon={<Checkbox
                        checked={this.state.selectAll}
                    />}
                    onClick={()=>this.handleSelectAll()}
                >
                </ListItem>]
            var listItems = caches.map(cache => (
                <ListItem
                    key={cache.name}
                    role={undefined}
                    primaryText={cache.name}
                    leftIcon={<Checkbox
                        checked={this.state.checked.indexOf(cache) !== -1}
                    />}
                    onClick={()=>this.handleToggle(cache)}
                >
                </ListItem>
            ))
            return (selectAll.concat(listItems))
        }
    }

    handleToggle(cache) {
        if(this.state.checked.indexOf(cache)!==-1){
            this.state.checked.splice(this.state.checked.indexOf(cache),1);
            this.setState({checked: this.state.checked, selectAll: false});
        }else{
            this.setState({checked: [...this.state.checked, cache]});
        }
    }

    handleSelectAll() {
        if(this.state.checked.length === this.props.caches.length){
            this.setState({checked: [],selectAll: false})
        }else{
            this.setState({checked: this.props.caches.slice(),selectAll: true})
            this.setState({checked: this.props.caches.slice()})
        }
    }

    clearCaches = (event) => {
        const {dispatch} = this.props;
        dispatch(actionsCacheManagement.clearCaches(this.state.checked));
        this.setState({open: this.state.failure === 0,checked: [],selectAll: false})
    };

    render() {
        return (
            <div>
              {this.props.loading ? <div className="pageloader is-active"><span className="title">Loading caches...</span></div> : null}
                <Card>
                    <CardTitle title="Cache Management"/>
                    <RaisedButton  disabled={this.state.checked.length === 0} onClick={this.clearCaches} primary={true} style={styles.clearCacheButton} label="Clear Caches"/>
                    <CardText>
                        <List>
                            {this.props.caches ? this.generateCaches(this.props.caches) : null}
                        </List>
                    </CardText>
                    <RaisedButton  disabled={this.state.checked.length === 0} onClick={this.clearCaches} primary={true} style={styles.clearCacheButton} label="Clear Caches"/>
                </Card>
                <Snackbar
                    open={this.state.failure === 0}
                    onRequestClose={()=>this.setState({failure: -1})}
                    message="Caches cleared"
                    autoHideDuration={4000}
                />
                <Snackbar
                    open={this.state.failure>0}
                    onRequestClose={()=>this.setState({failure: -1})}
                    message={this.props.failMessages[this.props.failure]?this.props.failMessages[this.props.failure]:""}
                    autoHideDuration={4000}
                />
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        caches: state.cache.caches,
        loading: state.cache.loading,
        failure: state.cache.failure,
        failMessages: ["no failure",state.cache.getMessage,state.cache.clearMessage]
    }
}

export default connect(mapStateToProps)(CacheManagement);
