import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import {findRoleByAppClient} from 'utils/SecurityTools';

import TransactionAnalyzerResultRow from 'components/transactions/TransactionAnalyzerResultRow';
import TransactionAnalyzerActionsBlock from 'components/transactions/TransactionAnalyzerActionsBlock';

import muiThemeable from 'material-ui/styles/muiThemeable';
import {Card, CardActions, CardText} from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import TablePagination from 'components/shared/TablePagination';
import Table from "react-bulma-components/lib/components/table"

import {actions as actionsAnalyzer} from 'modules/transaction/mod-transaction-analyzer-form';
import {selectedRows} from "../../api/TransactionApi";
import Modal from "react-bulma-components/lib/components/modal"

class TransactionAnalyzerResultList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      actionBlockOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.transactions !== this.props.transactions) {
      this.setState({
        actionBlockOpen: false,
      });
    }
  }

  render() {
    var asRoleWriter = findRoleByAppClient(this.props.collab, this.props.currentSearchFormValues.appClientId);

    return (
      <Card>
        <CardText>
          <Table>
            <thead>
            <tr>
              <th>
                <div className="field">
                  <input className={`is-checkradio is-info`}
                         id="selectAll"
                         type="checkbox"
                         name="selectAll"
                         disabled={this.props.transactions === null || this.props.transactions.length <= 0}
                         checked={this.props.selectedRowsTransaction.all}
                         onChange={this.handleSelectAllRow}/>
                  <label htmlFor="selectAll"/>
                </div>
              </th>
              <th>Order ID</th>
              <th>Transaction</th>
              <th>Status</th>
              <th>Date</th>
              <th>Payment</th>
              <th>Amount</th>
              <th>Notified</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>{this.getTransactionRows()}</tbody>
          </Table>
        </CardText>
        <CardActions>
          {this.hasTransactionToDisplay()
          &&
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <TablePagination total={this.props.transactionsCount} nbElementsPerPage={this.props.elementsPerPage}
                             currentPage={this.props.currentPage} onPageUpdate={this.onPageUpdate}/>
            <div>
              <FlatButton label="Export" onClick={this.handleExportBtn} disabled={this.props.exportLaunched}/>
              {asRoleWriter !== -1 &&
              <RaisedButton primary={true} label="Actions" onClick={this.handleOpenActionsBlock}
                            disabled={this.isSelectedRowsEmpty()}/>
              }

              <Modal closeOnEsc closeOnBlur showClose
                     show={this.state.actionBlockOpen}
                     onClose={this.handleCloseActionsBlock}
              >
                <TransactionAnalyzerActionsBlock handleCloseActionsBlock={this.handleCloseActionsBlock}/>
              </Modal>
            </div>
          </div>
          }
        </CardActions>
      </Card>
    );
  }

  handleOpenActionsBlock = (event) => {
    const { filterActions } = this.props;
    // Init action list
    this.props.dispatch(actionsAnalyzer.initReferenceDataAction(filterActions));

    // This prevents ghost click.
    event.preventDefault();
    this.setState({
      actionBlockOpen: true,
      actionBlockAnchorEl: event.currentTarget,
    });
  };
  handleCloseActionsBlock = () => {
    this.setState({
      actionBlockOpen: false,
    });
  };

  onPageUpdate = (page, itemsPerPage) => {
    this.props.dispatch(actionsAnalyzer.setCurrentPage(page, itemsPerPage));
  };

  handleSelectAllRow = () => {
    this.props.dispatch(actionsAnalyzer.selectRows())
  }

  handleExportBtn = () => {
    const {currentSearchFormValues} = this.props;
    const exportSearchFormValues = {
      ...currentSearchFormValues,
      selectedRowsTransactionId: selectedRows(this.props.selectedRowsTransaction)
    };
    this.props.dispatch(actionsAnalyzer.launchExport(exportSearchFormValues));
  }

  getTransactionRows = () => {
    const {transactionsLoading, transactions} = this.props;
    if (!transactionsLoading) {
      return transactions.map(transaction => <TransactionAnalyzerResultRow key={"transaction" + transaction.transactionDetailId} transaction={transaction}/>);
    }
  }

  hasTransactionToDisplay = () => {
    const {transactionsLoading, transactions} = this.props;
    return (!transactionsLoading && !_.isEmpty(transactions));
  }

  isSelectedRowsEmpty = () => {
    return !Object.keys(this.props.selectedRowsTransaction)
      .filter(key => key !== 'all')
      .map(key => this.props.selectedRowsTransaction[key])
      .reduce((previousValue, currentValue) => previousValue || currentValue, false);
  }
}





const mapStateToProps = state => {
  return {
    transactionsLoading: state.analyzer.form.transactionsLoading,
    transactions: state.analyzer.form.transactions,
    transactionsCount: state.analyzer.form.transactionsCount,
    currentPage: state.analyzer.form.currentPage,
    elementsPerPage: state.analyzer.form.elementsPerPage,
    selectedRowsTransaction: state.analyzer.form.selectedRowsTransaction,
    exportLaunched: state.analyzer.form.exportLaunched,
    currentSearchFormValues: state.analyzer.form.currentSearchFormValues,
    collab: state.collab.profile,
    filterActions: state.analyzer.form.filterActions,
  };
}

export default connect(mapStateToProps)(muiThemeable()(TransactionAnalyzerResultList));
