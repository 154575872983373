// in local, modify proxy in package.json
// be careful with gateway.zscaler proxy (we can directly modify BASE_SERVICE_URL with api server if needed)
export const BASE_SERVICE_URL = window.OnePayConfiguration.serversURLConstant.FRONT_BASE_PATH + '/api/v1'

// user info

export const BO_USER_INFO = BASE_SERVICE_URL + '/ajax/user/info'
export const BO_USER_LOGOUT = BASE_SERVICE_URL + '/ajax/logout'

// app
export function getUrlAppGetClientApplicationForUser(userId) {
  return BASE_SERVICE_URL + `/ajax/getClientApplicationsForUser?userId=${userId}`
}

export const GET_CLIENT_APP = BASE_SERVICE_URL + `/clients`


// payment methods controller
export function getMeansOfPaymentForClientAndChannelTypeUrl(clientId, channelTypeId) {
  return BASE_SERVICE_URL + `/ajax/clients/${clientId}/channels/${channelTypeId}/mops`
}

export function getMeanOfPaymentForClientAndChannelTypeUrl(clientId, channelTypeId, mopId) {
  return getMeansOfPaymentForClientAndChannelTypeUrl(clientId, channelTypeId) + `/${mopId}`
}

export function setMeansOfPaymentForClientAndChannelTypeUrl(clientId, channelTypeId) {
  return BASE_SERVICE_URL + `/save/clients/${clientId}/channels/${channelTypeId}/mops`
}

export function setMeanOfPaymentForClientAndChannelTypeUrl(clientId, channelTypeId, mopId) {
  return setMeansOfPaymentForClientAndChannelTypeUrl(clientId, channelTypeId) + `/${mopId}`
}

export const PAYMENT_METHOD_SAVE_PAYMENT_CLIENT_CHANNEL_TYPE = BASE_SERVICE_URL + '/ajax/paymentmethod/savePaymentClientChannelType'

export function contracts(clientId, paymentId) {
  return `${BASE_SERVICE_URL}/bo/clients/${clientId}/payments/${paymentId}/contracts`
}

export function contractsSupport(clientId, paymentId) {
  return `${BASE_SERVICE_URL}/support/clients/${clientId}/payments/${paymentId}/contracts`
}

export function paymentAttributes(clientId, channelId, paymentId) {
  return `${BASE_SERVICE_URL}/bo/clients/${clientId}/channels/${channelId}/payments/${paymentId}/attributes`
}

export function paymentAttributesSupport(clientId, channelId, paymentId) {
  return `${BASE_SERVICE_URL}/support/clients/${clientId}/channels/${channelId}/payments/${paymentId}/attributes`
}

// transaction analyzer
export function getUrlTransactionInitReferenceData(clientId) {
  return BASE_SERVICE_URL + `/ajax/transactionanalyser/initReferenceData?appClientId=${clientId}`
}

export function getUrlTransactionInitReferenceDataAction(clientId) {
  return BASE_SERVICE_URL + `/ajax/transactionanalyser/initReferenceDataAction/${clientId}`
}

export const TRANSACTIONS_SEARCH_TRANSACTIONS = BASE_SERVICE_URL + '/ajax/transactionanalyser/searchTransactions'
export const EXPORT_TRANSACTIONS_SEARCH_TRANSACTIONS = BASE_SERVICE_URL + '/ajax/transactionanalyser/exportSearchTransactions'
export const TRANSACTIONS_SAVE_FORCING_TRANSACTION = BASE_SERVICE_URL + '/save/transactionanalyser/registerForcingTransaction'
export const TRANSACTIONS_SAVE_RELAUNCH_NOTIF_TRANSACTION = BASE_SERVICE_URL + '/save/transactionanalyser/relaunchNotifTransaction'
export const TRANSACTIONS_SAVE_RELAUNCH_ACTION_TRANSACTION = BASE_SERVICE_URL + '/save/transactionanalyser/relaunchActionTransaction'

// transaction detail
export function getAllTransationByTransactionId(transactionId) {
  return BASE_SERVICE_URL + `/ajax/transactions/${transactionId}/transactionDetails`
}

export function getAllTransationByOrderId(orderId) {
  return BASE_SERVICE_URL + `/ajax/orders/${orderId}/transactionDetails`
}

export function getAllTransationLogs(transactionId) {
  return BASE_SERVICE_URL + `/ajax/transactions/${transactionId}/logs`
}

//log management
export const LOGGERS_ENDPOINT = BASE_SERVICE_URL + '/loggers'

// administration
export const ADMIN_BATCH_LAST_EXECUTIONS = BASE_SERVICE_URL + '/batch/executions'

export function getUrlBatchAdminExecuteJob(jobName) {
  return BASE_SERVICE_URL + `/batch/${jobName}/execute`
}

export function getUrlBatchAdminActivateJob(jobName) {
  return BASE_SERVICE_URL + `/batch/${jobName}/activate`
}

export function getUrlBatchAdminJobHistory(jobName, currentPage, nbElements) {
  return BASE_SERVICE_URL + `/batch/${jobName}/history?currentPage=${currentPage}&items=${nbElements}`
}

// Access Management
export const GET_ALL_ROLES = BASE_SERVICE_URL + '/bo/access/getAllRoles'

export function getUserInformations(userId) {
  return BASE_SERVICE_URL + `/bo/access/${userId}/infos`
}

export function setUserInformations(userId) {
  return BASE_SERVICE_URL + `/bo/access/${userId}/saveRoles`
}

export function getMeta() {
  return BASE_SERVICE_URL + '/info'
}

// Cache Management
export const GET_CACHES = BASE_SERVICE_URL + `/bo/caches`
export const CLEAR_CACHES = BASE_SERVICE_URL + `/bo/caches/clearCaches`

// Plugin Management
export const GET_PLUGINS_URL = BASE_SERVICE_URL + `/bo/plugins`

// parameters
export const APP_PARAMETERS_URL = BASE_SERVICE_URL + '/parameters'

// mop from client
export const MOP_CLIENT = BASE_SERVICE_URL + '/clients'
