import React, {PureComponent} from 'react'
import * as PropTypes from 'prop-types'
import Icon from "react-bulma-components/lib/components/icon"

export default class Text extends PureComponent {

  render() {

    const color = this.props.initialValue !== null && this.props.initialValue !== this.props.value ?
      "warning" :
      "info"

    return (
      <div className="control field is-compact">

        {
          !this.props.compact ?
            <label className="label has-text-centered is-small" htmlFor={this.props.id}>
              <span>{this.props.label}</span>
              {!!this.props.tooltip ?
                <Icon color="info"
                      align="right"
                      className="tooltip is-tooltip-info is-tooltip-multiline has-text-centered"
                      data-tooltip={this.props.tooltip}
                      style={{marginLeft: "0.5rem"}}>
                  <i className="fas fa-lg fa-question-circle"/>
                </Icon> :
                null
              }
            </label> :
            null
        }
        <p className={`control field is-compact has-icons-left${this.props.onClear ? " has-addons" : ""}`}>
          <input id={this.props.id}
                 className={`input is-small is-${color}`}
                 type="number"
                 placeholder={this.props.label}
                 onChange={this.props.onChange}
                 value={this.props.value}/>
          <Icon color={color} align="left">
            <i className="fas fa-list"/>
          </Icon>
          {
            this.props.onClear &&
            <div className="control">
              <a className="button is-small" href="#clear" onClick={this.props.onClear} style={{border: 0}}>
                <Icon>
                  <i className="fas fa-lg fa-times" style={{color: "#4a4a4a"}}/>
                </Icon>
              </a>
            </div>
          }
        </p>
        {
          this.props.error !== undefined ?
            <p className="help is-danger">{this.props.error}</p> :
            null
        }
      </div>
    )
  }
}

Text.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  initialValue: PropTypes.string,
  error: PropTypes.string,
  tooltip: PropTypes.string,
  compact: PropTypes.bool.isRequired,
  onClear: PropTypes.func,
}
