import React, { Component } from 'react';
import Moment               from 'react-moment';

import FlatButton         from 'material-ui/FlatButton';
import Badge              from 'material-ui/Badge';
import IconButton         from 'material-ui/IconButton';
import Dialog             from 'material-ui/Dialog';
import InfoIcon           from 'material-ui/svg-icons/action/info-outline';

import muiThemeable       from 'material-ui/styles/muiThemeable';

class BatchExecutionStepRow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      exitMessageDialogOpen: false
    };
  }

  render() {
    const {stepExecution} = this.props;
    const actions = [ <FlatButton label="Close" primary={true} keyboardFocused={true} onClick={this.handleDialogClose} /> ];

    return (
      <tr key={"stepExecution" + stepExecution.id}>
        <td>{stepExecution.stepName} </td>
        <td>{stepExecution.status}</td>
        <td>
          {(stepExecution.exitMessage === null || stepExecution.exitMessage === "") ? stepExecution.exitStatus :
            <div>
              <Badge badgeContent={<IconButton iconStyle={{ width: 20, height: 20 }} onClick={this.handleExitMessageOpen}><InfoIcon/></IconButton>}>
                {stepExecution.exitStatus}
              </Badge>
              <Dialog
                title="Exit message"
                actions={actions}
                modal={false}
                open={this.state.exitMessageDialogOpen}
                onRequestClose={this.handleDialogClose}
                contentStyle={{ width: '99%', maxWidth: 'none', whiteSpace: 'pre-wrap'}}>
                {stepExecution.exitMessage}
              </Dialog>
            </div>
          }
        </td>
        <td>{stepExecution.readCount} / {stepExecution.readSkipCount}</td>
        <td>{stepExecution.writeCount} / {stepExecution.writeSkipCount}</td>
        <td>{stepExecution.processSkipCount}</td>
        <td>{stepExecution.filterCount}</td>
        <td>{stepExecution.commitCount}</td>
        <td>{stepExecution.rollbackCount}</td>
        <td>{stepExecution.startTime && <Moment format="YYYY/MM/DD HH:mm:ss">{stepExecution.startTime}</Moment>}</td>
        <td>{stepExecution.startTime
                && stepExecution.endTime
                && <span><Moment decimal unit="seconds" diff={stepExecution.startTime}>{stepExecution.endTime}</Moment> seconds</span>}
        </td>
      </tr>
    );
  }

  handleExitMessageOpen = () => { this.setState({exitMessageDialogOpen: true}); };
  handleDialogClose = () => { this.setState({exitMessageDialogOpen: false}); };
}

export default muiThemeable()(BatchExecutionStepRow);
