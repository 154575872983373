/***************************************************************/
/* Actions
/***************************************************************/
const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
const HIDE_MESSAGE = 'HIDE_MESSAGE';

function displayMessage(data) {
  return dispatch => {
    dispatch({
      type: DISPLAY_MESSAGE,
      data: data
    });
  };
}

export const actions = {
  displayMessage: displayMessage
};

/***************************************************************/
/* Reducer functions
/***************************************************************/

export function messageReducer(state = {}, action) {
  switch (action.type) {
  case DISPLAY_MESSAGE:
    return {...state, ...action.data};
  case HIDE_MESSAGE:
    return {...state, text: null, type: null};
  default:
    return state;
  }
}
