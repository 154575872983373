import { redirectToAuthForm }       from 'utils/SecurityTools';

const EQUAL_SEPARATOR = '=';
const AND_SEPARATOR = '&';

/**
 * Fetch an api endpoint, with provided Authorization header, for authentication.
 *
 * @param url the url to fetch
 * @param options options for fetch, array, or might be undefined.
 * @returns Promise for fetch
 */
export function fetchAPI(url, options) {
  let authToken = sessionStorage.getItem(`oidc.user:token:${window.OnePayConfiguration.serversURLConstant.IDP_URL}:${window.OnePayConfiguration.idpData.CLIENT_ID}`);

  const customHeaders = options && options.headers ? options.headers : {};

  let headers = {
    ...customHeaders,
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };
  if (authToken !== undefined){
    headers = {
      ...headers,
      'Authorization': 'Bearer ' + authToken
    }
  } else {
    redirectToAuthForm(window);
  }
  let fetchOptions = {...options, headers};
  return fetch(url, fetchOptions);
}

/**
 * Get Query Parameter by name from callback hash returned by FEDID.
 *
 * @param name query parameter
 * @param hash string to retrieve the query parameter from.
 */
export function getQueryParameterByName(name, hash) {
  return hash.substr(hash.indexOf(name + EQUAL_SEPARATOR)).split(AND_SEPARATOR)[0].split(EQUAL_SEPARATOR)[1];
}

/**
 * Construct query parameters to be passed to API PROXY URL
 * @param queryParams query parameters used to build the URL
 */
export function buildQueryParameterUrl(queryParams) {
  let url = Object.keys(queryParams)
  .map(function(key) {
    return [key, queryParams[key]].join(EQUAL_SEPARATOR);
  })
  .join(AND_SEPARATOR);
  return url;
}


/**
 * Rework the url to replace & char in order to be url compatible
 */
export function encodeURL(url) {
  return url.split('&').join('%26');
}
