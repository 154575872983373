import * as ServicesURLConstant   from 'utils/ServicesURLConstant'
import { fetchAPI }               from 'utils/UrlHelper'
import _                  from 'lodash'

const BATCH_LAST_EXECUTION_REQUEST = 'BATCH_LAST_EXECUTION_REQUEST'
const BATCH_LAST_EXECUTION_SUCCESS = 'BATCH_LAST_EXECUTION_SUCCESS'
const BATCH_LAST_EXECUTION_FAILURE = 'BATCH_LAST_EXECUTION_FAILURE'

export function getBatchLastExecutions() {
  return dispatch => {
    dispatch({ type: BATCH_LAST_EXECUTION_REQUEST });

    fetchAPI(ServicesURLConstant.ADMIN_BATCH_LAST_EXECUTIONS)
    .then((response) => response.json())
    .then((responseJson) => {
      dispatch({
        type: BATCH_LAST_EXECUTION_SUCCESS,
        data: responseJson
      });
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
      dispatch({ type: BATCH_LAST_EXECUTION_FAILURE });
    });

  }
}

const BATCH_RELAUNCH_JOB_REQUEST = 'BATCH_RELAUNCH_JOB_REQUEST';
const BATCH_RELAUNCH_JOB_SUCCESS = 'BATCH_RELAUNCH_JOB_SUCCESS';
const BATCH_RELAUNCH_JOB_FAILURE = 'BATCH_RELAUNCH_JOB_FAILURE';

export function relaunchBatch(jobName, executionParameters){
  let body = executionParameters ? JSON.stringify(executionParameters) : null;
  return dispatch => {
    dispatch({ type: BATCH_RELAUNCH_JOB_REQUEST });

    fetchAPI(ServicesURLConstant.getUrlBatchAdminExecuteJob(jobName), {method:"POST", body})
    .then(() =>   {
      dispatch({
        type: BATCH_RELAUNCH_JOB_SUCCESS
      });
      dispatch(getBatchLastExecutions());
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
      dispatch({ type: BATCH_RELAUNCH_JOB_FAILURE });
    }) ;
  }
}


export function relaunchBatchWithParameters(job){
  return relaunchBatch(job.jobInstanceName, job.executionParameters);
}

const BATCH_ACTIVATE_JOB_REQUEST = 'BATCH_ACTIVATE_JOB_REQUEST';
const BATCH_ACTIVATE_JOB_SUCCESS = 'BATCH_ACTIVATE_JOB_SUCCESS';
const BATCH_ACTIVATE_JOB_FAILURE = 'BATCH_ACTIVATE_JOB_FAILURE';
export function activateBatch(jobName, isActivated){
  return dispatch => {
    dispatch({
      type: BATCH_ACTIVATE_JOB_REQUEST,
      data: {jobName, isActivated}
    });
    fetchAPI(ServicesURLConstant.getUrlBatchAdminActivateJob(jobName), {method:"PUT", body:JSON.stringify(isActivated)})
    .then(() =>   {
      dispatch({
        type: BATCH_ACTIVATE_JOB_SUCCESS
      });
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
      dispatch({ type: BATCH_ACTIVATE_JOB_FAILURE });
    }) ;
  }
}

const BATCH_HISTORY_CLEAR = 'BATCH_HISTORY_CLEAR';

export function clearHistory(jobInstance){
  return dispatch => {
    dispatch({
      type: BATCH_HISTORY_CLEAR,
      data: jobInstance
    });
  }
}

const BATCH_LOAD_EXECUTION_HISTORY_REQUEST = 'BATCH_LOAD_EXECUTION_HISTORY_REQUEST';
const BATCH_LOAD_EXECUTION_HISTORY_SUCCESS = 'BATCH_LOAD_EXECUTION_HISTORY_SUCCESS';
const BATCH_LOAD_EXECUTION_HISTORY_FAILURE = 'BATCH_LOAD_EXECUTION_HISTORY_FAILURE';

export function loadHistory(jobInstance, startIndex, nbElements){
  return dispatch => {
    dispatch({ type: BATCH_LOAD_EXECUTION_HISTORY_REQUEST });

    fetchAPI(ServicesURLConstant.getUrlBatchAdminJobHistory(jobInstance, startIndex, nbElements))
    .then((response) => response.json())
    .then((responseJson) => {
      dispatch({
        type: BATCH_LOAD_EXECUTION_HISTORY_SUCCESS,
        data: {
          jobName : jobInstance,
          history : responseJson
        }
      });
    })
    .catch(error => {
      dispatch({
        type: 'DISPLAY_MESSAGE',
        data: {
          text: error.message,
          type: 'error'
        }
      });
      dispatch({ type: BATCH_LOAD_EXECUTION_HISTORY_FAILURE });
    });

  }
}


const initialState = {
  jobExecutions: [],
  jobExecutionsHistory: new Map(),
  isJobExecutionsLoading: true
};

export function batchReducer(state = initialState, action) {
  switch (action.type) {
  case BATCH_LAST_EXECUTION_REQUEST:
  case BATCH_RELAUNCH_JOB_REQUEST: {
    return { ...state, isJobExecutionsLoading: true };
  }
  case BATCH_LAST_EXECUTION_SUCCESS: {
    return { ...state, isJobExecutionsLoading: false, jobExecutions: action.data };
  }
  case BATCH_LAST_EXECUTION_FAILURE:
  case BATCH_RELAUNCH_JOB_SUCCESS:
  case BATCH_RELAUNCH_JOB_FAILURE: {
    return { ...state, isJobExecutionsLoading: false, jobExecutions: [] };
  }
    case BATCH_ACTIVATE_JOB_REQUEST:

    let jobExecutions = _.cloneDeep(state.jobExecutions).map(jobExecution => {
      if (jobExecution.job.jobInstanceName === action.data.jobName) {
        jobExecution.enabled = action.data.isActivated;
      }
      return jobExecution;
    });

    return { ...state, jobExecutions };
  case BATCH_HISTORY_CLEAR:
    let newHistory = new Map(state.jobExecutionsHistory);
    newHistory.delete(action.data);
    return { ...state, jobExecutionsHistory : newHistory };
  case BATCH_LOAD_EXECUTION_HISTORY_SUCCESS:
    newHistory = new Map(state.jobExecutionsHistory);
    newHistory.set(action.data.jobName, action.data.history);
    return { ...state, jobExecutionsHistory : newHistory };
  default:
    return state;
  }
}
