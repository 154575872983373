import React, {Component} from 'react'
import _ from 'lodash'
import "react-datepicker/dist/react-datepicker.css"
import {actions as actionsClient} from 'modules/mod-client-application'
import {actions as actionsAnalyzer} from 'modules/transaction/mod-transaction-analyzer-form'
import SelectFilter from "./filter/select/SelectFilter"
import ButtonGroup from "react-bulma-components/lib/components/button/components/button-group"
import Button from "react-bulma-components/lib/components/button"
import Box from "react-bulma-components/lib/components/box"
import {createNotification} from "../../api/NotificationApi"
import {selectors as analyzerSelectors} from "../../modules/transaction/mod-transaction-analyzer-form"
import {connect} from "react-redux"
import * as ParameterConstant from "../../utils/ParameterConstant"
import {IntervalFilter} from "./filter/interval/IntervalFilter"
import * as PropTypes from "prop-types"
import Icon from "react-bulma-components/lib/components/icon"
import Columns from "react-bulma-components/lib/components/columns"
import Column from "react-bulma-components/lib/components/columns/components/column"
import MultiSelectFilter, {MultiSelectEntry} from "./filter/select/MultiSelectFilter"
import moment from "moment"

class MiscRelativeFilters extends Component {
  render() {
    return <Column className="is-hidden-mobile is-4-tablet is-3-desktop">
      <Columns className={"misc-relative-filters"}>
        {/* Status */}
        <Column size={12}>
          <MultiSelectFilter size={7.5}
                             label={
                               /* Last status */
                               <>
                                 <Icon align={"left"} color={this.props.statusNumber > 1 ? "success" : "info"}>
                                   <i className={`fas fa-flag`}/>
                                 </Icon>
                                 <label>Status</label>
                                 <span className="field has-text-right is-marginless" style={{order: 1, flexGrow: 1}}>
                                <input id="lastStatus" type="checkbox" name="lastStatus"
                                       className="switch is-rounded is-info is-small"
                                       checked={this.props.checked}
                                       onChange={this.props.onChange1}
                                />
                                <label htmlFor="lastStatus">Only last status</label>
                                </span>
                               </>
                             }
                             entries={this.props.statuses}/>
        </Column>

        <Column size={12}>
          <SelectFilter onChange={this.props.onChange2}
                        label={"Notified"}
                        style={{height: "unset"}}
                        value={this.props.notified}
                        options={this.props.notifiedOptions}/>
        </Column>
      </Columns>
    </Column>
  }
}

MiscRelativeFilters.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  onChange1: PropTypes.func,
  statuses: PropTypes.arrayOf(PropTypes.any),
  onChange2: PropTypes.func,
  notified: PropTypes.string,
  notifiedOptions: PropTypes.any,

  statusNumber: PropTypes.number,
}

class ClientRelativeFilters extends Component {
  render() {
    return <Column className="is-hidden-mobile is-4">
      <Columns className={"non-mandatory-filters"}>

        <Column size={12}>
          <Columns className="client-relative-filters is-mobile">
            {/* Channel */}
            <Column>
              <MultiSelectFilter label={"Channels"}
                                 icon={"store"}
                                 entries={this.props.channels}/>
            </Column>
            {/* Contexts */}
            <Column className="is-hidden-touch">
              <MultiSelectFilter label={"Contexts"}
                                 icon={"shopping-cart"}
                                 entries={this.props.contexts}/>
            </Column>
          </Columns>
        </Column>


        <Column size={12}>
          <Columns className="payment-relative-filters is-mobile">
            {/* PSP */}
            <Column>
              <MultiSelectFilter label={"PSP"}
                                 icon={"building"}
                                 entries={this.props.psp}/>
            </Column>
            {/* Payment */}
            <Column className="is-hidden-touch">
              <MultiSelectFilter label={"Payment"}
                                 icon={"cash-register"}
                                 entries={this.props.payments}/>
            </Column>
          </Columns>
        </Column>
      </Columns>
    </Column>
  }
}

ClientRelativeFilters.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.any),
  contexts: PropTypes.arrayOf(PropTypes.any),
  psp: PropTypes.arrayOf(PropTypes.any),
  payments: PropTypes.arrayOf(PropTypes.any),
}

class MandatoryTransactionFilters extends Component {
  render() {
    return <Column className="is-12-mobile is-3-desktop">
      <Columns className={"mandatory-filters"} multiline>

        {/* Customer */}
        <Column size={12}>
          <SelectFilter onChange={this.props.onChange}
                        label={"Customer"}
                        icon={"globe"}
                        style={{height: "unset"}}
                        options={this.props.clients}
          />
        </Column>

        {/* From */}
        <Column size={12}>
          <Columns>
            <Column>
              <IntervalFilter
                from={{
                  selected: this.props.selected,
                  onSelect: this.props.onSelect,
                  maxDate: this.props.maxDate,
                }}
                to={{
                  selected: this.props.selected1,
                  onSelect: this.props.onSelect1,
                  minDate: this.props.minDate,
                  maxDate: this.props.maxDate1,
                }}/>
            </Column>

            <Column size={12}>
              <Columns style={{flexWrap: "wrap", lineHeight: "1"}}>
                <Column className={"is-paddingless"} style={{margin: "1px"}}>
                  <Button type={"button"} fullwidth rounded color={"primary"}
                          onClick={this.props.onClick} size={"small"}>This month</Button>
                </Column>
                <Column className={"is-paddingless"} style={{margin: "1px"}}>
                  <Button type={"button"} fullwidth rounded color={"info"}
                          onClick={this.props.setPastSevenDays} size={"small"}>7 days</Button>
                </Column>
                <Column className={"is-paddingless"} style={{margin: "1px"}}>
                  <Button type={"button"} fullwidth rounded color={"primary"}
                          onClick={this.props.onClick1} size={"small"}>This week</Button>
                </Column>
                <Column className={"is-paddingless"} style={{margin: "1px"}}>
                  <Button type={"button"} fullwidth rounded color={"info"}
                          onClick={this.props.onClick2} size={"small"}>Today</Button>
                </Column>
                <Column className={"is-paddingless"} style={{margin: "1px"}}>
                  <Button type={"button"} fullwidth rounded color={"primary"}
                          onClick={this.props.onClick3} size={"small"}>Last hour</Button>
                </Column>
              </Columns>
            </Column>
          </Columns>
        </Column>

        <Column size={12}>
          <Columns className={"unique-selector-filters"}>

            {/* Transaction ID */}
            <Column kind={"child"}>
              <div className="field has-addons">

            <span className="control" style={{minWidth: "60px", width: "100%"}}>
              <label className="label is-small has-text-centered" htmlFor="transaction">#Transaction</label>
              <p className="control has-icons-left">
              <input className="input is-small"
                     placeholder="123456789"
                     id="transaction"
                     type={"tel"}
                     onChange={this.props.onChange1}
                     value={this.props.value}/>
              <Icon color={this.props.value ? "success" : null} align="left">
                <i className="fas fa-crosshairs"/>
              </Icon>
              </p>
            </span>

                <span className="control" style={{minWidth: "60px", width: "100%"}}>
              <label className="label is-small has-text-centered" htmlFor="order">#Order</label>
              <p className="control has-icons-left">
              <input className="input is-small"
                     placeholder="DCDE1234567"
                     id="order"
                     onChange={this.props.onChange2}
                     value={this.props.value1}/>
              <Icon color={this.props.value1 ? "success" : null} align="left">
                <i className="fas fa-list"/>
              </Icon>
              </p>
            </span>

              </div>
            </Column>
          </Columns>
        </Column>
      </Columns>
    </Column>
  }
}

MandatoryTransactionFilters.propTypes = {
  onChange: PropTypes.func,
  clients: PropTypes.any,
  selected: PropTypes.any,
  onSelect: PropTypes.func,
  maxDate: PropTypes.any,
  selected1: PropTypes.any,
  onSelect1: PropTypes.func,
  minDate: PropTypes.any,
  maxDate1: PropTypes.any,
  onClick: PropTypes.func,
  onClick1: PropTypes.func,
  setPastSevenDays: PropTypes.func,
  onClick2: PropTypes.func,
  onClick3: PropTypes.func,
  onChange1: PropTypes.func,
  value: PropTypes.any,
  onChange2: PropTypes.func,
  value1: PropTypes.string,
}

class TransactionAnalyzerForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formErrorDialogOpened: false,
      fromDateMaxDate: moment(),
      toDateMaxDate: moment(),
      toDateMinDate: null,
    }
  }

  componentDidMount() {
    const {dispatch} = this.props
    dispatch(actionsClient.listApplicationClient())
  }

  componentWillReceiveProps(nextProps) {
    // select first value by default.
    if (nextProps.selectedClient === null && !_.isEmpty(nextProps.clients)) {
      this.props.dispatch(actionsAnalyzer.selectClient(nextProps.clients[0].id))
    }

    // page has changed, launch search with new page.
    if (nextProps.currentPage !== this.props.currentPage
      || (nextProps.elementsPerPage !== this.props.elementsPerPage)) {
      const {currentSearchFormValues} = nextProps
      const newSearchFormValues = {
        ...currentSearchFormValues,
        currentPage: nextProps.currentPage,
        firstResultIndex: nextProps.currentPage * nextProps.elementsPerPage,
        maximumResults: nextProps.elementsPerPage,
      }

      this.props.dispatch(actionsAnalyzer.setNewSearchFormValues(newSearchFormValues))
    }
  }

  getClients = () => {
    const {clients} = this.props
    return clients.map(client =>
      <option key={"client" + client.id} value={client.id}>
        {client.description}
      </option>,
    )
  }

  getChannels = () => {
    const {channels} = this.props
    return channels.map(channel => {
        const selected = this.props.selectedChannels.includes(channel.id)
        return <MultiSelectEntry key={`channel-${channel.description}-${channel.id}`}
                                 id={`channel-${channel.description}-${channel.id}`}
                                 selected={selected}
                                 label={channel.description}
                                 onChange={(e) => this.handleChangeChannel(e, [channel.id])}
        />
      },
    )
  }

  getContexts = () => {
    const {contexts} = this.props
    const contextItems = []
    contexts.forEach(channel => {
      const allSubsetId = channel.contexts.map(context => context.id)

      const selected = _.difference(allSubsetId, this.props.selectedContexts).length === 0
      if (allSubsetId.length > 0) {
        contextItems.push(
          <MultiSelectEntry key={`context-${channel.description}-${channel.id}`}
                            id={`context-${channel.description}-${channel.id}`}
                            selected={selected}
                            label={channel.description}
                            onChange={(e) => this.handleChangeContext(e, allSubsetId, true)}
          />,
        )
      }

      channel.contexts.forEach(context => {
        const selected = this.props.selectedContexts.includes(context.id)
        contextItems.push(
          <MultiSelectEntry key={`context-${context.description}-${context.id}`}
                            id={`context-${context.description}-${context.id}`}
                            selected={selected}
                            label={context.description}
                            insetLevel={1}
                            onChange={(e) => this.handleChangeContext(e, [context.id])}
          />,
        )
      })
    })

    return contextItems
  }

  getPSP = () => {
    const {psps} = this.props
    return psps.map(psp => {
        const selected = this.props.selectedPSPs.includes(psp.pspId)
        return <MultiSelectEntry key={`psp-${psp.pspName}-${psp.pspId}`}
                                 id={`psp-${psp.pspName}-${psp.pspId}`}
                                 selected={selected}
                                 label={psp.pspName}
                                 onChange={(e) => this.handleChangePSP(e, [psp.pspId])}
        />
      },
    )
  }

  getPayments = () => {
    const {payments} = this.props
    const paymentsItems = []
    payments.forEach(psp => {
      const allSubsetId = psp.payments.map(payment => payment.paymentId)

      const selected = _.difference(allSubsetId, this.props.selectedPayments).length === 0
      paymentsItems.push(
        <MultiSelectEntry key={`payment-${psp.pspName}-${psp.pspId}`}
                          id={`payment-${psp.pspName}-${psp.pspId}`}
                          selected={selected}
                          label={psp.pspName}
                          onChange={(e) => this.handleChangePayment(e, allSubsetId, true)}
        />,
      )

      psp.payments.forEach(payment => {
        const selected = this.props.selectedPayments.includes(payment.paymentId)
        paymentsItems.push(
          <MultiSelectEntry key={`payment-${payment.paymentName}-${payment.paymentId}`}
                            id={`payment-${payment.paymentName}-${payment.paymentId}`}
                            selected={selected}
                            label={payment.paymentName}
                            insetLevel={1}
                            onChange={(e) => this.handleChangePayment(e, [payment.paymentId])}
          />,
        )
      })
    })

    return paymentsItems
  }

  getStatuses = () => {
    const {statuses} = this.props
    // statuses are objects representing statusPrevix (AUTHENTICATE, CAPTURE...), with a "statuses" attribute, that represents statuses.
    let statusesItems = []
    const allIds = statuses.map(statusPrefix => [statusPrefix.statuses.map(status => status.statusId)]).flat(2)

    const selected = _.difference(allIds, this.props.selectedStatuses).length === 0
    statusesItems.push(
      <MultiSelectEntry key={`status-all`}
                        id={`status-all`}
                        selected={selected}
                        label="All"
                        onChange={(e) => this.handleChangeStatuses(e, allIds, true)}
      />)

    statuses.forEach((statusPrefix, index) => {
      const allSubsetId = statusPrefix.statuses.map(statusPrefix => statusPrefix.statusId)

      const selected = _.difference(allSubsetId, this.props.selectedStatuses).length === 0
      statusesItems.push(
        <MultiSelectEntry key={`status-${statusPrefix.statusDescription}-${statusPrefix.statusId}-${index}`}
                          id={`status-${statusPrefix.statusDescription}-${statusPrefix.statusId}-${index}`}
                          selected={selected}
                          label={statusPrefix.statusDescription}
                          insetLevel={1}
                          onChange={(e) => this.handleChangeStatuses(e, allSubsetId, true)}
        />,
      )

      statusPrefix.statuses.forEach((status, index) => {
        const selected = this.props.selectedStatuses.includes(status.statusId)
        statusesItems.push(
          <MultiSelectEntry key={`status-${status.statusDescription}-${status.statusIs}-${index}`}
                            id={`status-${status.statusDescription}-${status.statusIs}-${index}`}
                            selected={selected}
                            label={status.statusDescription}
                            insetLevel={2}
                            onChange={(e) => this.handleChangeStatuses(e, [status.statusId])}
          />,
        )
      })
    })
    return statusesItems
  }

  getNotifiedOptions = () => {
    return [
      <option key={"any"} value="null">Any</option>,
      <option key={"true"} value={true}>Yes</option>,
      <option key={"false"} value={false}>No</option>,
    ]
  }

  validateForm = () => {
    const {selectedClient, fromDateTime, toDateTime, orderId, transactionId} = this.props
    const isValid = selectedClient !== null
      && ((fromDateTime !== null && toDateTime !== null)
        || !_.isEmpty(orderId)
        || !_.isEmpty(transactionId))

    if (!isValid) {
      this.openFormErrorDialog()
    }
    return isValid
  }

  openFormErrorDialog = () => {
    this.props.dispatch(createNotification("At least one of the following criteria has to be filled : Dates / Transaction ID / Order n°",
      "danger"))
  }

  getSearchParameters = () => {
    return {
      appClientId: this.props.selectedClient,
      channelIds: this.props.selectedChannels,
      contextIds: this.props.selectedContexts,
      pspIds: this.props.selectedPSPs,
      paymentMethodIds: this.props.selectedPayments,
      transactionStatusIds: this.props.selectedStatuses,
      fromDate: this.props.fromDateTime,
      toDate: this.props.toDateTime,
      orderId: this.props.orderId,
      transactionId: this.props.transactionId,
      notified: this.props.selectedNotified,
      lastStatus: this.props.selectedLastStatus,
      currentPage: 0,
      firstResultIndex: 0,
      maximumResults: this.props.elementsPerPage,
    }
  }

  setDateThisMonth = () => {
    this.setDate(moment().startOf('month'), moment().endOf('day'))
  }

  setDatePastSevenDays = () => {
    this.setDate(moment().subtract(1, 'week').startOf('day'), moment().endOf('day'))
  }

  setDateThisWeek = () => {
    this.setDate(moment().startOf('isoWeek'), moment().endOf('day'))
  }

  setDateToday = () => {
    this.setDate(moment().startOf('day'), moment().endOf('day'))
  }

  setDateLastHour = () => {
    this.setDate(moment().startOf('hour'), moment())
  }

  setDate = (from, to) => {
    this.props.dispatch(actionsAnalyzer.setFromDateTime(from))
    this.props.dispatch(actionsAnalyzer.setToDateTime(to))

    document.getElementById("transaction").focus()
  }

  setFromDateTime = (from) => {
    if (from.isAfter(this.props.toDateTime)) {
      this.setToDateTime(null)
    }

    const maxSearchDate = moment(from)
    maxSearchDate.add(this.props.dateSearchDaysRange, 'days')

    this.setState({toDateMaxDate: moment.min([maxSearchDate, moment()]), toDateMinDate: from})
    this.props.dispatch(actionsAnalyzer.setFromDateTime(from))
  }

  setToDateTime = (to) => {
    this.setState({fromDateMaxDate: moment.min([to, moment()])})
    this.props.dispatch(actionsAnalyzer.setToDateTime(to))
  }

  handleChangeTransactionId = (event) => {
    this.props.dispatch(actionsAnalyzer.setTransactionId(event.target.value.trim()))
  }

  handleChangeOrderId = (event) => {
    this.props.dispatch(actionsAnalyzer.setOrderId(event.target.value.trim()))
  }
  /**
   * Reset the form and date pickers limits.
   */
  handleResetButton = (e) => {
    e.preventDefault()
    this.setState({
      fromDateMaxDate: moment(),
      toDateMaxDate: moment(),
      toDateMinDate: null,
    })
    this.props.dispatch(actionsAnalyzer.resetForm())
  }

  handleSearchButton = () => {
    if (this.validateForm()) {
      this.props.dispatch(actionsAnalyzer.setNewSearchFormValues(this.getSearchParameters()))
    }
  }

  handleChangeClient = (event) => {
    this.props.dispatch(actionsAnalyzer.selectClient(parseInt(event.target.value)))
  }

  handleChangeChannel = (event, ids) => {
    let newlySelectedChannels = [...this.props.selectedChannels]

    ids.forEach(id => newlySelectedChannels.includes(id) ?
      newlySelectedChannels = newlySelectedChannels.filter(channel => channel !== id) :
      newlySelectedChannels.push(id),
    )

    this.props.dispatch(actionsAnalyzer.selectChannels(newlySelectedChannels))
  }

  handleChangeContext = (event, ids, parent) => {
    let newlySelectedContexts = [...this.props.selectedContexts]

    const allChildrenSelected = _.difference(ids, newlySelectedContexts).length === 0
    let hasAChildSelected = false
    if (parent) {
      for (let context of newlySelectedContexts) {
        if (ids.includes(context)) {
          hasAChildSelected = true
          break
        }
      }
    }

    const hasSomeChildrenSelected = !allChildrenSelected && hasAChildSelected

    if (!hasSomeChildrenSelected) {
      ids.forEach(id => newlySelectedContexts.includes(id) ?
        newlySelectedContexts = newlySelectedContexts.filter(context => context !== id) :
        newlySelectedContexts.push(id))
    } else {
      newlySelectedContexts = _.uniq(newlySelectedContexts.concat(ids))
    }


    this.props.dispatch(actionsAnalyzer.selectContexts(newlySelectedContexts))
  }

  handleChangePSP = (event, ids) => {
    let newlySelectedPSPs = [...this.props.selectedPSPs]

    ids.forEach(id => newlySelectedPSPs.includes(id) ?
      newlySelectedPSPs = newlySelectedPSPs.filter(psp => psp !== id) :
      newlySelectedPSPs.push(id),
    )

    this.props.dispatch(actionsAnalyzer.selectPSPs(newlySelectedPSPs))
  }

  handleChangePayment = (event, ids, parent) => {
    let newlySelectedPayments = [...this.props.selectedPayments]

    const allChildrenSelected = _.difference(ids, newlySelectedPayments).length === 0
    let hasAChildSelected = false
    if (parent) {
      for (let payment of newlySelectedPayments) {
        if (ids.includes(payment)) {
          hasAChildSelected = true
          break
        }
      }
    }

    const hasSomeChildrenSelected = !allChildrenSelected && hasAChildSelected

    if (!hasSomeChildrenSelected) {
      ids.forEach(id => newlySelectedPayments.includes(id) ?
        newlySelectedPayments = newlySelectedPayments.filter(payment => payment !== id) :
        newlySelectedPayments.push(id))
    } else {
      newlySelectedPayments = _.uniq(newlySelectedPayments.concat(ids))
    }


    this.props.dispatch(actionsAnalyzer.selectPayments(newlySelectedPayments))
  }

  handleChangeStatuses = (event, ids, parent = false) => {
    let newlySelectedStatuses = [...this.props.selectedStatuses]

    const allChildrenSelected = _.difference(ids, newlySelectedStatuses).length === 0
    let hasAChildSelected = false
    if (parent) {
      for (let status of newlySelectedStatuses) {
        if (ids.includes(status)) {
          hasAChildSelected = true
          break
        }
      }
    }

    const hasSomeChildrenSelected = !allChildrenSelected && hasAChildSelected

    if (!hasSomeChildrenSelected) {
      ids.forEach(id => newlySelectedStatuses.includes(id) ?
        newlySelectedStatuses = newlySelectedStatuses.filter(status => status !== id) :
        newlySelectedStatuses.push(id))
    } else {
      newlySelectedStatuses = _.uniq(newlySelectedStatuses.concat(ids))
    }


    this.props.dispatch(actionsAnalyzer.selectStatus(newlySelectedStatuses))
  }

  handleChangeNotified = (event) => {
    this.props.dispatch(actionsAnalyzer.selectNotified(event.target.value))
  }

  handleChangeLastStatus = () => {
    this.props.dispatch(actionsAnalyzer.selectLastStatus())
  }

  render() {
    return (
      <Box>
        <Columns style={{justifyContent: "space-evenly"}}>

          <form onSubmit={(e) => e.preventDefault()}>

            <Column size={12}>
              <Columns className={"is-unselectable"} style={{justifyContent: "space-evenly"}}>

                <MandatoryTransactionFilters onChange={this.handleChangeClient}
                                             clients={this.getClients()}
                                             selected={this.props.fromDateTime}
                                             onSelect={(date) => this.setFromDateTime(date)}
                                             maxDate={this.state.fromDateMaxDate}
                                             selected1={this.props.toDateTime}
                                             onSelect1={(date) => this.setToDateTime(date)}
                                             minDate={this.state.toDateMinDate}
                                             maxDate1={this.state.toDateMaxDate}
                                             onClick={this.setDateThisMonth}
                                             setPastSevenDays={this.setDatePastSevenDays}
                                             onClick1={this.setDateThisWeek}
                                             onClick2={this.setDateToday}
                                             onClick3={this.setDateLastHour}
                                             onChange1={this.handleChangeTransactionId}
                                             value={this.props.transactionId}
                                             onChange2={this.handleChangeOrderId}
                                             value1={this.props.orderId}
                                             onClick4={this.handleResetButton}
                                             disabled={this.props.transactionsLoading}
                                             onClick5={this.handleSearchButton}/>

                <ClientRelativeFilters channels={this.getChannels()}
                                       contexts={this.getContexts()}
                                       psp={this.getPSP()}
                                       payments={this.getPayments()}/>

                <MiscRelativeFilters onChange={this.handleChangeStatuses}
                                     checked={this.props.selectedLastStatus}
                                     statusNumber={this.props.selectedStatuses && this.props.selectedStatuses.length}
                                     onChange1={this.handleChangeLastStatus}
                                     statuses={this.getStatuses()}
                                     onChange2={this.handleChangeNotified}
                                     notified={this.props.selectedNotified}
                                     notifiedOptions={this.getNotifiedOptions()}/>
              </Columns>

            </Column>

            {/* Submit */}
            <Column size={12} className={"submit-filters"} style={{alignSelf: 'center'}}>
              <div>
                <ButtonGroup hasAddons>
                  <Button fullwidth
                          submit={false}
                          reset={true}
                          onClick={this.handleResetButton}>Reset</Button>
                  <Button fullwidth
                          submit={true}
                          disabled={this.props.transactionsLoading}
                          loading={this.props.transactionsLoading}
                          color={"primary"}
                          onClick={this.handleSearchButton}
                  >
                    <Icon>
                      <i className="fas fa-search"/>
                    </Icon>
                  </Button>
                </ButtonGroup>
              </div>
            </Column>

          </form>
        </Columns>

      </Box>
    )
  }
}

const mapStateToProps = state => {
  return {
    clients: state.appClient.clients,
    channels: analyzerSelectors.channelsSelector(state),
    contexts: analyzerSelectors.contextsSelector(state),
    psps: analyzerSelectors.pspsSelector(state),
    payments: analyzerSelectors.paymentSelector(state),
    statuses: analyzerSelectors.statusesSelector(state),
    selectedClient: state.analyzer.form.selectedClient,
    selectedChannels: state.analyzer.form.selectedChannels,
    selectedContexts: state.analyzer.form.selectedContexts,
    selectedPSPs: state.analyzer.form.selectedPSPs,
    selectedPayments: state.analyzer.form.selectedPayments,
    selectedStatuses: state.analyzer.form.selectedStatuses,
    selectedNotified: state.analyzer.form.selectedNotified,
    selectedLastStatus: state.analyzer.form.selectedLastStatus,
    transactionId: state.analyzer.form.transactionId,
    orderId: state.analyzer.form.orderId,
    fromDateTime: state.analyzer.form.fromDateTime,
    toDateTime: state.analyzer.form.toDateTime,
    currentPage: state.analyzer.form.currentPage,
    elementsPerPage: state.analyzer.form.elementsPerPage,
    currentSearchFormValues: state.analyzer.form.currentSearchFormValues,
    dateSearchDaysRange: parseInt(state.parameters[ParameterConstant.TRANSACTION_ANALYZER_MAX_SEARCH_DELAY], 0),
    transactionsLoading: state.analyzer.form.transactionsLoading,
  }
}

export default connect(mapStateToProps)(TransactionAnalyzerForm)
