import {
  DEFAULT,
  FETCH_ORDER_HISTORY_FAILURE,
  FETCH_ORDER_HISTORY_REQUEST,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_TRANSACTION_HISTORY_FAILURE,
  FETCH_TRANSACTION_HISTORY_REQUEST,
  FETCH_TRANSACTION_HISTORY_SUCCESS,
  FETCH_TRANSACTION_LOGS_FAILURE,
  FETCH_TRANSACTION_LOGS_REQUEST,
  FETCH_TRANSACTION_LOGS_SUCCESS,
  SET_CURRENT_TRANSACTION_LOG_ACTION
} from "../actions/Types";

const initialState = {
  history: new Map(),
  loading: false,
  currentAction: undefined,
};

export function transactionAnalyzerDetailReducer(state = initialState, action = DEFAULT) {

  switch (action.type) {
    case FETCH_ORDER_HISTORY_REQUEST:
    case FETCH_TRANSACTION_HISTORY_REQUEST:
    case FETCH_TRANSACTION_LOGS_REQUEST:

      let newHistory = new Map();
      newHistory.set(action.data.transactionId, {logType: action.data.logType});

      return {...state, history: newHistory, loading: true, currentAction: initialState.currentAction};

    case FETCH_TRANSACTION_LOGS_SUCCESS:

      let currentAction = Object.keys(action.data.history.logs)[0]
      // eslint-disable-next-line
    case FETCH_ORDER_HISTORY_SUCCESS:
    case FETCH_TRANSACTION_HISTORY_SUCCESS:

      newHistory = new Map();
      newHistory.set(action.data.transactionId, {content: action.data.history, logType: action.data.logType});

      return {...state, history: newHistory, loading: false, currentAction: currentAction};

    case FETCH_ORDER_HISTORY_FAILURE:
    case FETCH_TRANSACTION_HISTORY_FAILURE:
    case FETCH_TRANSACTION_LOGS_FAILURE:

      newHistory = new Map();
      return {...state, history: newHistory};

    case SET_CURRENT_TRANSACTION_LOG_ACTION:
      return {...state, currentAction: action.data};

    case DEFAULT:
    default:
      return state;
  }
}
