export const DEFAULT = {type: 'default'}

// Notifications
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS'
export const ANIMATE_NOTIFICATION_SUCCESS = 'ANIMATE_NOTIFICATION_SUCCESS'
export const ANIMATE_NOTIFICATION_OUT_SUCCESS = 'ANIMATE_NOTIFICATION_OUT_SUCCESS'
export const CLOSE_NOTIFICATION_SUCCESS = 'CLOSE_NOTIFICATION_SUCCESS'

// Transaction details
export const FETCH_TRANSACTION_HISTORY_REQUEST = 'FETCH_TRANSACTION_HISTORY_REQUEST'
export const FETCH_TRANSACTION_HISTORY_SUCCESS = 'FETCH_TRANSACTION_HISTORY_SUCCESS'
export const FETCH_TRANSACTION_HISTORY_FAILURE = 'FETCH_TRANSACTION_HISTORY_FAILURE'
export const FETCH_ORDER_HISTORY_REQUEST = 'FETCH_ORDER_HISTORY_REQUEST'
export const FETCH_ORDER_HISTORY_SUCCESS = 'FETCH_ORDER_HISTORY_SUCCESS'
export const FETCH_ORDER_HISTORY_FAILURE = 'FETCH_ORDER_HISTORY_FAILURE'
export const FETCH_TRANSACTION_LOGS_REQUEST = 'FETCH_TRANSACTION_LOGS_REQUEST'
export const FETCH_TRANSACTION_LOGS_SUCCESS = 'FETCH_TRANSACTION_LOGS_SUCCESS'
export const FETCH_TRANSACTION_LOGS_FAILURE = 'FETCH_TRANSACTION_LOGS_FAILURE'
export const SET_CURRENT_TRANSACTION_LOG_ACTION = 'SET_CURRENT_TRANSACTION_LOG_ACTION'

// Logs
export const FETCH_ALL_LOGS_REQUEST = 'FETCH_ALL_LOGS_REQUEST'
export const FETCH_ALL_LOGS_SUCCESS = 'FETCH_ALL_LOGS_SUCCESS'
export const FETCH_ALL_LOGS_ERROR = 'FETCH_ALL_LOGS_ERROR'
export const EXPAND_LOG_SUCCESS = 'EXPAND_LOG_SUCCESS'
export const CHANGE_LOG_LEVEL_REQUEST = 'CHANGE_LOG_LEVEL_REQUEST'
export const CHANGE_LOG_LEVEL_SUCCESS = 'CHANGE_LOG_LEVEL_SUCCESS'
export const CHANGE_LOG_LEVEL_ERROR = 'CHANGE_LOG_LEVEL_ERROR'

// Meta
export const FETCH_META_DATA = 'FETCH_META_DATA'
export const FETCH_META_DATA_SUCCESS = 'FETCH_META_DATA_SUCCESS'
export const FETCH_META_DATA_ERROR = 'FETCH_META_DATA_ERROR'
