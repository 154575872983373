import React, {Component} from 'react'
import Moment from 'react-moment'
import {connect} from 'react-redux'
import {activateBatch, clearHistory, loadHistory} from 'modules/admin/mod-batch'

import muiThemeable from 'material-ui/styles/muiThemeable'
import FlatButton from 'material-ui/FlatButton'
import Badge from 'material-ui/Badge'
import IconButton from 'material-ui/IconButton'
import Dialog from 'material-ui/Dialog'
import InfoIcon from 'material-ui/svg-icons/action/info-outline'
import Toggle       from "../../shared/form/Toggle"

import BatchExecutionStepTable from 'components/admin/batch/BatchExecutionStepTable'
import BatchExecutionHistoryTable from 'components/admin/batch/BatchExecutionHistoryTable'
import Button from "react-bulma-components/lib/components/button"
import ButtonGroup from "react-bulma-components/lib/components/button/components/button-group"

class BatchExecutionRow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      exitMessageDialogOpen: false,
      stepExecutionDialogOpen: false,
      historyOpened: false
    };
  }

  render() {
    const {jobExecution, history} = this.props;
    const actions = [ <FlatButton label="Close" primary={true} keyboardFocused={true} onClick={this.handleDialogClose} /> ];

    const enabled = !!jobExecution.enabled;
    const hasSteps = !!jobExecution.stepExecutions;
    const isHistoryLoading = this.state.historyOpened && (history === null || history === undefined);

    return (
      <React.Fragment>
      <tr key={`jobExecution${jobExecution.job.jobInstanceName}`}>
        <td>
          <Toggle compact={false}
                  id={jobExecution.job.jobInstanceName}
                  label=""
                  name={jobExecution.job.jobInstanceName}
                  checked={enabled}
                  onChange={this.toggleBatchActivation} />
        </td>

        <td>{jobExecution.job.jobInstanceName}</td>
        <td>{jobExecution.status}</td>
        <td>
          {(jobExecution.exitMessage === null || jobExecution.exitMessage === "") ? jobExecution.exitStatus :
            <div>
              <Badge badgeContent={<IconButton iconStyle={{ width: 20, height: 20 }} onClick={this.handleExitMessageOpen}><InfoIcon/></IconButton>}>
                {jobExecution.exitStatus}
              </Badge>
              <Dialog
                title="Exit message"
                actions={actions}
                modal={false}
                open={this.state.exitMessageDialogOpen}
                onRequestClose={this.handleDialogClose} >
                {jobExecution.exitMessage}
              </Dialog>
            </div>
          }
        </td>
        <td>{jobExecution.startTime && <Moment format="YYYY/MM/DD HH:mm:ss">{jobExecution.startTime}</Moment>}</td>
        <td>{jobExecution.startTime
              && jobExecution.endTime
              && <span><Moment decimal unit="seconds" diff={jobExecution.startTime}>{jobExecution.endTime}</Moment> seconds</span>}
        </td>
        <td>{jobExecution.nextExecutionDate && <Moment format="YYYY/MM/DD HH:mm:ss">{jobExecution.nextExecutionDate}</Moment>} </td>
        <td>
          <ButtonGroup hasAddons style={{justifyContent: "center"}}>
            <Button color="info"
                    size="small"
                    onClick={this.handleStepExecutionOpen}
                    disabled={!hasSteps}>
              Steps
            </Button>
            <Button color="info"
                    size="small"
                    onClick={this.handleLoadHistory}
                    loading={isHistoryLoading}
                    disabled={isHistoryLoading}>
              History
            </Button>
            <Button jobname={jobExecution.job.jobInstanceName}
                    color="success"
                    size="small"
                    onClick={this.handleRelaunchJobClick}>
              Run
            </Button>
          </ButtonGroup>
          <Dialog
            title="Step executions"
            actions={actions}
            modal={false}
            open={this.state.stepExecutionDialogOpen}
            onRequestClose={this.handleDialogClose}
            contentStyle={{ width: '99%', maxWidth: 'none', whiteSpace: 'pre-wrap'}}>
            <BatchExecutionStepTable jobExecution={jobExecution} />
          </Dialog>
        </td>
      </tr>
        {
          this.state.historyOpened && !isHistoryLoading ?
            <tr key={"jobExecutionHistoryTable" + jobExecution.id}>
              <td colSpan="15">
                <BatchExecutionHistoryTable history={history} onPageUpdate={this.onPageUpdate} />
              </td>
            </tr> :
            null
        }
      </React.Fragment>
    );
  }

  handleExitMessageOpen = () => { this.setState({exitMessageDialogOpen: true}); };
  handleDialogClose = () => { this.setState({exitMessageDialogOpen: false, stepExecutionDialogOpen: false}); };
  handleStepExecutionOpen = () => { this.setState({stepExecutionDialogOpen: true}); };
  toggleBatchActivation = () => {
    const {dispatch, jobExecution} = this.props;
    dispatch(activateBatch(jobExecution.job.jobInstanceName, !jobExecution.enabled));
  };

  handleLoadHistory = (event) => {
    const {dispatch, jobExecution} = this.props;
    let newHistoryState = !this.state.historyOpened;

    this.setState({historyOpened: newHistoryState});

    if (newHistoryState){
      dispatch(loadHistory(jobExecution.job.jobInstanceName, 0, 10));
    } else {
      dispatch(clearHistory(jobExecution.job.jobInstanceName));
    }
  };

  handleRelaunchJobClick = (event) => {
    const {relaunchClick, jobExecution} = this.props;
    event.preventDefault();
    relaunchClick(jobExecution.job, event.currentTarget);
  };

  onPageUpdate = (page, itemsPerPage) => {
    const {dispatch, jobExecution} = this.props;
    dispatch(loadHistory(jobExecution.job.jobInstanceName, page, itemsPerPage));
  };

}


const mapStateToProps = (state, ownProps) => {
  return {
    history: state.admin.batch.jobExecutionsHistory.get(ownProps.jobExecution.job.jobInstanceName)
  };
}

export default connect(mapStateToProps)(muiThemeable()(BatchExecutionRow));
