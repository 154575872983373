import {
  CHANGE_LOG_LEVEL_ERROR,
  CHANGE_LOG_LEVEL_REQUEST,
  CHANGE_LOG_LEVEL_SUCCESS,
  DEFAULT, EXPAND_LOG_SUCCESS, FETCH_ALL_LOGS_ERROR,
  FETCH_ALL_LOGS_REQUEST,
  FETCH_ALL_LOGS_SUCCESS
} from "../actions/Types";
import _ from 'lodash'

const initialState = {
  loaders:{
    fetch: false,
    loading: true,
    change: false,
  },
  loggers: {},
  levels: [],
};

export function logManagementReducer(state = initialState, action = DEFAULT) {

  switch (action.type) {
    case FETCH_ALL_LOGS_REQUEST:

      let newLoaders = _.merge(state.loaders, {loading: action.data.loading, fetch: true})

      return {...state, loaders: newLoaders}

    case CHANGE_LOG_LEVEL_REQUEST:
      return {...state, loaders: {...state.loaders, change: true}}

    case FETCH_ALL_LOGS_SUCCESS:
      let newLoggers = _.merge(state.loggers, action.data.loggers)
      newLoaders = _.merge(state.loaders, {loading: false, fetch: true})
      return {
        ...state,
        loggers: newLoggers,
        levels: action.data.levels,
        loaders: newLoaders,
      }

    case CHANGE_LOG_LEVEL_SUCCESS:
      newLoggers = Object.assign({}, state.loggers);

      _.set(newLoggers, `${action.data.key}.configuredLevel`, action.data.level)
      _.set(newLoggers, `${action.data.key}.effectiveLevel`, action.data.level)

      return {
        ...state,
        loggers: newLoggers,
        loaders: {...state.loaders, fetch: true}
      }

    case FETCH_ALL_LOGS_ERROR:

      newLoaders = _.merge(state.loaders, {loading: false, fetch: false})

      return {
        ...state,
        loaders: newLoaders
      }

    case CHANGE_LOG_LEVEL_ERROR:
      return {
        ...state,
        loaders: {...state.loaders, change: false}
      }

    case EXPAND_LOG_SUCCESS:
      newLoggers = Object.assign({}, state.loggers)
      _.set(newLoggers, action.data, !_.get(newLoggers, action.data))

      return {
        ...state,
        loggers: newLoggers
      }

    case DEFAULT:
    default:
      return state;
  }
}
