import React, {Component} from 'react';
import {connect}          from 'react-redux';
import _                  from 'lodash';
import {actions}          from 'modules/mod-client-application';
import {actions as actionsTreeForm} from 'modules/mod-mop-tree-form';

import {List, ListItem}     from 'material-ui/List';
import Divider              from 'material-ui/Divider';

import ModalAddMopTree from 'components/mop/ModalAddMopTree';

class MopsTreeView extends Component {

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
  }
  
  toggleModal() {    
    const {dispatch} = this.props;
    dispatch(actionsTreeForm.changeModalStatus());
  }

  componentWillMount() {
    this.fetchAppClients();
  }

  componentWillReceiveProps(nextProps) {
    // open first element by default.
    if (_.size(nextProps.appClient.clients) > 0 && nextProps.appClient.selectedChannelId === null){
      this.selectTreeViewElement(nextProps.appClient.clients[0]);
    }
  }

  render() {
    const clients = this.buildAppClients();
    
    return (
      <div className={"mop-treeview-container"}>
        {clients}
        {this.props.collab && this.props.collab.roles.map(role => role.code).indexOf('ROLE_ADMIN') !== -1 &&
          <button className="button is-fixed-bottom-mop-tree is-info is-rounded" onClick={this.toggleModal}>
            <span className="icon is-small">
              <i className="fas fa-pencil-alt"/>
            </span>
          </button>
        }
        <ModalAddMopTree 
          closeModal={this.toggleModal} 
          modalState={this.props.modalStatusAddItem} 
          title="Create a Client"
        />
      </div>
    )
  }
}

/***************************************************************/
/* Toggle add menu
/***************************************************************/
MopsTreeView.prototype.toggleAddMenu = function(e) {
  const {dispatch} = this.props;
  dispatch(actions.toggleAddMenu(e.currentTarget));
}

/***************************************************************/
/* Select an app
/***************************************************************/
MopsTreeView.prototype.selectTreeViewElement = function(clientApp, channel, context) {
  const {dispatch} = this.props;
  dispatch(actions.selectTreeViewElement(clientApp, channel, context));
}

/***************************************************************/
/* Fetch the apps list
/***************************************************************/
MopsTreeView.prototype.fetchAppClients = function() {
  const {dispatch} = this.props;
  dispatch(actions.listApplicationClient());
}

/***************************************************************/
/* Build the applications list
/***************************************************************/
MopsTreeView.prototype.buildAppClients = function() {
  const {clients} = this.props.appClient;
  let clientItems = clients ? clients.map((client,index) => <div key={index}>
    <ListItem
      key={ "clientApp" + client.id }
      style={client.selected === true ? {fontWeight: 'bold'} : {}}
      primaryText={client.description}
      secondaryText={<span style={{fontSize: '9px'}}>{client.externalId}</span>}
      initiallyOpen={false}
      primaryTogglesNestedList={true}
      onClick={() => this.selectTreeViewElement(client)}
      open={client.selected}
      nestedItems={this.buildChannels(client, undefined, client.channels)}
    />
    <Divider/>
  </div>) : [];

  return <List>{clientItems}</List>;
}

MopsTreeView.prototype.buildChannels = function(client, parentChannel, channels) {
  if (channels) {
    return channels.filter(channel => (!channel.default)).map(channel => (
      <ListItem
        key={ "channel" + channel.id}
        style={channel.selected === true ? {fontWeight: 'bold'} : {}}
        primaryTogglesNestedList={true}
        primaryText={channel.description}
        onClick={parentChannel ?
          () => this.selectTreeViewElement(client, parentChannel, channel)
          : () => this.selectTreeViewElement(client, channel)}
        open={channel.selected}
        nestedItems={ this.buildChannels(client, channel, channel.contexts)}
      />
    ))
  }
  return [];
}

const mapStateToProps = state => {
  return {
    appClient: state.appClient,
    collab: state.collab.profile,
    modalStatusAddItem: state.mopTreeForm.modalStatusAddItem
  }
}

export default connect(mapStateToProps)(MopsTreeView);
