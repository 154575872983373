import React, { Component } from 'react';
import Moment               from 'react-moment';
import { styles }         from 'styles';
import muiThemeable       from 'material-ui/styles/muiThemeable';
import FlatButton         from 'material-ui/FlatButton';
import Badge              from 'material-ui/Badge';
import IconButton         from 'material-ui/IconButton';
import Dialog             from 'material-ui/Dialog';
import InfoIcon           from 'material-ui/svg-icons/action/info-outline';
import HelpIcon           from 'material-ui/svg-icons/action/help';

import BatchExecutionStepTable            from 'components/admin/batch/BatchExecutionStepTable';

class BatchExecutionHistoryRow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      exitMessageDialogOpen: false
    };
  }

  render() {
    const {jobExecution} = this.props;
    const actions = [ <FlatButton label="Close" primary={true} keyboardFocused={true} onClick={this.handleDialogClose} /> ];

    return (
      <tr key={`jobExecution${jobExecution.job.jobInstanceName}`}>
        <td style={{maxWidth: "50px"}}>{jobExecution.id}</td>
        <td>{jobExecution.status}</td>
        <td>
          {(jobExecution.exitMessage === null || jobExecution.exitMessage === "") ? jobExecution.exitStatus :
            <div>
              <Badge badgeContent={<IconButton iconStyle={{ width: 20, height: 20 }} onClick={this.handleExitMessageOpen}><InfoIcon/></IconButton>}>
                {jobExecution.exitStatus}
              </Badge>
              <Dialog
                title="Exit message"
                actions={actions}
                modal={false}
                open={this.state.exitMessageDialogOpen}
                onRequestClose={this.handleDialogClose} >
                {jobExecution.exitMessage}
              </Dialog>
            </div>
          }
        </td>
        <td>{jobExecution.startTime && <Moment format="YYYY/MM/DD HH:mm:ss">{jobExecution.startTime}</Moment>}</td>
        <td>{jobExecution.startTime && jobExecution.endTime && <span><Moment decimal unit="seconds" diff={jobExecution.startTime}>{jobExecution.endTime}</Moment> seconds</span>}</td>
        <td>{this.getExecutionParameters()}</td>
        <td colSpan={1} style={styles.batch.table.td}>
          <IconButton iconStyle={{ width: 30, height: 30 }} onClick={this.handleStepExecutionOpen} disabled={jobExecution.stepExecutions === null}>
            <HelpIcon color={styles.palette.primary1Color}/>
          </IconButton>
          <Dialog
            title="Step executions"
            actions={actions}
            modal={false}
            open={this.state.stepExecutionDialogOpen}
            onRequestClose={this.handleDialogClose}
            contentStyle={{ width: '99%', maxWidth: 'none', whiteSpace: 'pre-wrap'}}>
            <BatchExecutionStepTable jobExecution={jobExecution} />
          </Dialog>
        </td>
      </tr>
    );
  }

  handleExitMessageOpen = () => { this.setState({exitMessageDialogOpen: true}); };
  handleDialogClose = () => { this.setState({exitMessageDialogOpen: false, stepExecutionDialogOpen: false}); };
  handleStepExecutionOpen = () => { this.setState({stepExecutionDialogOpen: true}); };
}

BatchExecutionHistoryRow.prototype.getExecutionParameters = function(){
  const {jobExecution} = this.props;
  return jobExecution.executionParameters
  .filter(jobExecution => (jobExecution.name !== "time"))
  .map(jobExecution =>
    <div className="is-flex">
      <span>{jobExecution.name}</span> : <span>{jobExecution.value}</span>
    </div>
  )
};


export default muiThemeable()(BatchExecutionHistoryRow);
